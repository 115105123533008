//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import moment from 'moment'

import { transformEntityName, compareDates } from '@/utils'
import { calendar } from '@/utils/DataUtils'
import { ChatEventBus, Events as ChatEvents } from '@/components/Chat/ChatEventBus'
import { Events, TaskEventBus } from '@/components/Tasks/EventBus'

import UserPic from '@/components/UI/UserPic'
import TaskItems from './TaskItems'
import { format } from 'quasar'
import {
  remindsStore,
  contactsStore,
  tasksStore,
  teamsStore,
  uiStore,
} from '@/store'

const { capitalize } = format

export default {
  name: 'TaskMeta',
  components: {
    IconBell: () => import('@/components/UI/icons/IconBell'),
    TaskImportanceChip: () => import('@/components/Tasks/TaskImportanceChip'),
    TaskItems,
    UserPic,
  },
  props: {
    jid: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    team () {
      return teamsStore.getters.currentTeam
    },
    isDone () {
      return this.task.done
    },
    deadlineCaption () {
      const prefix = this.isOverdue && this.canEditDeadline
        ? this.$t('tasks.deadline.overdue')
        : this.$t('tasks.deadline.basic')
      const deadline = this.formatDate(this.task.deadline)
      return `${prefix}: ${deadline}`
    },
    canEditReminders () {
      return this.task.canSendMessage
    },
    canEditDeadline () {
      return this.task.changeableFields.includes('deadline')
    },
    task () {
      return tasksStore.state.data[this.jid]
    },
    showObserversAsList () {
      return this.task.observers.length >= 1 && this.task.observers.length <= 3
    },
    filteredObsList () {
      if (!this.task.observers) return []
      if (!this.team.hideArchivedUsers) return this.task.observers
      return this.task.observers.filter(user => this.alwaysShowUser(user))
    },
    obsCountText () {
      const { assignee, owner, observers } = this.task
      const obsLength = this.filteredObsList.length
      const count = 1 /* owner */ + obsLength +
        (assignee && assignee !== owner && observers.indexOf(assignee) === -1 ? 1 : 0)
      return `${count} ${this.$tc('tasks.member', count)}`
    },
    linkedInfo () {
      if (!this.task.linkedMessages || !this.task.linkedMessages[0]) {
        return false
      }

      // Not sure where the linkedMessages sorting logic comes from,
      // for now we just sort in the order we need
      const linkedMessages = this.task.linkedMessages.sort((a, b) => compareDates(a.created, b.created))
      const linked = linkedMessages[0]
      const me = this.$store.getters.getUserId
      const isDirect = linked.chatType === 'direct'
      const linkActive = !isDirect || linked.sender === me || linked.recipient === me
      const info = {
        linkActive,
        msgJid: linked.messageId,
        userJid: linked.sender,
        userNameHtml: '@' + transformEntityName(contactsStore.getters.contactDisplayName(linked.sender)),
        chatJid: linked.chatId,
        chatNameHtml: '#' + transformEntityName(this.$store.getters.chatName(linked.chatId)),
        // formattedDate: dateFormat(linked.created, 'd.mm в H:MM').replace(dateFormat('d.mm'), 'сегодня'),
        // formattedDate: moment(linked.created).calendar().replace(/^./, c => c.toLowerCase()),
        fromMe: linked.sender === this.$store.getters.getUserId,
      }

      const params = {
        name: contactsStore.getters.contactDisplayName(linked.sender),
        // date: info.formattedDate
      }

      const isTask = linked.chatType === 'task'
      const chatHandle = isDirect
        ? '@'
        : isTask
          ? ''
          : '#'

      if (isDirect) {
        info.info = this.$t('tasks.createdFromMessageInDirectChatDescription', {
          ...params,
          chat: chatHandle.concat(contactsStore.getters.contactDisplayName(linked.recipient)),
        })
      } else {
        info.info = this.$t('tasks.createdFromMessageInNonDirectChatDescription', {
          ...params,
          chat: chatHandle.concat(this.$store.getters.chatName(linked.chatId)),
        })
      }

      return info
    },
    remindersHumanReadable () {
      const items = remindsStore.getters.remindsByChat[this.jid]
      return items?.map(item => calendar(item.fireAt)) ?? false
      // return items?.map(item => moment(new Date(item.fireAt)).calendar()) ?? false
    },
    isOverdue () {
      return this.task.deadlineExpired
    },
    assigneeText () {
      return capitalize(this.$t('tasks.assignee'))
    },
    tagsText () {
      return capitalize(this.$t('tasks.tags'))
    },
    memberText () {
      return capitalize(this.$t('tasks.member_plural'))
    },
  },
  methods: {
    taskComplexityLabel (jid) {
      return tasksStore.getters.complexityLabel(jid)
    },
    alwaysShowUser (user) {
      return (
        !contactsStore.getters.contact(user)?.isArchive ||
        user === this.task.owner ||
        user === this.task.assignee
      )
    },
    navTo (ev, jid) {
      if (ev.ctrlKey || ev.shiftKey || ev.metaKey) {
        return
      }
      ev.preventDefault()
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid,
        },
      })
    },
    strHash (str) {
      let hash = 0
      if (str.length === 0) return hash
      for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    },
    openAllObserversModal () {
      uiStore.actions.showModal({ instance: 'all-members', payload: { jid: this.task.jid } })
    },
    searchByTag (tag) {
      TaskEventBus.$emit(Events.SET_TASK_SEARCH, tag)
    },
    goToMessage (chatId, messageId) {
      ChatEventBus.$emit(ChatEvents.TOGGLE_IMPORTANT_MESSAGES_VIEWER, false)
      ChatEventBus.$emit(ChatEvents.GO_TO_MESSAGE, { chatId, messageId })
      window.goal('taskControls', { taskControls: 'Клик по сообщению в шторке, из которого создана задача' })
    },
    goToChat (chatId) {
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: chatId,
        },
      })
    },
    formatDate (date) {
      date = new Date(date)
      return calendar(date, true)
    },
    parentHref (jid) {
      return jid
        ? `/${teamsStore.state.currentTeamId}/chats/${jid}/`
        : null
    },
  },
}
