import DOMUtils, { prepend } from '@/utils/DOM'
import { formatTime } from '@/utils/Common'
import { getChatType } from '@/utils'
import i18n from '@/i18n'
import { MESSAGE_COLLAPSE_TIMEOUT } from '@/store/messages/consts'
import * as Sentry from '@sentry/browser'
import store, { contactsStore, groupsStore } from '@/store'

import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { createSenderNameElement, getSender } from '@/components/Chat/Instance/DOM/Components/Common'
import { isImportant } from '@/components/Chat/Instance/DOM/Components/Processing/Important'
import { Contact, Chat, SingleIcon } from '@tada-team/tdproto-ts'

export const COLLAPSED_CLASS_NAME = 'collapsed'
export const SENDER_DATA_CLASS_NAME = 'msg-data'
export const TIME_CLASS_NAME = 'time'

const createTimeElement = (date: Date, fullDate: boolean): HTMLElement => {
  return DOMUtils.createElement('div', { class: TIME_CLASS_NAME }, formatTime(date, fullDate))
}

const createSourceElement = (chatId: string): HTMLElement => {
  const element = DOMUtils.createElement('div', { class: 'source o-text-cutter' })

  const { getters } = store
  const chatType = getChatType(chatId)
  const prefix = chatType === 'direct' ? `${i18n.t('chattape.directChat')} — ` : ''
  element.textContent = prefix + getters.chatName(chatId)

  return element
}

export const createSenderData = (element: HTMLElement, data?: Contact): HTMLElement => {
  const jid = element.getAttribute(MessageDOMAttribute.OWNER)
  const source = element.getAttribute(MessageDOMAttribute.SOURCE)

  const timestamp = element.getAttribute(MessageDOMAttribute.TIMESTAMP) || '0'
  const date = new Date(+timestamp)

  if ((!data || data.jid === 'dummy') && !jid) {
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Error)
      scope.setTag('tech debt', 'createSenderData')
      scope.setContext('chat', { data, jid, element })
      Sentry.captureException('Unexpected jid detected.')
    })
  }
  const sender = data || getSender(jid)

  let icon: string | SingleIcon | undefined
  if (sender instanceof Contact) {
    icon = contactsStore.getters.contactIcon(sender.jid)
  } else if (sender instanceof Chat) {
    icon = groupsStore.getters.icon(sender.jid)
  } else {
    icon = sender.icon
  }

  const avatarElement = DOMUtils.createElement(
    'img',
    { src: icon, class: 'rounded-circle o-no-select' },
  )
  /**
  * We can undestand that message rendering in Seach by checking the source attribute
  */
  const timeElement = createTimeElement(date, Boolean(source))
  const sourceElement = source ? createSourceElement(source) : null
  const nameElement = createSenderNameElement({ sender })

  return DOMUtils.createElement('div', { class: SENDER_DATA_CLASS_NAME }, avatarElement, nameElement, sourceElement, timeElement)
}

const isAlreadyCollapsed = (element: HTMLElement): boolean => {
  return !element.parentNode || element.classList.contains(COLLAPSED_CLASS_NAME)
}

export const checkAttributesToCollapse = (timestamp: number, owner: string, previousElement: HTMLElement): boolean => {
  const previousTimestamp = previousElement.getAttribute(MessageDOMAttribute.TIMESTAMP)
  if (!previousTimestamp) return false

  if (Math.abs(+previousTimestamp - +timestamp) > MESSAGE_COLLAPSE_TIMEOUT) return false

  const sameOwner = owner === previousElement.getAttribute(MessageDOMAttribute.OWNER)

  if (sameOwner) {
    const insideEl = previousElement.querySelector('.message-content')
    const itsAttr = insideEl?.getAttribute('data-content-type')
    return itsAttr !== TADA.MessageType.CHANGE
  }
  return owner === previousElement.getAttribute(MessageDOMAttribute.OWNER)
}

const needToCollapse = (element: HTMLElement, previousElement?: HTMLElement): boolean => {
  const timestamp = element.getAttribute(MessageDOMAttribute.TIMESTAMP)
  if (!timestamp) return false

  const owner = element.getAttribute(MessageDOMAttribute.OWNER)
  if (!owner) return false

  previousElement = previousElement || element.previousElementSibling as HTMLElement
  if (!previousElement) return false

  return checkAttributesToCollapse(+timestamp, owner, previousElement)
}

export const removeSenderData = (element: HTMLElement) => {
  const senderDataElement = element.querySelector(`.${SENDER_DATA_CLASS_NAME}`) as HTMLElement
  DOMUtils.removeElement(senderDataElement)

  element.classList.add(COLLAPSED_CLASS_NAME)
}

const addSenderData = (element: HTMLElement) => {
  const senderDataElement = createSenderData(element)
  prepend(element, senderDataElement)

  element.classList.remove(COLLAPSED_CLASS_NAME)
}

export const isSenderDataElement = (element: HTMLElement): boolean => {
  return element.className === SENDER_DATA_CLASS_NAME
}

export const updateSenderData = (element: HTMLElement, model: TADA.Message | null) => {
  if (model && model.content.type === TADA.MessageType.CHANGE) return

  const isMessageImportant = model ? model.important : isImportant(element)
  const collapse = !isMessageImportant && needToCollapse(element)
  const isCollapsed = isAlreadyCollapsed(element)

  if (collapse === isCollapsed) return

  if (collapse) return removeSenderData(element)

  const senderDataElement = element.querySelector(`.${SENDER_DATA_CLASS_NAME}`)
  if (senderDataElement === null) {
    addSenderData(element)
    return
  }

  const updatedSenderDataElement = createSenderData(element)
  senderDataElement.replaceWith(updatedSenderDataElement)
}
