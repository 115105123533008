//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import Messages from '@/store/messages'

import * as actionTypes from '@/store/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import { getAllowedFileExtensions } from '@/utils'
import { createMessage } from '@/api/v3/DataGenerator'

import ReplyArea from './ReplyArea'
import ActiveMessages from './ActiveMessages'
import AudioInput from './AudioMessages/AudioInput'
import PopupWrapper from '@/components/UI/Wrappers/PopupWrapper/'
import { defaultLogger } from '@/loggers'
import { format } from 'quasar'

import {
  contactsStore,
  tasksStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'

const { capitalize } = format

export default {
  name: 'ChatForm',
  components: {
    ActiveMessages,
    AudioInput,
    IconClip: () => import('@/components/UI/icons/IconClip'),
    IconGift: () => import('@/components/UI/icons/IconGift'),
    PopupWrapper,
    ReplyArea,
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isAudioInputActive: false,
      showSendOptions: false,
      loading: false,
      positiveUpdateAltSendOverride: null,
    }
  },
  computed: {
    ...mapGetters([
      'chatAudioComposingIds',
      'chatComposingIds',
      'chatName',
      'chatType',
      'getCurrentActiveMessageData',
      'getUserId',
    ]),
    isThemeNY () {
      return uiSettingsStore.getters.isThemeNY
    },
    allowedFileExtension () {
      return getAllowedFileExtensions()
    },
    attachIcon () {
      return this.isThemeNY ? 'IconGift' : 'IconClip'
    },
    displayAltSend () {
      return this.positiveUpdateAltSendOverride === null ? this.me.altSend : this.positiveUpdateAltSendOverride
    },
    sendHotkeyAdditionalText () {
      if (!this.me.altSend) {
        return ''
      }

      if (navigator.platform.toLowerCase().indexOf('mac') !== -1) {
        return 'Cmd + '
      }

      return 'Ctrl + '
    },
    me () {
      return teamsStore.getters.currentTeam.me
    },
    activeMessageData () {
      return this.getCurrentActiveMessageData
    },
    placeholder () {
      const type = this.chatType(this.chatId)

      if (type === 'task') {
        const task = tasksStore.state.data[this.chatId]
        return task
          ? this.$t('chattape.taskMessage', { num: task.num })
          : this.$t('chattape.typeMessage')
      }

      let name = this.chatName(this.chatId)
      if (!name) return this.$t('chattape.typeMessage')

      name = name.length > 60 ? (name.substr(0, 57) + '...') : name
      return this.$t(`chattape.${type}Message`, { name, interpolation: { escapeValue: false } })
    },
    composingPostfix () {
      if (this.isThemeNY) {
        return {
          singular: this.$t('chatlist.writingNY'),
          plural: this.$t('chatlist.writingNY_plural'),
        }
      } else {
        return {
          singular: this.$t('chatlist.writing'),
          plural: this.$t('chatlist.writing_plural'),
        }
      }
    },
    composingNames () {
      const composingIds = this.chatComposingIds(this.chatId)
      if (!composingIds || !composingIds.length) return ''

      const { isThemeNY } = uiSettingsStore.getters

      const getText = (isAudio = false, isPlural = false) => {
        if (isPlural) {
          return isAudio
            ? this.$t('chattape.userRecAudioMsgShort_plural').toString()
            : isThemeNY
              ? this.$t('chatlist.writingNY_plural').toString()
              : this.$t('chatlist.writing_plural').toString()
        } else {
          return isAudio
            ? this.$t('chattape.userRecAudioMsgShort').toString()
            : isThemeNY
              ? this.$t('chatlist.writingNY').toString()
              : this.$t('chatlist.writing').toString()
        }
      }

      const audioIds = this.chatAudioComposingIds(this.chatId)

      let prefix = ''
      let suffix = ''

      if (composingIds.length === 1) {
        prefix = contactsStore.getters.contactDisplayName(composingIds[0])
        suffix = getText(!!audioIds.length)
      } else {
        const equal = (
          composingIds.length === audioIds.length &&
          !composingIds.some(id => !audioIds.includes(id))
        )

        const names = composingIds.map(id => contactsStore.getters.contactDisplayName(id))

        prefix = names.join(', ')
        suffix = getText(equal, true)
      }
      return `${prefix} ${suffix}`
    },
    formatting () {
      return capitalize(this.$t('glossary.formatting'))
    },
  },
  methods: {
    ...mapActions([
      actionTypes.SOCKET_EDIT_MESSAGE,
      actionTypes.SOCKET_SEND_MESSAGE,
    ]),
    openOptions (open) {
      if (open) {
        window.clearTimeout(this.timeout)
        this.showSendOptions = true
      } else {
        window.clearTimeout(this.timeout)
        this.timeout = window.setTimeout(() => { this.showSendOptions = false }, 50)
      }
    },
    async saveSendHotkey (param) {
      this.loading = true
      this.positiveUpdateAltSendOverride = param
      const me = this.getUserId
      try {
        await api.contacts.edit(me, { alt_send: param })
      } catch (e) {
        this.positiveUpdateAltSendOverride = null
      }
      this.loading = false
      this.showSendOptions = false
    },
    selectFile (event) {
      const input = event.target
      if (!input) return

      const { files } = input
      if (!files || files.length === 0) return

      uiStore.actions.showModal({ instance: 'FileUploader', payload: [...files] })

      input.value = ''
    },
    // sendTextBlock () {
    //   const { dispatch } = this.$store
    //   uiStore.actions.showModal({ instance: 'text-uploader-modal' })
    // },
    toggleAudioInput () {
      const { activeMessages } = this.$refs
      if (activeMessages) {
        const data = this.activeMessageData
        data && data.goal === 'edit' && activeMessages.clear()
      }

      this.isAudioInputActive = !this.isAudioInputActive
    },
    insert () { },
    submit (content) {
      this.editMessage(content.text) || this.sendMessage(content)

      const { activeMessages } = this.$refs
      activeMessages && activeMessages.clear()
    },
    sendMessage (content) {
      const message = createMessage({
        sender: this.getUserId,
        recipient: this.chatId,
        text: content.text,
        linkedMessages: content.forwardedMessages,
        replyTo: content.replyTo,
      })

      this.SOCKET_SEND_MESSAGE({ chatId: this.chatId, message })
    },
    editMessage (text) {
      defaultLogger.info('Edit message called in ChatForm', text)
      const data = this.activeMessageData
      if (!data || data.goal !== 'edit') return false

      const { messageId } = data
      const message = Messages.getMessage(this.chatId, messageId)
      if (!message) return false

      Object.assign(message.content, { text })

      defaultLogger.info('Sending to socket edited message', { chatId: this.chatId, message })
      this.SOCKET_EDIT_MESSAGE({ chatId: this.chatId, message })

      return true
    },
  },
  watch: {
    chatId () {
      this.isAudioInputActive = false
    },
    'me.altSend' () {
      this.positiveUpdateAltSendOverride = null
    },
  },
}
