/* eslint-disable eqeqeq */
import i18n from '@/i18n'

import store, { uiStore, groupsStore, teamsStore } from '@/store'
import * as actionTypes from '@/store/actionTypes'
import Messages from '@/store/messages'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { Events as TaskEvents, TaskEventBus } from '@/components/Tasks/EventBus'
import { copy } from '@/utils/Common'
import { preventHtmlTags } from '@/utils'

import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { toggleImportantAppearance } from '@/components/Chat/Instance/DOM/Components/Processing/Important'
import { getMessageText, showMessageRelatedDialog } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import { defaultLogger } from '@/loggers'
import router from '@/router'
// import { Chat } from '@tada-team/tdproto-ts'
import api from '@/api/v3'

export default {
  /* Undelivered message tools handlers */
  resend: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    let message = Messages.getMessage(chatId, messageId)
    message = JSON.parse(JSON.stringify(message))

    Messages.removeUndeliveredMessage(chatId, messageId)
    store.dispatch(actionTypes.SOCKET_SEND_MESSAGE, { message, chatId })
  },
  trash: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    Messages.removeUndeliveredMessage(chatId, messageId)
  },
  /* Regular message tools handlers */
  task: (element: HTMLElement) => {
    if (!element) return

    const id = element.getAttribute(MessageDOMAttribute.ID)
    uiStore.actions.showModal({ instance: 'new-task', payload: { linkedMessages: [id] } })
  },
  taskItem: (element: HTMLElement) => {
    if (!element) return

    const id = element.getAttribute(MessageDOMAttribute.ID)
    TaskEventBus.$emit(TaskEvents.ADD_TASK_ITEM, id)
  },
  meeting: (element: HTMLElement): void => {
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    ChatEventBus.$emit(Events.HANDLE_ALL_SELECTED, { handle: 'meeting', orderIds: [messageId] })
  },
  comment: async (element: HTMLElement): Promise<void> => {
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    if (message.chatType !== 'group') return

    const openChat = (jid: string) => router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid,
      },
    })

    let threadJID = message.threadJID

    if (!message.threadJID) {
      const thread = await api.chats.createThread(messageId)
      threadJID = thread.chatId
    }

    openChat(threadJID!)

    await store.dispatch(actionTypes.LOAD_CHAT, threadJID)
    if (store.getters.chatExists(threadJID)) {
      openChat(threadJID!)
    }
  },
  important: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const source = element.getAttribute(MessageDOMAttribute.SOURCE)
    source && toggleImportantAppearance(element)

    Messages.toggleMessageImportance(source || chatId, messageId)
  },
  reply: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const chat = getters.entity(chatId)
    if (!chat || !chat.canSendMessage) return

    ChatEventBus.$emit(Events.TOGGLE_MESSAGE_ACTIVE, { messageId, goal: 'reply' })
  },
  forward: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    uiStore.actions.showModal({
      instance: 'RedirectMessagesModal',
      // TODO: remove any
      payload: message as any,
    })
  },
  copy: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const text = getMessageText(chatId, messageId)
    copy(text)
  },
  edit: (element: HTMLElement) => {
    defaultLogger.debug('Starting to edit message...')
    defaultLogger.info('Message element:', element)
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    ChatEventBus.$emit(Events.TOGGLE_MESSAGE_ACTIVE, { messageId, goal: 'edit' })
  },
  hidePreview: (element: HTMLElement) => {
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const { getters, dispatch } = store
    const { currentChat: chatId } = getters

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    message.nopreview = true

    ChatEventBus.$emit(Events.SET_MESSAGE_IMPORTANCE, { chatId, message })

    dispatch(actionTypes.SOCKET_EDIT_MESSAGE, { chatId, message })
  },
  delete: (element: HTMLElement) => {
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    showMessageRelatedDialog({
      message,
      dialogText: i18n.t('chattape.deleteOneMessageText').toString(),
      title: i18n.t('chattape.deleteOneMessageTitle').toString(),
      buttonText: i18n.t('chattape.messageActions.delete').toString(),
      callback: (chatId: string, messageId: string) => {
        const { dispatch } = store
        dispatch(actionTypes.SOCKET_EDIT_MESSAGE, { chatId, deleteMessage: messageId })
      },
    })
  },
  debug: (element: HTMLElement) => {
    if (!element) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const lastMessage = getters.chatLastMessage(chatId)
    const { messageId: lastMessageId } = lastMessage

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    const content = preventHtmlTags(JSON.stringify(message, null, 4))

    const chat = lastMessageId === messageId ? '<b>true</b>' : '<i>' + preventHtmlTags(lastMessage.content.text.slice(0, 50)) + '</i>'
    let dom = '?'

    const messagesElements = document.querySelectorAll('.msg[data-owner-id]')
    const lastMessageElement = messagesElements[messagesElements.length - 1]
    let lastMessageElementId = ''
    if (lastMessageElement) {
      lastMessageElementId = element.getAttribute(MessageDOMAttribute.ID) || ''
      const lastMessageFromDOM = Messages.getMessage(chatId, lastMessageElementId)
      if (!lastMessageFromDOM) {
        dom = 'No message in DOM!'
      } else {
        dom = lastMessageElementId === messageId ? '<b>true</b>' : '<i>' + preventHtmlTags(lastMessageFromDOM.content.text.slice(0, 50)) + '</i>'
      }
    }

    const styles = `
      white-space: pre;
      width: 100%;
      height: 400px;
      overflow: auto;
    `
    const html = `
      Last message: (${lastMessageElementId === lastMessageId ? '<b>same</b>' : '<b>not</b> same'})<br>
      &nbsp;&nbsp;Chat: ${chat}
      <br>
      &nbsp;&nbsp;DOM: ${dom}
      <hr>
      <div style="${styles}">${content}</div>
    `

    uiStore.actions.showModal({
      instance: 'universal-yes-no',
      payload: {
        title: 'Message debug',
        text: html,
      },
    })
  },
  pin: (element: HTMLElement) => {
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    const group = groupsStore.state.data[chatId]
    const unpin = group?.pinnedMessage?.messageId === messageId

    const payload = {
      groupId: chatId,
      messageId,
      unpin,
    }
    groupsStore.actions.pinMessage(payload)
  },
  unpin: (element: HTMLElement) => {
    if (!element) return

    const messageId = element.getAttribute(MessageDOMAttribute.ID)
    if (!messageId) return

    const { getters } = store
    const { currentChat: chatId } = getters

    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    const payload = {
      groupId: chatId,
      messageId,
      unpin: true,
    }
    groupsStore.actions.pinMessage(payload)
  },
} as { [name: string]: (element: HTMLElement) => void }
