//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiStore } from '@/store'

export default {
  props: [
    'topCollapsed',
    'isDescrHovered',
    'isChatHovered',
    'background',
  ],
  data () {
    return {
      fullDescription: false,
    }
  },
  computed: {
    someMessagesSelected () {
      return (uiStore.getters.selectedMessagesIds || []).length > 0
    },
  },
  watch: {
    isChatHovered: {
      handler () {
        this.fullDescription = true

        const descr = document.querySelector('.full-task-descr')
        if (descr && document.documentElement.clientHeight - descr.getBoundingClientRect().bottom < 170) {
          this.fullDescription = false
        }
      },
      immediate: true,
    },
  },
}
