//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import * as actionTypes from '@/store/actionTypes'
import { format, preventHtmlTags, scrollIntoViewIfNeeded } from '@/utils'
import { Events, TaskEventBus } from '@/components/Tasks/EventBus'
import { ChatEventBus, Events as ChatEvents } from '@/components/Chat/ChatEventBus'

import UserPic from '@/components/UI/UserPic'
import ChatArea from './ChatArea'
import SplitView from './SplitView'
import Split from './Split'
import TaskMeta from './TaskMeta'
import ScrollableAreaWrapper from '@/components/UI/Wrappers/ScrollableAreaWrapper.jsx'

import TaskDropMixin from '@/components/MiddleColumn/Mixins/TaskDrop'

import NotifyCallBar from '@/components/Calls/NotifyCallBar'
import store, {
  activeCallStore,
  callsStore,
  sectionsStore,
  contactsStore,
  tasksStore,
  uiStore,
  teamsStore,
} from '@/store'

import { format as formatQuasar, throttle } from 'quasar'
import CallActionsBtn from '@/components/HeaderBar/CallActionsBtn/index.vue'

const { capitalize } = formatQuasar

export default {
  name: 'FullTask',
  components: {
    'scrollable-area-wrapper': ScrollableAreaWrapper,
    UserPic,
    CallActionsBtn,
    ChatArea,
    TaskMeta,
    SplitView,
    Split,
    IconPen: () => import('@/components/UI/icons/IconPen'),
    NotifyCallBar,
    TaskMenu: () => import('./TaskMenu'),
    IconLogoGray: () => import('@/components/UI/icons/IconLogoGray.vue'),
  },
  mixins: [TaskDropMixin],
  data () {
    return {
      showDebug: false,
      importantViewerActive: false,
      loadingError: null,
      loadingErrorFromServer: null,
      maxTopSize: 500,
      isDescrHovered: false,
      isChatHovered: false,
      taskStatusLoading: false,
      defaultTopContentBackground: '#fafafa',
    }
  },
  computed: {
    hasExtraStatuses () {
      return tasksStore.getters.hasExtraStatuses
    },
    tasksStatuses () {
      return tasksStore.state.statuses
    },
    currentStatus () {
      return this.tasksStatuses.find(s => s.name === this.task.taskStatus)
    },
    topContentBackground () {
      const color = tasksStore.getters.color(this.task.jid)?.light
      return { background: color ?? this.defaultTopContentBackground }
    },
    canSendMessage () {
      return this.task && this.task.canSendMessage
    },
    calling () {
      if (!this.canCall) return

      const activeCallJid = activeCallStore.state.activeCall?.jid
      const isActiveCallInThisChat = (
        activeCallJid &&
        activeCallJid === this.currentChat
      )

      return (
        isActiveCallInThisChat ||
        callsStore.getters.call(this.currentChat)
      )
    },
    sectionText () {
      return this.task.section && sectionsStore.getters.section(this.task.section, 'task')?.name
    },
    currentChat () {
      return this.$store.getters.currentChat
    },
    task () {
      return tasksStore.state.data[this.currentChat]
    },
    loading () {
      return !this.task || !this.currentChat || !this.task.full
    },
    canEdit () {
      const propertiesToCheck = [
        'assignee',
        'deadline',
        'description',
        'items',
        'observers',
        'public',
        'section',
        'tags',
      ]
      return propertiesToCheck.some(
        property => this.task.changeableFields.includes(property),
      )
    },
    canCall () {
      return this.task.canCall && callsStore.getters.isCallingEnabled()
    },
    canChangeStatus () {
      return this.task.changeableFields.indexOf('task_status') !== -1
    },
    isOverdue () {
      return this.task.taskStatus !== 'done' && this.task.deadline && Date.parse(this.task.deadline) < Date.now()
    },
    isIn () {
      const me = this.$store.getters.getUserId
      return this.task.assignee === me
    },
    isDone () {
      return this.task.taskStatus === 'done'
    },
    taskFormattedText () {
      const splitIndex = this.task.description.indexOf('\n')
      return {
        title: splitIndex >= 0 ? this.task.description.substr(0, splitIndex) : this.task.description,
        // text: splitIndex >= 0 ? format(preventHtmlTags(this.task.description.substr(splitIndex + 1)), false, true) : ''
        text: format(preventHtmlTags(this.task.description), false, true),
      }
    },
    nextStatus () {
      const currOrderIndex = this.currentStatus.sortOrdering
      let nextStatus = null
      this.tasksStatuses.forEach(s => {
        s.sortOrdering > currOrderIndex &&
        (!nextStatus || nextStatus.sortOrdering > s.sortOrdering) &&
        (nextStatus = s)
      })

      return nextStatus
    },
    taskText () {
      return capitalize(this.$t('glossary.task'))
    },

    hasSelectedMessagesIds () {
      const hasSelected = uiStore.getters.selectedMessagesIds && uiStore.getters.selectedMessagesIds.length > 0
      return hasSelected
    },
  },
  watch: {
    async loading () {
      this.initTask()

      if (this.eventAttached) return

      const { mc } = this.$refs
      if (!mc) return

      const { $el: messageContainer } = mc

      const scrollableContainer = messageContainer.querySelector('.scrollable-area > .scrollable-content')
      scrollableContainer && scrollableContainer.addEventListener('scroll', this.updateState)

      this.eventAttached = true
    },
    async 'task.jid' () {
      await this.$nextTick()
      this.initTask()
    },
    task: {
      handler: async function () {
        await this.$nextTick()
        const content = document.querySelector('.full-task .full-task-descr .scrollable-content')
        // console.log('CALC', content, content && content.clientHeight, content && content.scrollHeight)
        if (content) {
          this.maxTopSize = content.scrollHeight + 50
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created () {
    this.collapsedStateSaver = throttle((jid, collapsed) => {
      const task = tasksStore.state.data[jid]
      task && task.collapsed !== collapsed && api.tasks.edit(jid, { collapsed })
    }, 5000)

    this.handleImportantViewer = value => { this.importantViewerActive = !!value }

    TaskEventBus.$on(Events.TOP_SPLIT_COLLAPSE_STATE, this.onTopSplitCollapseState)
  },
  beforeDestroy () {
    ChatEventBus.$off(ChatEvents.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.handleImportantViewer)
    TaskEventBus.$off(Events.TOP_SPLIT_COLLAPSE_STATE, this.onTopSplitCollapseState)
  },
  mounted () {
    this.initTask()
    ChatEventBus.$on(ChatEvents.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.handleImportantViewer)
  },
  methods: {
    searchBySection () {
      TaskEventBus.$emit(Events.SET_SECTION_FILTER, this.task.section)
    },
    onTopSplitCollapseState (c) {
      this.collapsedStateSaver(this.task.jid, c)
    },
    debug () {
      if (window.FEATURES.is_testing) {
        this.showDebug = !this.showDebug
      }
    },
    reset () {
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: store.getters.lastChat || contactsStore.getters.defaultContact,
        },
      })
    },
    toggleImportantViewer () {
      this.unselectAll()

      this.importantViewerActive = !this.importantViewerActive

      window.goal('taskControls', { taskControls: 'Включить важные сообщения' })

      if (this.importantViewerActive) {
        // this.toggleMode()
      }
    },
    unselectAll () {
      uiStore.actions.clearSelectedMessages()
    },
    descrTransitionStart () {
      // if (this.mode !== 'full') {
      //   this.prevHeight = this.$refs.descr.clientHeight
      // }

      // if (this.descrAnimIv) {
      //   return
      // }

      // this.descrAnimIv = window.setInterval(() => {
      //   if (!this.$refs || !this.$refs.descr) {
      //     window.clearInterval(this.descrAnimIv)
      //     this.descrAnimIv = null
      //   }
      //   let diff = this.$refs.descr.clientHeight - this.prevHeight
      //   this.prevHeight = this.$refs.descr.clientHeight
      //   if (this.$refs.mc) {
      //     const { $el: messageContainer } = this.$refs.mc

      //     const scrollableContainer = messageContainer.querySelector('.scrollable-area > .scrollable-content')
      //     scrollableContainer && (scrollableContainer.scrollTop += diff)
      //   }
      // }, 3)
    },
    async descrTransitionEnd () {
      // window.clearInterval(this.descrAnimIv)
      // this.descrAnimIv = null
      await this.$nextTick()
      const { descrScroll } = this.$refs
      descrScroll && descrScroll.updateScrollBar && descrScroll.updateScrollBar()
      setTimeout(() => descrScroll && descrScroll.updateScrollBar && descrScroll.updateScrollBar(), 100)
    },

    async initTask () {
      this.loadingError = null
      this.loadingErrorFromServer = null

      if (!this.task || !this.task.full) {
        // try to load
        try {
          await tasksStore.actions.loadTask(this.currentChat)
        } catch (e) {
          console.error('Task loading failed', e)
          if (e.error === 'Объект не найден') {
            this.loadingError = this.$t('tasks.errorNotFound')
          } else if (e.status === 403) {
            this.loadingError = this.$t('tasks.errorAccessDenied')
          } else if (e.error) {
            this.loadingErrorFromServer = e.error
          } else {
            this.loadingError = this.$t('errors.unknownError')
          }
        }
        return
      }

      const card = document.querySelector(`#task-small-card-${this.task.num}`)
      card && scrollIntoViewIfNeeded(card, 'task-items-wrapper')

      // await this.$nextTick()
      // this.$refs.split.setTopSize(this.task.collapsed ? 0 : 100000, true, false)
      await this.$nextTick()
      const content = document.querySelector('.full-task .full-task-descr .scrollable-content')
      // console.log('CALC', content, content && content.clientHeight, content && content.scrollHeight)
      if (content) {
        this.maxTopSize = content.scrollHeight + 50
        // console.log('CALC2', this.maxTopSize)
      }
      await this.$nextTick()
      this.$refs.split.calculateSizes()
      this.$refs.split.setTopSize(this.task.collapsed ? 0 : 100000)

      console.log('init ok')
      this.$store.dispatch(actionTypes.CHAT_OPENED, { chatId: this.task.jid })
    },
    openContactChat (jid) {
      const { getters } = this.$store
      if (jid === getters.getUserId || !contactsStore.getters.contact(jid)) {
        return
      }

      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid,
        },
      })
    },
    displayName (id) {
      return contactsStore.getters.contactDisplayName(id)
    },
    openModal () {
      uiStore.actions.showModal({ instance: 'new-task', payload: { jid: this.task.jid } })
      window.goal('taskControls', { taskControls: 'Редактировать задачу − из шторки задачи' })
    },
    async changeTaskStatus () {
      if (this.taskStatusLoading) return

      this.taskStatusLoading = true
      try {
        await tasksStore.actions.changeStatus({ jid: this.task.jid })
        window.goal('taskControls', { taskControls: 'Завершить задачу − из шторки задачи' })
      } catch (e) {
        uiStore.actions.showModal({
          instance: 'universal-yes-no',
          payload: {
            title: this.$t('common.error'),
            noText: this.$t('common.close'),
            text: this.$t('tasks.errorChangingStatus'),
          },
        })
      } finally {
        this.taskStatusLoading = false
      }
    },
    async changeTaskCustomStatus (status) {
      if (this.taskStatusLoading) return

      if (status.name === this.currentStatus.name) return

      this.taskStatusLoading = true
      const payload = {
        jid: this.task.jid,
        status: status.name || this.nextStatus.name,
      }
      try {
        await tasksStore.actions.changeStatus(payload)
      } catch (e) {
        uiStore.actions.showModal({
          instance: 'universal-yes-no',
          payload: {
            title: this.$t('common.error'),
            noText: this.$t('common.close'),
            text: this.$t('tasks.errorChangingStatus'),
          },
        })
      } finally {
        this.taskStatusLoading = false
      }
    },
  },
}
