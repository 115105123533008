import { getDateWithDifference } from '@/api/v3/DataGenerator'
import i18n from '@/i18n'
import store, { groupsStore, tasksStore, teamsStore } from '@/store'
import { canMarkMessageImportant, isMessageEditable } from '@/utils'
import { goalChatOneMessageActions, goalMessageCommentControls, goalTaskControls } from '@/analytics'

export const REACTION_POPOUT_ID = 'reaction'

export const isEditable = (model: TADA.Message): boolean => {
  const { editableUntil } = model

  // TODO: Может вместо того, чтобы каждый раз вызывать getDateWithDifference('iso')
  // Записывать в store (vue'шный) время открытия чата и сравнивать с ним?
  return !!editableUntil && getDateWithDifference() <= new Date(editableUntil)
}

export interface Tool {
  condition?: (message: TADA.Message) => boolean;
  event: string;
  description?: string;
  fa?: string;
  icon?: string;
  size?: number;
  offsetX?: number;
  popoutId?: string;
  onClick?: () => void;
}

export const enum ToolsType {
  NONE = 'none',
  UNDELIVERED = 'undelivered',
  REGULAR = 'regular'
}

export const MAX_SHORTCUT_BUTTONS = 5

const resendMessage: Tool = {
  event: 'resend',
}

const trashMessage: Tool = {
  fa: 'far fa-trash-alt',
  event: 'trash',
}

const commentMessage: Tool = {
  condition: (model: TADA.Message) => {
    const { threads_enabled: isEnabled } = window.FEATURES || {}

    if (!isEnabled) return false

    if (model.chatType !== 'group') return false

    return !teamsStore.getters.isSingleGroupTeam()
  },
  event: 'comment',
  onClick: goalMessageCommentControls.commentMessageByMessagesClick,
}

const createTask: Tool = {
  condition: () => !teamsStore.getters.isSingleGroupTeam(),
  event: 'task',
  onClick: goalTaskControls.createTaskByMessageClick,
}

const createMeeting: Tool = {
  condition: () => !teamsStore.getters.isSingleGroupTeam() && !!teamsStore.getters.currentTeam.me.canCreateMeeting,
  icon: 'calendar',
  event: 'meeting',
  onClick: goalChatOneMessageActions.meetingClick,
}

const addToChecklist: Tool = {
  fa: 'far fa-check-square',
  event: 'taskItem',
  condition: (model: TADA.Message) => {
    if (
      model.chatType !== 'task' ||
      model.content.type !== TADA.MessageType.PLAIN ||
      !model.content.text ||
      teamsStore.getters.isSingleGroupTeam()
    ) return false

    const task = tasksStore.state.data[model.chatId]
    if (
      !task ||
      task.taskStatus === 'done' ||
      !task.changeableFields.includes('items')
    ) return false

    return true
  },
}

const addReaction: Tool = {
  fa: 'far fa-smile',
  event: 'smile-o',
  popoutId: REACTION_POPOUT_ID,
  condition: (model: TADA.Message) => !model.isStandalone,
  onClick: goalChatOneMessageActions.showReactionsClick,
}

const replyToMessage: Tool = {
  icon: 'reply',
  event: 'reply',
  condition: (model: TADA.Message) => !model.isStandalone,
  onClick: goalChatOneMessageActions.replyClick,
}

const forwardMessage: Tool = {
  condition: () => !teamsStore.getters.isSingleGroupTeam(),
  icon: 'forward',
  event: 'forward',
  onClick: goalChatOneMessageActions.forwardClick,
}

const pinMessage: Tool = {
  icon: 'pin',
  description: 'pin',
  event: 'pin',
  condition: (model: TADA.Message) => {
    if (model.chatType !== 'group') return false

    const group = groupsStore.state.data[model.chatId]

    return Boolean(
      group?.changeableFields?.includes('pinned_message') &&
      group.pinnedMessage?.messageId !== model.messageId,
    )
  },
  onClick: goalChatOneMessageActions.pinClick,
}

const unpinMessage: Tool = {
  icon: 'unpin',
  description: 'unpin',
  event: 'pin',
  condition: (model: TADA.Message) => {
    if (model.chatType !== 'group') return false

    const group = groupsStore.state.data[model.chatId]

    return Boolean(
      group?.changeableFields?.includes('pinned_message') &&
      group.pinnedMessage?.messageId === model.messageId,
    )
  },
  onClick: goalChatOneMessageActions.unpinClick,
}

const copyMessage: Tool = {
  icon: 'copy',
  event: 'copy',
  size: 14,
  onClick: goalChatOneMessageActions.copyClick,
}

const markImportant: Tool = {
  icon: 'important',
  event: 'important',
  description: 'important',
  condition: (model: TADA.Message) => (
    canMarkMessageImportant(model) &&
    !model.important
  ),
  size: 11,
  onClick: () => goalChatOneMessageActions.importantClick(true),
}

const unmarkImportant: Tool = {
  icon: 'important',
  event: 'important',
  description: 'notImportant',
  condition: (model: TADA.Message) => {
    const { isFirst } = model
    if (model.chatType === 'thread' && isFirst) return false
    return canMarkMessageImportant(model) && model.important
  },
  size: 11,
  onClick: () => goalChatOneMessageActions.importantClick(false),
}

const editMessage: Tool = {
  icon: 'tasks/ic_edit_20',
  event: 'edit',
  condition: (model: TADA.Message) => isMessageEditable(model),
  onClick: goalChatOneMessageActions.editClick,
}

const hidePreview: Tool = {
  fa: 'far fa-eye-slash',
  event: 'hidePreview',
  condition: (model: TADA.Message) => {
    const { sender, content, editableUntil, isStandalone, links } = model

    if (isStandalone || sender !== store.getters.getUserId || content.type !== TADA.MessageType.PLAIN) return false

    if (!links.some(l => !!l.preview)) return false

    return !editableUntil || getDateWithDifference() <= new Date(editableUntil)
  },
  onClick: goalChatOneMessageActions.hidePreviewClick,
}

const deleteMessage: Tool = {
  icon: 'tasks/ic_trash_20',
  event: 'delete',
  condition: (model: TADA.Message) => {
    const { isStandalone, isFirst } = model
    if (isStandalone) return false
    if (model.chatType === 'thread' && isFirst) return false

    const chat: TADA.Chat | undefined = store.getters.chat(model.chatId)
    if (chat?.canDeleteAnyMessage) return true

    return isMessageEditable(model)
  },
  offsetX: 4,
  onClick: goalChatOneMessageActions.deleteClick,
}

const precondition = (tool: Tool, condition: (message: TADA.Message) => boolean) => {
  return (message: TADA.Message) => condition(message) && !(tool.condition && !tool.condition(message))
}

const tools: {
  [ToolsType.UNDELIVERED]: Array<Tool>;
  [ToolsType.REGULAR]: Array<Tool>;
} = {
  [ToolsType.UNDELIVERED]: [
    resendMessage,
    trashMessage,
  ],
  [ToolsType.REGULAR]: [
    commentMessage,
    createTask,
    { ...createMeeting, condition: precondition(createMeeting, msg => msg.chatType !== 'task') },
    addToChecklist,
    { ...createMeeting, condition: precondition(createMeeting, msg => msg.chatType === 'task') },
    addReaction,
    replyToMessage,
    forwardMessage,
    pinMessage,
    unpinMessage,
    copyMessage,
    markImportant,
    unmarkImportant,
    editMessage,
    hidePreview,
    deleteMessage,
  ],
}

const setupTools = (tools: Array<Tool>): Array<Tool> => {
  tools = tools.map(t => {
    // TODO: refactor this without concatenate keys of i18n.t()
    const key = t.description || t.event || t.icon || t.fa
    return { ...t, description: i18n.t('chattape.messageActions.' + key).toString() }
  })

  return tools
}

export const debugTool: Tool = {
  fa: 'fas fa-bug',
  event: 'debug',
  description: 'Debug',
}

export const getUndeliveredTools = () => setupTools(tools.undelivered)
export const getRegularTools = () => setupTools(tools.regular)
