import Quill from 'quill'

import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'

import EmojiBlot from '@/components/Chat/ReplyArea/EditableArea/Blots/EmojiBlot'
import MentionBlot from '@/components/Chat/ReplyArea/EditableArea/Blots/MentionBlot'
import DraftStorageModule from '@/components/Chat/ReplyArea/EditableArea/Modules/DraftStorageModule'
import AutocompleteModule from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule'
import ComposingStateModule from '@/components/Chat/ReplyArea/EditableArea/Modules/ComposingStateModule'
import EmojiListModule from '@/components/Chat/ReplyArea/EditableArea/Modules/EmojiListModule'
import MessageActionModule from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule'
import MessageSendingModule from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageSendingModule'
import LinkCheckerModule from '@/components/Chat/ReplyArea/EditableArea/Modules/LinkCheckerModule'
import CustomClipboardModule from '@/components/Chat/ReplyArea/EditableArea/Modules/CustomClipboardModule'
import * as Keyboard from '@/components/Chat/ReplyArea/EditableArea/Keyboard'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'

export const enum Modules {
  DRAFT_STORAGE = 'draftStorage',
  AUTOCOMPLETE = 'autocomplete',
  COMPOSING_STATE = 'composingState',
  EMOJI_LIST = 'emojiList',
  MESSAGE_ACTION = 'messageAction',
  MESSAGE_SENDING = 'messageSending',
  LINK_CHECKER = 'linkChecker',
  CUSTOM_CLIPBOARD = 'customClipboard'
}

export const Formats = {
  EMOJI: 'emoji',
  MENTION: 'mention',
}

/**
 * For copy from editor without extra empty line, changed p to div
 * {@link http://info-line.net/videos/chetvertyj-videourok-kursa-po-html-paragrafy-i-zagalovki}
 * {@link https://github.com/quilljs/quill/issues/861#issuecomment-239961806}
 */
const Block = Quill.import('blots/block')
Block.tagName = 'DIV'

Quill.register(Block, true)

Quill.register('formats/' + Formats.EMOJI, EmojiBlot)
Quill.register('formats/' + Formats.MENTION, MentionBlot)

Quill.register('modules/' + Modules.AUTOCOMPLETE, AutocompleteModule)
Quill.register('modules/' + Modules.DRAFT_STORAGE, DraftStorageModule)
Quill.register('modules/' + Modules.COMPOSING_STATE, ComposingStateModule)
Quill.register('modules/' + Modules.EMOJI_LIST, EmojiListModule)
Quill.register('modules/' + Modules.MESSAGE_ACTION, MessageActionModule)
Quill.register('modules/' + Modules.MESSAGE_SENDING, MessageSendingModule)
Quill.register('modules/' + Modules.LINK_CHECKER, LinkCheckerModule)
Quill.register('modules/' + Modules.CUSTOM_CLIPBOARD, CustomClipboardModule)
/**
 * TODOs:
 *  1) Use pasteEntry from inside commitAutocomplete
 *  2) Flush LinkCheckerModule after pasteEntry
 */
export default class {
  quill: Quill
  chatId: string
  element: HTMLElement

  constructor ({ element, chatId, placeholder }: { element: HTMLElement; chatId: string; placeholder: string }) {
    this.chatId = chatId
    this.element = element

    const commonModuleOptions = { chatId }

    this.quill = new Quill(this.element, {
      modules: {
        toolbar: false,
        keyboard: {
          bindings: Keyboard.getBindings(),
        },
        [Modules.MESSAGE_SENDING]: true,
        [Modules.LINK_CHECKER]: commonModuleOptions,
        [Modules.AUTOCOMPLETE]: commonModuleOptions,
        [Modules.DRAFT_STORAGE]: window.FEATURES.server_drafts ? commonModuleOptions : false,
        [Modules.COMPOSING_STATE]: commonModuleOptions,
        [Modules.MESSAGE_ACTION]: commonModuleOptions,
        [Modules.CUSTOM_CLIPBOARD]: true,
        [Modules.EMOJI_LIST]: true,
      },
      formats: [Formats.EMOJI, Formats.MENTION],
    })

    this.setActiveChat(chatId)
    this.setPlaceholder(placeholder)
  }

  public destroy = () => {
    EventBus.$emit(EventTypes.BEFORE_DESTROY)
    EventBus.$off()
  }

  public setActiveChat = (chatId: string) => {
    this.chatId = chatId
    this.quill.setText('', 'silent')

    EventBus.$emit(EventTypes.SET_CHAT_ID, chatId)

    Utils.focusArea(this.quill, true)
  }

  public setPlaceholder = (placeholder: string) => {
    const { root } = this.quill
    root && root.setAttribute('data-placeholder', placeholder)
  }
}
