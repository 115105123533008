import Quill from 'quill'

import Messages from '@/store/messages'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import BaseAction, { ActionOptions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule/BaseAction'
import { Actions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule'
import { defaultLogger } from '@/loggers'

export default class EditingAction extends BaseAction {
  constructor (public quill: Quill, chatId: string) {
    super(chatId)

    this.quill.keyboard.addBinding({ key: 'Up' }, () => {
      defaultLogger.info('Quill edit sequence started...')
      const isEmpty = Utils.isEmpty(this.quill)
      if (!isEmpty) return true

      defaultLogger.info(
        'Quill edit TOGGLE_MESSAGE_ACTIVE',
        { messageId: undefined, goal: Actions.EDITING },
      )
      ChatEventBus.$emit(Events.TOGGLE_MESSAGE_ACTIVE, { messageId: undefined, goal: Actions.EDITING })

      return false
    })
  }

  action (options: ActionOptions): boolean {
    if (!this.chatId) return false

    Utils.clearArea(this.quill)

    const { messageId, goal } = options
    if (!messageId || typeof messageId !== 'string' || goal !== Actions.EDITING) return false

    const message = Messages.getMessage(this.chatId, messageId)
    if (!message) return false

    const { content, linkedMessages, uploads } = message
    if (!content) return false

    const { text } = content
    // there is got to be at least something that we can edit!
    if (!text && !linkedMessages && !uploads) return false

    Utils.insertAndFormat(this.quill, text)
    Utils.focusArea(this.quill, true)

    return true
  }

  flush () { }
}
