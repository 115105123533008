import { uiStore, teamsStore } from '@/store'
import router from '@/router'

import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'

export const nameClickEvent = (jid: string) => {
  uiStore.actions.switchRightBarInstance({
    instance: 'contact-profile',
    payload: jid,
  })
}

export const groupClickEvent = (jid: string) => {
  router.push({
    name: 'Chat',
    params: {
      teamId: teamsStore.getters.currentTeam.uid,
      jid,
    },
  })
}

export const selectionClickHandler = (element: HTMLElement, value?: boolean) => {
  if (!element) return false

  const messageId = element.getAttribute(MessageDOMAttribute.ID)
  if (!messageId) return false

  const className = 'selected'
  const contains = element.classList.contains(className)
  const select = value === undefined ? !contains : value

  if (select === contains) return select

  select
    ? element.classList.add(className)
    : element.classList.remove(className)

  uiStore.actions.toggleMessageSelection({ messageId, add: !!select })

  return select
}
