//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { Events, TaskEventBus } from '@/components/Tasks/EventBus'
import { tasksStore, teamsStore, uiStore } from '@/store'
import Messages from '@/store/messages'

const HIDE_ITEMS_KEY = 'tasksHideCheckedItems'
const UNSAVED_ITEMS_KEY = 'tasksUnsavedItems'

export default {
  components: {
    Editor: () => import('./TaskItems/Editor.vue'),
    Header: () => import('./TaskItems/Header.vue'),
    List: () => import('./TaskItems/List.vue'),
  },
  props: {
    jid: {
      type: String,
    },
  },
  data () {
    return {
      editUid: null,
      showChecked: true,
      loading: false,
      error: null,
      itemsLoading: [],
      lastDeletedItem: null,
      unsavedItems: {},
    }
  },
  created () {
    TaskEventBus.$on(Events.ADD_TASK_ITEM, this.onAddItem)

    try {
      this.unsavedItems = JSON.parse(window.localStorage[UNSAVED_ITEMS_KEY])
    } catch (e) {
      console.warn(`[TaskItems.created err]: ${e}`)
    }
  },
  destroyed () {
    TaskEventBus.$off(Events.ADD_TASK_ITEM, this.onAddItem)
  },
  computed: {
    errorText () {
      const e = this.error
      if (!e) return null

      if (e.message === 'Network Error' || e.status === 504) {
        return this.$t('tasks.taskItems.addCheckList')
      }

      if (e.details) {
        return (e.error + '. ' +
          Object.keys(e.details).map(field => `${field}: ${e.details[field]}.`).join(' '))
          .replace('..', '.')
      }

      return e.message || e
    },
    sortedItems () {
      const sorted = [...this.task.items].sort(
        (a, b) => a.sortOrdering - b.sortOrdering,
      )

      if (this.lastDeletedItem) {
        // Bodge for a brief moment, when items still contains `lastDeletedItem`
        // api DELETE does not return updated task, so have to wait for
        // socket event to update task after an item is deleted
        const filtered = [
          ...sorted.filter(item => item.uid !== this.lastDeletedItem.uid),
        ]
        return [...filtered, this.lastDeletedItem].sort(
          (a, b) => a.sortOrdering - b.sortOrdering,
        )
        // const index = sorted.findIndex(i => i.uid === this.lastDeletedItem.uid)
        // if (index > -1) sorted.splice(index, 1, this.lastDeletedItem)
        // return [...sorted]
      }

      return sorted
    },
    displayItems () {
      if (this.showChecked) return this.sortedItems
      return this.sortedItems.filter(item => !item.checked)
    },
    deletedItems () {
      return this.lastDeletedItem ? [this.lastDeletedItem.uid] : []
    },
    counters () {
      return Object.fromEntries(
        this.sortedItems
          .filter(item => !this.deletedItems.includes(item.uid))
          .map((item, index) => [item.uid, index + 1]),
      )
    },
    canEdit () {
      return this.task.changeableFields.indexOf('items') !== -1
    },
    doneItems () {
      const items = this.task.items
      if (!items.length) return

      return items.reduce((sum, item) => sum + (item.checked ? 1 : 0), 0)
    },
    percent () {
      const ratio = this.doneItems / this.task.items.length || 0
      return '' + Math.round(ratio * 100)
    },
    progressStyle () {
      const ratio = this.doneItems / this.task.items.length
      return {
        width: `${ratio * 100}%`,
        'background-color': ratio === 1 ? '#60c050' : 'var(--main-accent)',
      }
    },
    task () {
      return tasksStore.state.data[this.jid]
    },
  },
  methods: {
    onCreateTask (uid) {
      const item = this.task.items.find(item => item.uid === uid)
      if (!item) throw new Error(`Task item with UID ${uid} not found!`)

      if (item.subtask) {
        window.goal('taskControls', { taskControls: 'Создать копию задачи — из подзадачи чеклиста' })
      } else {
        window.goal('taskControls', { taskControls: 'Создать задачу — из элемента чеклиста' })
      }

      uiStore.actions.showModal({
        instance: 'new-task',
        payload: {
          cloneJid: item.subtask ? item.subtask.jid : null,
          item: item.subtask ? null : item.uid,
          linkedMessages: [],
        },
      })
    },
    async onDelete (uid) {
      if (this.itemsLoading.includes(uid)) return
      const item = this.task.items.find(item => item.uid === uid)
      if (!item.canToggle) return

      await this.deleteItem(uid)
      window.goal('taskControls', { taskControls: 'Удалить элемент чеклиста' })
    },
    async onOrderChanged ({ oldIndex, newIndex }) {
      const changed = this.displayItems[oldIndex]
      if (!changed) throw new Error(`Task item #${oldIndex} not found!`)

      if (oldIndex === newIndex) return

      let sortOrdering = newIndex

      // some task items might be hidden, so fix it
      if (!this.showChecked) {
        sortOrdering += this.sortedItems
          .slice(0, newIndex)
          .filter(item => item.checked)
          .length
      }

      // does not start at zero, so fix it
      sortOrdering += 1

      // is always a multiple of 10
      sortOrdering *= 10

      // TODO: fix for backend bug with first and last items
      sortOrdering += (newIndex > oldIndex) ? 1 : -1

      this.error = null
      this.itemsLoading.push(changed.uid)

      try {
        const params = { sort_ordering: sortOrdering }
        await api.tasks.editItem(this.task.jid, changed.uid, params)
      } catch (e) {
        this.error = e
      }

      window.goal('taskControls', { taskControls: 'Переместить элемент чеклиста' })
      this.itemsLoading = this.itemsLoading.filter(uid => uid !== changed.uid)
    },
    navTo (ev, jid) {
      if (ev.ctrlKey || ev.shiftKey || ev.metaKey) {
        return
      }
      ev.preventDefault()
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid,
        },
      })
    },
    handleItemClick (ev, item) {
      if (item.subtask) {
        this.navTo(ev, item.subtask.jid)
      } else {
        window.goal('taskControls', { taskControls: 'Редактировать элемент чеклиста кликом по пункту' })
        this.toggleEdit(item.uid)
      }
    },
    handleEdit (uid) {
      window.goal('taskControls', { taskControls: 'Редактировать элемент чеклиста из «...»-меню' })
      this.toggleEdit(uid)
    },
    toggleEdit (uid) {
      this.canEdit && (this.editUid = uid)
    },
    onUnsaved (uid, { text }) {
      uid = uid || this.task.jid
      if (text) {
        this.$set(this.unsavedItems, uid, { text })
      } else {
        this.$delete(this.unsavedItems, uid)
      }

      window.localStorage[UNSAVED_ITEMS_KEY] = JSON.stringify(this.unsavedItems)
    },
    toggleShowChecked () {
      let hidden = []

      try {
        hidden = JSON.parse(window.localStorage[HIDE_ITEMS_KEY])
      } catch (e) {
        console.warn(`[TaskItems.toggleShowChecked err]: ${e}`)
      }

      this.showChecked = !this.showChecked

      if (this.showChecked) {
        hidden = hidden.filter(jid => jid !== this.jid)
      } else {
        hidden.push(this.jid)
      }

      window.localStorage[HIDE_ITEMS_KEY] = JSON.stringify(hidden)
      if (!this.showChecked) window.goal('taskControls', { taskControls: 'Скрыть завершённые элементы чеклиста' })
    },
    async toggleItem (uid) {
      if (this.itemsLoading.includes(uid)) return
      const item = this.task.items.find(item => item.uid === uid)
      if (!item.canToggle) return

      this.itemsLoading.push(uid)
      try {
        const result = await api.tasks.editItem(this.task.jid, uid, { checked: !item.checked })
        const newTask = this.task
        newTask.items = this.task.items.filter(item => item.uid !== result.uid)
        newTask.items.push(result)
        newTask.items.sort((a, b) => a.sortOrdering - b.sortOrdering)
        tasksStore.mutations.addTask({ jid: newTask.jid, task: newTask })
      } catch (e) {
        this.error = e
      } finally {
        this.itemsLoading = this.itemsLoading.filter(i => i !== uid)
      }
    },
    async deleteItem (uid) {
      this.itemsLoading.push(uid)
      try {
        await api.tasks.deleteItem(this.task.jid, uid)
        const item = this.task.items.find(item => item.uid === uid)

        this.lastDeletedItem = {
          ...item,
          sortOrdering: item.sortOrdering - 1,
          deleted: true,
        }
      } catch (e) {
        this.error = e
      } finally {
        this.itemsLoading = this.itemsLoading.filter(i => i !== uid)
      }

      this.onUnsaved(uid, { text: null })
    },
    onAddItem (jid) {
      const message = Messages.getMessage(this.task.jid, jid)
      // console.log('trying to add task item', message)

      const text = message && message.content && message.content.text
      if (!text) return

      this.addItem(text, null)
    },
    async addItem (text, doneFn) {
      if (text.trim() === '') {
        return
      }

      if (text.trim().length > window.FEATURES.max_taskitem_length) {
        this.error = this.$t('tasks.taskItems.maxLengthItem', { max: window.FEATURES.max_taskitem_length })
        return
      }

      let success = false
      this.loading = true
      this.error = null

      try {
        // await new Promise(resolve => setTimeout(resolve, 5000))
        const result = await api.tasks.addItem(this.task.jid, { text })
        if (result.subtask) {
          window.goal('taskControls', { taskControls: 'Добавить подзадачу в чеклист' })
        }
        const newTask = this.task
        newTask.items.push(result)
        tasksStore.mutations.addTask({ jid: newTask.jid, task: newTask })
        success = true
      } catch (e) {
        this.error = e
      }
      this.loading = false

      if (success && doneFn) {
        doneFn()
      }
      this.onUnsaved(null, { text: null })
    },
    async restoreItem () {
      const uid = this.lastDeletedItem.uid
      this.itemsLoading.push(uid)
      this.error = null

      try {
        await api.tasks.addItem(this.task.jid, {
          checked: this.lastDeletedItem.checked,
          sort_ordering: this.lastDeletedItem.sortOrdering,
          text: this.lastDeletedItem.text,
        })

        this.lastDeletedItem = null
      } catch (e) {
        this.error = e
      }
      this.itemsLoading = this.itemsLoading.filter(i => i !== uid)
    },
    async editItem (uid, text, doneFn) {
      if (text.trim() === '') {
        await this.deleteItem(uid)
        return
      }

      if (text.trim().length > window.FEATURES.max_taskitem_length) {
        this.error = this.$t('tasks.taskItems.maxLengthItem', { max: window.FEATURES.max_taskitem_length })
        return
      }

      let success = false
      this.itemsLoading.push(uid)
      this.error = null

      try {
        // await new Promise(resolve => setTimeout(resolve, 5000))
        const result = await api.tasks.editItem(this.task.jid, uid, { text })
        const newTask = this.task
        newTask.items = this.task.items.filter(item => item.uid !== result.uid)
        newTask.items.push(result)
        newTask.items.sort((a, b) => a.sortOrdering - b.sortOrdering)
        tasksStore.mutations.addTask({ jid: newTask.jid, task: newTask })

        success = true
      } catch (e) {
        this.error = e
      }
      this.itemsLoading = this.itemsLoading.filter(i => i !== uid)

      if (success && doneFn) {
        doneFn()
        this.editUid = null
        this.onUnsaved(uid, { text: null })
      }
    },
    startAddItem () {
      this.editUid = 'new'
      window.goal('taskControls', { taskControls: 'Добавить элемент чеклиста' })
    },
  },
  watch: {
    jid: {
      immediate: true,
      handler () {
        this.lastDeletedItem = null
        try {
          this.showChecked = JSON.parse(window.localStorage[HIDE_ITEMS_KEY]).indexOf(this.jid) === -1
        } catch (e) {
          this.showChecked = true
        }
      },
    },
  },
}
