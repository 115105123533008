import { render, staticRenderFns } from "./SelectedMessagesBar.vue?vue&type=template&id=aafc5b6a&scoped=true&"
import script from "./SelectedMessagesBar.vue?vue&type=script&lang=js&"
export * from "./SelectedMessagesBar.vue?vue&type=script&lang=js&"
import style0 from "./SelectedMessagesBar.vue?vue&type=style&index=0&id=aafc5b6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aafc5b6a",
  null
  
)

export default component.exports
import {QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
