import i18n from '@/i18n'

import DOMUtils, { flushElement } from '@/utils/DOM'
import { uiStore, teamsStore } from '@/store'
import { getChatType, getMentionPrefix } from '@/utils'
import router from '@/router'

import { createImageContent } from '@/components/Chat/Instance/DOM/Components/Content/Image'
import { createVideoContent } from '@/components/Chat/Instance/DOM/Components/Content/Video'
import { showUserInfoPopup } from '../UserInfoPopup'
import { createFileUpload } from './File'

const ATTRIBUTE_CHAT_LINK = 'data-chat-link'

const createLinkCaption = (text: string): HTMLElement => {
  const caption = text.indexOf('http') === 0 ? text.replace('http://', '').replace('https://', '') : text
  return DOMUtils.createElement('div', { class: 'link-caption' }, caption)
}

const createExtendedLink = ({ url, title, text }: { url: string; title: string; text: string }): HTMLElement => {
  const captionElement = createLinkCaption(text)
  const titleElement = DOMUtils.createElement('div', { class: 'link-title' }, title)
  const linkContentElement = DOMUtils.createElement('div', { class: 'extended-link' }, titleElement, captionElement)

  return DOMUtils.createElement('a', {
    class: 'extended-link-wrapper',
    target: '_blank',
    href: url,
    alt: captionElement.textContent,
  }, linkContentElement)
}

const handleChatLinkClick = function (this: HTMLElement, e: Event) {
  if ((window as any).isHeadless) return // open in new tab

  // detect modifiers and allow browser default befaviour for them
  if (e instanceof MouseEvent) {
    if (e.ctrlKey || e.shiftKey || e.metaKey) {
      return
    }
  }

  e.stopPropagation()
  e.preventDefault()

  const element = this
  if (!element || !(element instanceof HTMLElement)) return

  const jid = element.getAttribute('data-jid')
  if (!jid) return
  if (jid.startsWith('sd-')) {
    uiStore.actions.switchRightBarInstance({ instance: 'contacts', payload: jid })
    return
  }

  if (getChatType(jid) === 'direct') {
    showUserInfoPopup(jid, element)
    return
  }
  router.push({
    name: 'Chat',
    params: {
      teamId: teamsStore.getters.currentTeam.uid,
      jid,
    },
  })
}

const handleChatLink = (contentElement: HTMLElement, link: TADA.MessageLink): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pattern, url } = link

  const mentionPrefix = getMentionPrefix(url)
  if (!mentionPrefix) return false

  const elements = contentElement.querySelectorAll(`[href="${url}"]`)
  for (let i = 0; i < elements.length; i++) {
    const linkElement = elements[i]
    if (!linkElement || linkElement.getAttribute(ATTRIBUTE_CHAT_LINK) !== null) continue

    const mentionPrefix = getMentionPrefix(url)
    if (!mentionPrefix) {
      throw new Error(`URL prefix error. Message prefix unknown in ${url}`)
    }
    const prefixLength = mentionPrefix.length

    const jid = url.substr(prefixLength)
    linkElement.setAttribute('href', `${window.location.origin}/${(window as any).currentTeamId}/chats/${jid}`)
    linkElement.setAttribute(ATTRIBUTE_CHAT_LINK, url)
    linkElement.setAttribute('data-jid', jid)
    linkElement.removeAttribute('title')

    linkElement.addEventListener('click', handleChatLinkClick)
  }
  return true
}

const createImageLink = ({ url, text, title, previewURL, preview2xURL, previewWidth, previewHeight }: { url: string; text: string; title: string; previewURL: string; preview2xURL: string; previewWidth: number; previewHeight: number }, youtubeId?: string): HTMLElement => {
  const extendedLinkElm = createExtendedLink({ url, text, title })

  const basisElm = extendedLinkElm.querySelector('.link-caption')
  if (!basisElm) return extendedLinkElm

  const content = { name: title, previewURL, preview2xURL, previewWidth, previewHeight }
  let imageElm: HTMLElement
  if (youtubeId) {
    imageElm = createVideoContent(content as any, 250, false)
    imageElm.onclick = event => {
      event.preventDefault()
      event.stopPropagation()

      const payload = Object.assign({}, content, { type: 'ytvideo', youtubeId })
      uiStore.actions.showModal({ instance: 'FileViewer', payload })
    }
  } else {
    imageElm = createImageContent(content, 250, false)
  }
  imageElm.style.display = 'block'
  basisElm.appendChild(imageElm)

  return extendedLinkElm
}

const createTinyImageLink = ({ url, text, title, previewURL }: { url: string; text: string; title: string; previewURL: string }): HTMLElement => {
  const refElement = DOMUtils.createElement('a', { target: '_blank', href: url, class: 'o-no-default-a' }, ' ')
  const imageCellElement = DOMUtils.createElement('td', {
    class: 'image-cell',
    style: { 'background-image': `url("${previewURL}")` },
  }, refElement)

  const extendedLinkElement = createExtendedLink({ url, title, text })
  const contentCellElement = DOMUtils.createElement('td', { class: 'content-cell' }, extendedLinkElement)

  const trElm = DOMUtils.createElement('tr', {}, imageCellElement, contentCellElement)
  return DOMUtils.createElement('table', { class: 'attachment-link-preview' }, trElm)
}

const createUpload = (link: TADA.MessageLink, sender: string): HTMLElement | null => {
  const { url, text, uploads, preview } = link
  if (!uploads || uploads.length === 0) return null

  const title = preview ? preview.title : i18n.t('chattape.untitledFile').toString()

  const upload = uploads[0]
  const { preview: previewUpload } = upload
  if (!previewUpload) return createFileUpload({ name: upload.name, size: upload.size, processing: upload.processing, mediaURL: upload.url, senderJid: sender })

  const { height: previewHeight, width: previewWidth, url: previewURL, url2x: preview2xURL } = previewUpload

  if (Math.max(previewHeight, previewWidth) <= 250) return createTinyImageLink({ url, title, text, previewURL })

  return createImageLink({ url, title, text, previewURL, preview2xURL, previewHeight, previewWidth })
}

const extendQuoteLink = (contentElement: HTMLElement, link: TADA.MessageLink): boolean => {
  const { uploads } = link
  if (!uploads || uploads.length !== 1) return false

  const upload = uploads[0]
  const { preview } = upload
  if (!preview) return false

  const firstElement = contentElement.firstElementChild
  if (!firstElement || firstElement.className !== 'quote') return false

  const lastElement = firstElement.lastElementChild
  if (!lastElement || lastElement.getAttribute('data-local') !== 'true') return false

  flushElement(firstElement)

  const mediaElement = createImageContent({
    mediaURL: upload.url,
    previewURL: preview.url,
    preview2xURL: preview.url2x,
    previewHeight: preview.height,
    previewWidth: preview.width,
    name: upload.name,
    animated: false,
  }, 150)

  firstElement.appendChild(mediaElement)

  return true
}

const extendSingleLink = (contentElement: HTMLElement, link: TADA.MessageLink): boolean => {
  if (contentElement.childNodes.length !== 1) return false

  const { url, text, preview, uploads, youtube_id: youtubeId } = link
  if (!preview) return false

  const { title } = preview

  const regularLinkElement = contentElement.firstElementChild as HTMLElement
  if (!regularLinkElement || regularLinkElement.getAttribute('href') !== link.url) return false

  let extendedLinkWrapperElement: HTMLElement

  const upload = uploads && uploads[0]
  if (upload?.preview) {
    const { width: previewWidth, height: previewHeight, url: previewURL, url2x: preview2xURL } = upload.preview

    const content = { url, title, text, previewWidth, previewHeight, previewURL, preview2xURL }
    extendedLinkWrapperElement = createImageLink(content, youtubeId)
  } else {
    extendedLinkWrapperElement = createExtendedLink({ url, title, text })
  }
  regularLinkElement.replaceWith(extendedLinkWrapperElement)
  return true
}

export const extendLinks = (contentElement: HTMLElement, links: Array<TADA.MessageLink>, sender: string) => {
  if (links.length === 1) {
    const singleLink = extendSingleLink(contentElement, links[0])
    if (singleLink) return

    const quoteLink = extendQuoteLink(contentElement, links[0])
    if (quoteLink) return
  }

  let fragment: DocumentFragment | null = null
  links.forEach(link => {
    const isChatLink = handleChatLink(contentElement, link)
    if (isChatLink) return

    const uploadElement = createUpload(link, sender)
    if (!uploadElement) return

    fragment = fragment || document.createDocumentFragment()
    fragment.appendChild(uploadElement)
  })

  fragment && contentElement.appendChild(fragment)
}
