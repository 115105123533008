import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { defaultLogger } from '@/loggers'

const CHECK_THRESHOLD_ACROSSING_PIXELS = 50
const BORDER_THRESHOLD_PIXELS_OFFSET = 150

export default {
  created () {
    this.previousScrollTop = null
    this.isImportantHook = this.$options.name === 'ImportantHook'
  },
  mounted () {
    this.startObserveVisibility()
  },
  methods: {
    flushThresholdObserverMixin () {
      this.previousScrollTop = null
      this.isImportantHook = false
    },
    startObserveVisibility () {
      this.setScrollEvent((scrollTop, scrollHeight, clientHeight, manually) => {
        if (Math.abs(this.previousScrollTop - scrollTop) > CHECK_THRESHOLD_ACROSSING_PIXELS) {
          this.checkThresholdAcrossing(scrollTop, scrollHeight, clientHeight, manually)
        }
      })
    },
    checkThresholdAcrossing (scrollTop, scrollHeight, clientHeight, manually) {
      if (!manually || this.previousScrollTop === scrollTop || this.loadingIndicatorStatus !== 'none') return

      this.DHMHandler && this.DHMHandler()

      if (!this.olderMessagesLoading && scrollTop <= BORDER_THRESHOLD_PIXELS_OFFSET) {
        this.handleTopThresholdAcrossing()
        return
      }

      if (this.previousScrollTop === null) {
        this.previousScrollTop = scrollTop
        return
      }

      const scrollTopIsNotLower = this.previousScrollTop <= scrollTop
      const distanceFromBottom = scrollHeight - (scrollTop + clientHeight)
      const bottomThresholdPassed = distanceFromBottom <= BORDER_THRESHOLD_PIXELS_OFFSET

      defaultLogger.debug(
        'before TA 5', '\n',
        'pst', this.previousScrollTop, '\n',
        'scrollTop', scrollTop, '\n',
        'scrollHeight', scrollHeight, '\n',
        'clientHeight', clientHeight, '\n',
        'distanceFromBottom', distanceFromBottom,
      )

      if (scrollTopIsNotLower || bottomThresholdPassed) {
        bottomThresholdPassed && this.handleBottomThresholdAcrossing()
      }
      this.previousScrollTop = scrollTop
    },
    async handleTopThresholdAcrossing () {
      if (this.loadingIndicatorStatus === 'old') return

      if (this.isImportantHook) {
        this.persistScroll({ callback: this.loadNext })
        return
      }

      this.olderMessagesLoading = true
      const messages = await this.instance.fetchMessages(true)
      if (!messages) return

      await this.persistScroll({
        callback: async () => { ChatEventBus.$emit(Events.ADD_OLD_MESSAGES, { chatId: this.chatId, messages }) },
      })

      this.olderMessagesLoading = false
      this.instance.cropChatIfNeeded(true)
      this.instance.toggleHistoryMode()
    },
    async handleBottomThresholdAcrossing () {
      if (this.loadingIndicatorStatus === 'new' || this.isImportantHook) return

      const messages = await this.instance.fetchMessages()
      if (!messages) return

      ChatEventBus.$emit(Events.ADD_NEW_MESSAGES, { chatId: this.chatId, messages })

      this.instance.cropChatIfNeeded()
      this.instance.toggleHistoryMode()
    },
  },
}
