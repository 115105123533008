import MentionBlot, {
  MentionBlotOptions,
} from '@/components/Chat/ReplyArea/EditableArea/Blots/MentionBlot'
import { Formats } from '@/components/Chat/ReplyArea/EditableArea/Instance'
import { popHandler } from '@/components/Chat/ReplyArea/EditableArea/Keyboard'
import AutocompleteDataStore, {
  AutocompleteEntry,
  EntriesPack,
} from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/AutocompleteDataStore'
import NoticeButton from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/NoticeButton'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/Utils'
import * as CommonUtils from '@/components/Chat/ReplyArea/EditableArea/Utils'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'
import { defaultLogger } from '@/loggers'
import { teamsStore } from '@/store'
import Quill, { DeltaOperation, DeltaStatic, Sources } from 'quill'

export const enum SearchType {
  COMMAND = '/',
  CONTACT = '@',
  ALT_CONTACT = '*',
  GROUP = '#'
}

interface AutocompleteModuleOptions {
  maxLookupSequenceLength: number;
  startSymbols: Array<string>;
  ignoreWhitespaces: boolean;
  textTypes: Array<SearchType>;
}

// TODO: typing with AutocompleteEntry interface
interface PasteEntryOptions {
  id: string;
  type: SearchType;
  title?: string;
  key?: string;
}

export default class {
  dataStore: AutocompleteDataStore
  isOpen: boolean

  private chatId: string
  private currentIndex: number
  private currentEntries: Array<AutocompleteEntry> | null
  private cursorPosition: number | null
  private options: AutocompleteModuleOptions

  private currentType: SearchType | null
  private normalizedSequence: string | null
  private typeCharPosition: number | null
  private noticeButton: NoticeButton

  constructor (public quill: Quill, options: any) {
    this.chatId = options.chatId
    this.isOpen = false
    this.currentIndex = 0
    this.currentEntries = null
    this.cursorPosition = null

    this.currentType = null
    this.typeCharPosition = null
    this.normalizedSequence = null

    this.dataStore = new AutocompleteDataStore()
    this.noticeButton = new NoticeButton(this)

    const startSymbols = [
      SearchType.CONTACT,
      SearchType.GROUP,
      SearchType.COMMAND,
      SearchType.ALT_CONTACT,
    ]

    this.options = {
      maxLookupSequenceLength: 64,
      startSymbols: startSymbols,
      ignoreWhitespaces: false,
      textTypes: [SearchType.COMMAND],
    }
    options && Object.assign(this.options, options)

    quill.on('selection-change', (range, oldRange, source) => {
      if (source !== 'user') return

      if (range && range.length === 0) {
        this.handleChange()
        return
      }
      this.flush()
    })

    quill.on('text-change', (delta, oldDelta, source) => {
      if (source !== 'user') return
      this.handleChange()
    })

    EventBus.$on(EventTypes.AUTOCOMPLETE_COMMIT, this.pasteEntry)
    EventBus.$on(
      EventTypes.AUTOCOMPLETE_SELECTED_INDEX,
      ({ index }: { index: number }) => { this.currentIndex = index },
    )
    EventBus.$on(EventTypes.BEFORE_DESTROY, this.destroy)
    EventBus.$on(EventTypes.COMMIT_MESSAGE, this.flush)
    EventBus.$on(EventTypes.SET_CHAT_ID, (chatId: string) => {
      this.flush()
      this.chatId = chatId
    })

    EventBus.$on(EventTypes.FOCUS, () => {
      this.quill.focus()
    })

    this.quill.keyboard.addBinding({ key: 'Escape' }, () => {
      if (this.isOpen) {
        this.emitData(null)
        return false
      }
      return true
    })

    const selectHandler = () => {
      if (this.isOpen) {
        if (this.commitAutocomplete()) return false

        const entry =
          this.currentEntries &&
          this.currentEntries[this.currentIndex] as any
        entry && this.pasteEntry({
          id: entry.meta.jid || entry.displayName,
          type: SearchType.CONTACT,
        })
        return false
      }
      return true
    }
    this.quill.keyboard.addBinding({ key: 'Enter' }, selectHandler)
    popHandler(this.quill, 13)
    this.quill.keyboard.addBinding({ key: 'Tab' }, selectHandler)
    popHandler(this.quill, 9)
    if (!window.FEATURES.is_testing) {
      this.quill.keyboard.addBinding({ key: ' ' }, selectHandler)
      popHandler(this.quill, 32)
    }
    const moveHandler = (isUp?: boolean) => {
      if (this.isOpen) {
        this.emitIndex({
          index: this.currentIndex + (isUp ? -1 : 1),
          fromKeyboard: true,
        })
        return false
      }
      return true
    }
    this.quill.keyboard.addBinding({ key: 'Up' }, () => moveHandler(true))
    this.quill.keyboard.addBinding({ key: 'Down' }, () => moveHandler(false))
  }

  emitIndex ({ index, fromKeyboard }: {
    index: number
    fromKeyboard?: boolean
  }) {
    if (!this.currentEntries) index = 0
    else if (index < 0) index = this.currentEntries.length - 1
    else if (index >= this.currentEntries.length) index = 0

    EventBus.$emit(EventTypes.AUTOCOMPLETE_SELECTED_INDEX, {
      index,
      fromKeyboard,
    })
  }

  emitData = (data: any) => {
    if (!data) {
      this.isOpen = false
      this.currentEntries = null
      this.cursorPosition = null
      this.typeCharPosition = null
      this.normalizedSequence = null
      this.currentType = null
    } else {
      this.isOpen = true
      const { entries } = data
      entries && (this.currentEntries = entries)
    }

    this.emitIndex({ index: 0 })
    EventBus.$emit(EventTypes.AUTOCOMPLETE_CHANGE, data || null)
  }

  private handleChange = async (needUpdate = true) => {
    const cursorPosition = CommonUtils.getCursorPosition(this.quill)
    if (!cursorPosition) {
      this.emitData(null)
      return
    }

    this.cursorPosition = cursorPosition

    const startLookupPosition = Math.max(
      0,
      this.cursorPosition - this.options.maxLookupSequenceLength,
    )
    const lookupSequence = this.quill.getText(
      startLookupPosition,
      this.cursorPosition - startLookupPosition,
    )

    const startIndex = this.getStartIndex(lookupSequence)
    if (startIndex < 0) {
      this.emitData(null)
      return
    }

    const previousSymbol: string | undefined = lookupSequence[startIndex - 1]
    if (previousSymbol && !/\s|\(/.test(previousSymbol)) return

    this.typeCharPosition =
      this.cursorPosition - (lookupSequence.length - startIndex)

    let startSymbol = lookupSequence[startIndex] as SearchType
    if (startSymbol === SearchType.ALT_CONTACT) {
      // Check "*" mention usage from settings (alias to "@")
      if (!teamsStore.getters.currentTeam.me.asteriskMention) return
      startSymbol = SearchType.CONTACT
    }

    const typeSymbol = this.currentType = startSymbol
    const textExtract = lookupSequence.substring(startIndex + 1)

    if (
      !typeSymbol ||
      (!this.options.ignoreWhitespaces && /\s/.test(textExtract))
    ) {
      this.emitData(null)
      return
    }

    if (typeSymbol === SearchType.COMMAND) {
      const isValid = this.isCommandTypingValid()
      if (!isValid) {
        this.emitData(null)
        return
      }
    }

    const isEmbedsInside = this.isEmbedsInside(
      this.typeCharPosition,
      this.cursorPosition - this.typeCharPosition,
    )
    if (isEmbedsInside) {
      this.emitData(null)
      return
    }

    this.normalizedSequence = Utils.prepareSequenceToFilter(textExtract)
    const entries = this.dataStore.getEntries(
      this.chatId,
      this.handleUpdatedEntries,
      { type: typeSymbol, sequence: this.normalizedSequence, needUpdate },
    )
    if (entries.length === 0) {
      this.emitData(null)
      return
    }
    this.emitData({ context: typeSymbol, entries })

    if (entries.length === 1 && !this.isRawTextFormattingType(typeSymbol)) {
      const entry = entries[0]
      const name = this.dataStore.getFilterSequence(entry)
      name === this.normalizedSequence && this.commitAutocomplete(textExtract)
    }
  }

  private handleUpdatedEntries = (pack: EntriesPack) => {
    const { chatId } = pack
    if (chatId !== this.chatId) return

    this.handleChange(false)
  }

  private isRawTextFormattingType = (searchType: SearchType): boolean =>
    this.options.textTypes.indexOf(searchType) >= 0

  private isCommandTypingValid = (): boolean => {
    if (!this.typeCharPosition) return true

    const length = this.typeCharPosition || 0
    const textBeforeCommand = this.quill.getText(0, length)
    if (textBeforeCommand.trim()) return false

    return !this.isEmbedsInside(0, length)
  }

  private isEmbedsInside = (index: number, length: number) => {
    const contents = this.quill.getContents(index, length)
    const { ops } = contents
    if (!ops) return false

    return ops.some(o => typeof o.insert !== 'string')
  }

  private pasteEntry = (options: PasteEntryOptions) => {
    // Try to commit autocomplete before paste entry
    const tryCommit = this.commitAutocomplete()
    if (tryCommit) return

    const { id, type, title } = options

    const position = CommonUtils.getCursorPosition(this.quill, true)
    if (position === null) return

    const ops: Array<DeltaOperation> = [{ retain: position }]

    let selectionIndex = position

    const prependSpaceOp = this.prependSpaceOperation(position - 1)
    if (prependSpaceOp) {
      selectionIndex += 1
      ops.push(prependSpaceOp)
    }

    const data: MentionBlotOptions = { id, sequence: title ?? '', type }

    const { operation, length } = this.appendAutocompleteEntryOperation(data)
    ops.push(operation)
    selectionIndex += length

    const appendSpaceOp = this.appendSpaceOperation(position)
    appendSpaceOp && ops.push(appendSpaceOp)
    selectionIndex += 1

    const isCaretAtEndBeforeUpdate = position >= this.quill.getLength() - 1
    CommonUtils.updateContents(this.quill, ops)
    this.quill.setSelection(selectionIndex, 0)

    isCaretAtEndBeforeUpdate && this.listenToRemoveSpaceTrail()
    this.emitData(null)
  }

  private commitAutocomplete = (textExtract?: string): boolean => {
    if (!this.currentEntries || !this.currentType) return false
    defaultLogger.log(
      '[AutocompleteModule.commitAutocomplete]',
      this.currentEntries[this.currentIndex],
    )

    const entry = this.currentEntries[this.currentIndex]
    if (!entry) return false

    this.cursorPosition = this.cursorPosition === null
      ? CommonUtils.getCursorPosition(this.quill)
      : this.cursorPosition
    if (!this.cursorPosition) return false

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    textExtract = textExtract || this.dataStore.getFilterSequence(entry, false)

    const ops: Array<DeltaOperation> = []

    // Deleting entered characters before inserting autocomplete
    if (this.typeCharPosition !== null && !isNaN(this.typeCharPosition)) {
      if (this.typeCharPosition === 0) {
        ops.push({ delete: this.cursorPosition })
      } else {
        ops.push({ retain: this.typeCharPosition })
        ops.push({ delete: this.cursorPosition - this.typeCharPosition })
      }
    }

    // Add a space before the insertion object if necessary.
    const previousCharIndex = this.typeCharPosition
      ? (this.typeCharPosition - 1)
      : (this.cursorPosition - 2)
    const op = this.prependSpaceOperation(previousCharIndex)
    op && ops.push(op)

    // Add the autocomplete object (MentionBlot/text)
    const data: MentionBlotOptions = {
      // TODO: refactor (id with type "string" cannot be "undefined")
      id: entry.key || entry.jid || entry.displayName,
      sequence: entry.title || entry.displayName,
      type: this.currentType,
    }

    const { operation } = this.appendAutocompleteEntryOperation(data)
    ops.push(operation)

    ops.push({ insert: ' ' })

    CommonUtils.updateContents(this.quill, ops)

    this.emitData(null)

    return true
  }

  private appendAutocompleteEntryOperation = (data: MentionBlotOptions): {
    operation: DeltaOperation
    length: number
  } => {
    const { type } = data

    if (this.isRawTextFormattingType(type)) {
      const insert = data.id
      return { operation: { insert }, length: insert.length }
    }

    return { operation: { insert: { [Formats.MENTION]: data } }, length: 1 }
  }

  private prependSpaceOperation = (
    previousCharIndex: number,
  ): DeltaOperation | null => {
    if (previousCharIndex < 0) return null

    const previousChar = this.quill.getText(previousCharIndex, 1)
    if (!previousChar) return null

    const isPreviousCharDelimiter =
      Utils.isWhitespace(previousChar) || Utils.isPunctuationMark(previousChar)
    if (isPreviousCharDelimiter) return null

    return { insert: ' ' }
  }

  private appendSpaceOperation = (index: number): DeltaOperation | null => {
    const nextChar = this.quill.getText(index, 1)
    const isNextCharDelimiter =
      Utils.isWhitespace(nextChar) || Utils.isPunctuationMark(nextChar)
    const isCaretAtEnd = index >= this.quill.getLength() - 1
    const concatWithSpace = !nextChar || !isNextCharDelimiter || isCaretAtEnd

    return concatWithSpace ? { insert: ' ' } : null
  }

  private listenToRemoveSpaceTrail = () => {
    const handler = (
      delta: DeltaStatic,
      oldDelta: DeltaStatic,
      source: Sources,
    ) => {
      if (source !== 'user') return

      const currentPosition = CommonUtils.getCursorPosition(this.quill)
      if (currentPosition === null) return

      const [leaf, offset] = this.quill.getLeaf(currentPosition)
      if (!leaf || offset !== 2) return

      const node = leaf.domNode as Node | null
      if (!node || node.nodeType !== Node.TEXT_NODE || !node.textContent) return

      const { prev } = leaf
      if (!prev || !(prev instanceof MentionBlot)) return

      const whitespaceChar = node.textContent[0]
      if (!whitespaceChar || !Utils.isWhitespace(whitespaceChar)) return

      const char = node.textContent[1]
      if (!char || !Utils.isPunctuationMark(char)) return

      const leafIndex = this.quill.getIndex(prev)
      if (leafIndex < 0) return

      CommonUtils.updateContents(this.quill, [
        { retain: leafIndex + 1 },
        { delete: 1 },
      ])
    }

    this.quill.once('text-change', handler)
    this.quill.once('selection-change', () => {
      this.quill.off('text-change', handler)
    })
  }

  private flush = () => {
    this.dataStore.flush()
    this.emitData(null)
  }

  private destroy = () => {
    this.flush()
    this.noticeButton.destroy()

    EventBus.$off(EventTypes.AUTOCOMPLETE_SELECTED_INDEX)
    EventBus.$off(EventTypes.AUTOCOMPLETE_COMMIT)
  }

  private getStartIndex (lookupSequence: string): number {
    const { startSymbols } = this.options
    const startIndex = startSymbols.reduce((previous, current) => {
      const previousIndex = previous
      const mentionIndex = lookupSequence.lastIndexOf(current)
      return mentionIndex > previousIndex ? mentionIndex : previousIndex
    }, -1)
    return startIndex
  }
}
