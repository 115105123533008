import {
  goalChatContactPopupOpenClick,
  goalChatContactPopupOpenMyClick,
} from '@/analytics'
import * as PopoutUtils from '@/components/UI/Popouts/Utils'
import i18n from '@/i18n'
import store from '@/store'
import DOMUtils, { isChildOf, measureHeight } from '@/utils/DOM'
import Vue from 'vue'
import UserInfoWindow from './UserInfoWindow.vue'

const USER_INFO_ID = 'user-info'

let windowInstance: Vue | null = null

export const flushWindowInstance = (windowElement: HTMLElement | null) => {
  windowElement = windowElement || document.getElementById(USER_INFO_ID)

  if (windowInstance) {
    windowInstance.$destroy()
    windowInstance.$el.remove()
    windowInstance = null
  }

  window.removeEventListener('click', windowClickHandler)

  if (windowElement) {
    windowElement.style.display = 'none'
    windowElement.style.visibility = 'hidden'
    const { parentElement: windowParentElement } = windowElement
    windowParentElement && windowParentElement.setAttribute('data-open', 'false')

    DOMUtils.removeElement(windowElement)
  }
}

const createComponentInstance = ({ element, windowElement, jid }: { element: HTMLElement; windowElement: HTMLElement; jid: string }) => {
  flushWindowInstance(windowElement)

  element.style.position = 'relative'
  element.appendChild(windowElement)

  const placeholderElement = DOMUtils.createElement('div', { style: { position: 'absolute' } })
  windowElement.appendChild(placeholderElement)

  windowInstance = new Vue(Object.assign({ store, i18n }, UserInfoWindow, { propsData: { jid, close: flushWindowInstance } }) as any)
  windowInstance.$mount(placeholderElement)
}

const windowClickHandler = function (event: MouseEvent) {
  const { target } = event

  const windowElement = document.getElementById(USER_INFO_ID)
  if (!windowElement) {
    window.removeEventListener('click', windowClickHandler)
    return
  }

  const isChild = target instanceof HTMLElement && isChildOf(target, windowElement)
  if (isChild) return

  flushWindowInstance(windowElement)
}

const createUserInfoWindow = (width: number): HTMLElement => {
  const element = DOMUtils.createElement('div', {
    class: 'o-popout',
    id: USER_INFO_ID,
    style: {
      width: width + 'px',
    },
  })

  element.addEventListener('click', e => {
    e.preventDefault()
    e.stopPropagation()
  })

  element.style.display = 'none'
  element.style.visibility = 'hidden'

  return element
}

const checkHorPlacement = (windowElement: HTMLElement, boundAncestor: HTMLElement | null) => {
  const boundOffset = PopoutUtils.getOffsetFromAncestorBounds(windowElement, boundAncestor)
  if (!boundOffset) return

  const { left: leftOffset, right: rightOffset } = boundOffset
  if (rightOffset >= 0) return

  const marginFromBounds = 10
  const offset = Math.abs(rightOffset) + marginFromBounds
  if (offset > leftOffset) return

  windowElement.style.left = `${-offset}px`
}

export const showUserInfoPopup = (jid: string, element: HTMLElement) => {
  const boundAncestor = DOMUtils.searchAncestorByOverflow(element) as HTMLElement | null
  const boundOffset = PopoutUtils.getOffsetFromAncestorBounds(element, boundAncestor)
  if (!boundOffset) return

  const openAttribute = element.getAttribute('data-open')
  const marginFromAnchor = 0
  const isOpen = openAttribute === 'true'

  let windowHeight = 0
  const windowWidth = 270
  let windowElement = document.getElementById(USER_INFO_ID)

  if (isOpen) {
    flushWindowInstance(windowElement)
    return
  }

  store.getters.profile.jid === jid
    ? goalChatContactPopupOpenMyClick()
    : goalChatContactPopupOpenClick()

  windowElement = windowElement || createUserInfoWindow(windowWidth)
  createComponentInstance({ element, windowElement, jid })

  windowHeight = measureHeight(windowElement)

  const { bottom: offsetBottom } = boundOffset
  const dropDown = offsetBottom > windowHeight

  windowElement.style.left = '0';
  (windowElement.style as any)[dropDown ? 'top' : 'bottom'] = `calc(100% + ${marginFromAnchor}px)`;
  (windowElement.style as any)[dropDown ? 'bottom' : 'top'] = 'auto'

  windowElement.style.display = 'block'
  checkHorPlacement(windowElement, boundAncestor)
  windowElement.style.visibility = 'visible'
  element.setAttribute('data-open', 'true')

  window.addEventListener('click', windowClickHandler)
}
