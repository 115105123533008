import { goalChatOneMessageActions } from '@/analytics'
import {
  createReactionsPopoutContent,
  getReactionDescription,
  hasMyReaction,
  onTapeClickHandler,
} from '@/components/Chat/Instance/DOM/Components/Helpers/Reactions'
import {
  REACTION_POPOUT_ID,
} from '@/components/Chat/Instance/DOM/Components/Tools/Models'
import parser from '@/components/Chat/ReplyArea/Emoji/Parser'
import {
  ATTRIBUTE_ANCHOR_POPOUT_ID,
  attachLazyPopout,
} from '@/components/UI/Popouts/Popout'
import {
  attachTooltip,
  attachTooltipScope,
  detachTooltip,
  setDescription,
} from '@/components/UI/Popouts/TooltipController'
import i18n from '@/i18n'
import DOMUtils, { insertAfter, toClassList } from '@/utils/DOM'
import { MessageReaction } from '@tada-team/tdproto-ts'

const MAX_TOOLTIP_WIDTH = 200

const REACTIONS_TAPE_CLASS_NAME = 'reactions'
export const REACTION_CLASS_NAME = 'reaction'
export const REACTION_ME_CLASS_NAME = 'reaction-me'
const ADD_REACTION_CLASS_NAME = 'add-reaction'

export const ATTRIBUTE_REACTION_NAME = 'data-reaction-name'
const ATTRIBUTE_REACTION_COUNT = 'data-reaction-count'

const updateTooltipDescription = async (
  reactionElement: HTMLElement,
  reaction: MessageReaction,
) => {
  const description = await getReactionDescription(reaction)
  setDescription(reactionElement, description)
}

const updateReactionElement = (
  reactionElement: HTMLElement,
  reaction: MessageReaction,
) => {
  const { counter, name } = reaction

  const currentName = reactionElement.getAttribute(ATTRIBUTE_REACTION_NAME)
  if (currentName !== name) throw new Error(`[Reactions.updateReactionElement] Names not matches: ${currentName} != ${name}`)

  const hasMy = hasMyReaction(reaction)
  const hasMyReactionAlready = reactionElement.classList.contains(REACTION_ME_CLASS_NAME)

  if (hasMy !== hasMyReactionAlready) {
    hasMyReactionAlready
      ? reactionElement.classList.remove(REACTION_ME_CLASS_NAME)
      : reactionElement.classList.add(REACTION_ME_CLASS_NAME)
  }

  const currentCounter = +(reactionElement.getAttribute(ATTRIBUTE_REACTION_COUNT) || '')
  if (currentCounter !== counter) {
    reactionElement.setAttribute(ATTRIBUTE_REACTION_COUNT, counter + '')
  }

  updateTooltipDescription(reactionElement, reaction)
}

const createReactionElement = (reaction: MessageReaction): HTMLElement => {
  const { name, counter } = reaction
  const source = parser.toSource(name)

  const element = DOMUtils.createElement('div', {
    class: toClassList({
      [REACTION_CLASS_NAME]: true,
      [REACTION_ME_CLASS_NAME]: hasMyReaction(reaction),
    }),
    style: { 'background-image': `url("${source}")` },
    [ATTRIBUTE_REACTION_NAME]: name,
    [ATTRIBUTE_REACTION_COUNT]: counter,
  })

  updateTooltipDescription(element, reaction)

  return element
}

const updateTapeElement = (
  tapeElement: HTMLElement,
  reactions: MessageReaction[],
) => {
  const { children } = tapeElement

  const currentReactionElements: { [name: string]: HTMLElement | null } = {}
  for (let i = 0; i < children.length; i++) {
    const reactionElement = children[i]
    const name = reactionElement.getAttribute(ATTRIBUTE_REACTION_NAME)
    if (!name) continue

    currentReactionElements[name] = reactionElement as HTMLElement
  }

  let previousReactionElement: HTMLElement | null = null
  reactions.forEach(messageReaction => {
    const { name } = messageReaction

    let reactionElement = currentReactionElements[name]
    if (reactionElement) {
      updateReactionElement(reactionElement, messageReaction)
    } else {
      reactionElement = createReactionElement(messageReaction)
      attachTooltip({ element: reactionElement, cacheable: false, maxWidth: MAX_TOOLTIP_WIDTH })

      previousReactionElement
        ? insertAfter(reactionElement, previousReactionElement)
        : tapeElement.appendChild(reactionElement)
    }

    previousReactionElement = reactionElement
    delete currentReactionElements[name]
  })

  Object.keys(currentReactionElements).forEach(name => {
    const reactionElement = currentReactionElements[name]
    if (!reactionElement) return

    detachTooltip(reactionElement)
    DOMUtils.removeElement(reactionElement)

    currentReactionElements[name] = null
  })
}

const onPopoutShow = (anchor: HTMLElement) => {
  anchor.classList.add('force')
}

const onPopoutHide = (anchor: HTMLElement) => {
  anchor.classList.remove('force')
}

const createAddReactionElement = (): HTMLElement => {
  const element = DOMUtils.createElement('div', {
    class: ADD_REACTION_CLASS_NAME,
  })
  element.setAttribute(ATTRIBUTE_ANCHOR_POPOUT_ID, REACTION_POPOUT_ID)
  setDescription(element, i18n.t('chattape.reactionAdd').toString())

  element.addEventListener('click', () => {
    goalChatOneMessageActions.showMoreReactionsClick()
  })

  attachLazyPopout({
    anchor: element,
    content: createReactionsPopoutContent,
    behaviour: {
      onShow: onPopoutShow,
      onHide: onPopoutHide,
      local: true,
    },
  })

  return element
}

const createTapeElement = (reactions: MessageReaction[]): HTMLElement => {
  const fragment = document.createDocumentFragment()

  reactions.forEach(messageReaction => {
    const reactionElement = createReactionElement(messageReaction)
    fragment.appendChild(reactionElement)
  })

  const addReactionElement = createAddReactionElement()

  const element = DOMUtils.createElement('div', {
    class: REACTIONS_TAPE_CLASS_NAME,
  }, fragment, addReactionElement)

  element.addEventListener('click', onTapeClickHandler)

  attachTooltipScope({ element, maxWidth: MAX_TOOLTIP_WIDTH, dynamicContent: true })

  return element
}

const findTape = (element: HTMLElement): HTMLElement | null => {
  const { children } = element
  for (let i = children.length - 1; i >= 0; i--) {
    const child = children[i] as HTMLElement
    if (child.className === REACTIONS_TAPE_CLASS_NAME) return child
  }
  return null
}

export const updateMessageReactions = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  const { reactions } = model
  const isNoReactions = !reactions || reactions.length === 0

  const currentTapeElement = findTape(element)
  if (isNoReactions) {
    currentTapeElement && DOMUtils.removeElement(currentTapeElement)
    return
  }

  if (currentTapeElement) {
    updateTapeElement(currentTapeElement, reactions)
    return
  }

  const tapeElement = createTapeElement(reactions)
  element.appendChild(tapeElement)
}
