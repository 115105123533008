import store from '@/store'

const NOT_RECEIVED_CLASS_NAME = 'not-received'

export const updateReceivedState = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  const { getters } = store
  const { sender, received } = model
  if (sender !== getters.getUserId) return

  if (!received) {
    element.classList.add(NOT_RECEIVED_CLASS_NAME)
    return
  }

  element.classList.remove(NOT_RECEIVED_CLASS_NAME)
}
