import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import ChatEvents from '@/components/Chat/Instance/ChatEvents'
import ChatReader from '@/components/Chat/Instance/ChatReader'
import ChatTape from '@/components/Chat/Instance/ChatTape'
import ScrollController from '@/components/Chat/Instance/Controllers/ScrollController'
import UnreadBarController from '@/components/Chat/Instance/Controllers/UnreadBarController'
import InactiveDetector from '@/electron/InactiveDetector'
import { defaultLogger } from '@/loggers'
import store from '@/store'
import Messages, { OrderedMessages } from '@/store/messages'
import * as Consts from '@/store/messages/consts'
import { CHAT_SET_STARTING_MESSAGE_ID } from '@/store/modules/chat/mutationTypes'
import { getChatType } from '@/utils'
import DOMUtils from '@/utils/DOM'

export interface MessageObject {
  model: TADA.Message;
  element: HTMLElement;
}

export default class {
  chatId: string

  messages: { [messageId: string]: MessageObject | null }
  unreadController: UnreadBarController
  scrollController: ScrollController
  isInactive: boolean
  isInHistoryMode: boolean
  tape: ChatTape
  reader: ChatReader

  private count: number

  private isReady: boolean

  private events: ChatEvents

  constructor (root: HTMLElement) {
    this.chatId = ''
    this.isInactive = false
    this.isInHistoryMode = false
    this.isReady = false
    this.count = 0
    this.messages = {}

    this.tape = new ChatTape(root)
    this.reader = new ChatReader()

    this.unreadController = new UnreadBarController(this)
    this.scrollController = new ScrollController(this, root)

    this.events = new ChatEvents(this)
  }

  flush () {
    this.isInHistoryMode = this.isReady = this.isInactive = false

    for (const messageId in this.messages) {
      if (!Object.prototype.hasOwnProperty.call(this.messages, messageId)) continue

      const object = this.messages[messageId]
      if (!object) continue

      const { element } = object
      DOMUtils.removeElement(element)

      delete this.messages[messageId]
    }

    this.count = 0
    this.messages = {}
    this.unreadController.hideBar()
    this.tape.flush()
    this.reader.flush()
  }

  destroy () {
    this.flush()

    this.unreadController.destroy()
    this.events.destroy()
  }

  async init (chatId?: string) {
    this.chatId = chatId || this.chatId
    this.flush()

    this.tape.reset()
    this.setupUndeliveredMessages()

    const { commit } = store

    const initFrom = this.getChatInitialMessageId()
    await this.setupMessages(initFrom)

    ChatEventBus.$emit(Events.UPDATE_CHAT_SCROLLBAR)

    this.unreadController.placeBar(this.chatId, null)
    this.scrollController.scrollToMessage(initFrom)

    commit(CHAT_SET_STARTING_MESSAGE_ID, { chatId, messageId: null })

    ChatEventBus.$emit(Events.CHAT_READY, { instance: this })
    this.isReady = true

    this.toggleHistoryMode()
    this.readChatToLastMessage(null, true)
  }

  getChatInitialMessageId (): string | null {
    const { getters } = store
    const messageId = getters.chatStartingMessageId(this.chatId) || getters.chatLastReadMessageId(this.chatId)
    if (messageId) return messageId

    const lastMessage = getters.chatLastMessage(this.chatId)
    return lastMessage ? lastMessage.messageId : null
  }

  async setupMessages (from: string | null): Promise<boolean> {
    try {
      if (!from) {
        const chatType = getChatType(this.chatId)
        await Messages.loadLastMessages(this.chatId, chatType === 'task')
      }

      const { getters } = store
      const lastMessage = getters.chatLastMessage(this.chatId)
      if (!lastMessage) return false

      await Messages.loadMessagesAround({
        chatId: this.chatId,
        messageId: from || lastMessage.messageId,
        callback: (messages: OrderedMessages) => {
          this.handleIncomingMessages(messages)
        },
        isLast: lastMessage.isLast,
      })
    } catch (e) {
      defaultLogger.warn('[ChatInstance.setupMessages]', e)

      return false
    }
    return true
  }

  async fetchMessages (older?: boolean): Promise<OrderedMessages | null> {
    const edgeMessageId = this.tape.getEdgeMessageId(older)
    if (!edgeMessageId) return null

    defaultLogger.debug('fetchMessages -', older ? 'older' : 'newer', edgeMessageId)

    const message = this.messages[edgeMessageId]
    if (!message) throw new Error(`[ChatInstance.fetchMessages] Message [${edgeMessageId}] is in DOM, but model not exists`)

    let needToLoad = false
    if (older) {
      const { model } = message
      needToLoad = !model.isFirst
    } else {
      const { getters } = store
      const lastMessage = getters.chatLastMessage(this.chatId)
      needToLoad = lastMessage && edgeMessageId !== lastMessage.messageId
    }

    if (!needToLoad) return null

    const messages = Messages.getMessages(this.chatId, edgeMessageId, older)
    if (messages) return messages

    return older
      ? Messages.loadOlderMessages(this.chatId, edgeMessageId, true, false)
      : Messages.loadNewerMessages(this.chatId, edgeMessageId, true, false)
  }

  cropChatIfNeeded (fromBottom?: boolean) {
    const availableCount = Consts.MAX_DISPLAY_MESSAGES - this.count
    if (availableCount >= 0) return

    const cropCount = -availableCount
    for (let i = 0; i < cropCount; i++) {
      const edgeMessageId = this.tape.getEdgeMessageId(!fromBottom)
      if (!edgeMessageId) continue

      this.remove(edgeMessageId)
    }
  }

  handleIncomingMessage (message: TADA.Message, inverse?: boolean) {
    this.messages[message.messageId] ? this.update(message) : this.add(message, inverse)
  }

  handleIncomingMessages ({ orderIds, data }: OrderedMessages, inverse?: boolean): boolean {
    const countBeforeHandler = this.count
    orderIds.forEach(messageId => {
      const message = data[messageId]
      message && this.handleIncomingMessage(message, inverse)
    })
    return countBeforeHandler < this.count
  }

  readChatToLastMessage (orderedMessages?: OrderedMessages | null, immediate?: boolean) {
    if (!this.isReady || this.isInactive) return
    if (!InactiveDetector.isActive) {
      this.unreadController.placeBar(this.chatId, null)
      return
    }

    if (!orderedMessages) {
      const lastMessageIdFromDOM = this.isInHistoryMode ? this.tape.getEdgeMessageId() : null
      this.reader.readChat(this.chatId, lastMessageIdFromDOM, immediate)
      return
    }

    const { orderIds } = orderedMessages
    const lastOrderId = orderIds[orderIds.length - 1]
    this.reader.readChat(this.chatId, lastOrderId, immediate)
  }

  remove (messageId: string): boolean {
    const object = this.messages[messageId]
    if (!object) return false

    const { element, model } = object
    this.tape.removeMessage(element)

    delete this.messages[messageId]

    model.state === TADA.MessageState.NORMAL && (this.count -= 1)

    return true
  }

  toggleHistoryMode () {
    const lastMessageIdFromDOM = this.tape.getEdgeMessageId(
      false,
      false,
      false,
      false,
    )
    lastMessageIdFromDOM && this.reader.toggleHistoryModeIfNeeded(this.chatId, lastMessageIdFromDOM)
  }

  private add (message: TADA.Message, inverse?: boolean) {
    const element = this.tape.addMessage(message, inverse)

    const { messageId, state } = message

    const object = { model: message, element }
    this.messages[messageId] = object

    if (state === TADA.MessageState.NORMAL) {
      this.count += 1
      this.isInactive && this.unreadController.placeBar(this.chatId, object)
    }
  }

  private update (message: TADA.Message) {
    const { messageId } = message

    const object = this.messages[message.messageId]
    if (!object) return

    const { element } = object
    this.tape.updateMessage(message, element)

    Object.assign(this.messages[messageId], {
      model: message,
    })
  }

  private setupUndeliveredMessages () {
    const messages = Messages.getChatMessages(this.chatId)
    const { undeliveredOrderIds, undeliveredData } = messages
    undeliveredOrderIds.forEach(messageId => {
      const message = undeliveredData[messageId]
      message && this.handleIncomingMessage(message)
    })
  }
}
