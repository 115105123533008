//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResizeSensor from 'vue-resize-sensor'

import * as actionTypes from '@/store/actionTypes'
import Messages from '@/store/messages'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import ScrollableArea from '@/components/UI/ScrollableArea'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'

import ChatInstance from '../ChatInstance'

import HookUtilsMixin from './Mixins/HookUtilsMixin'
import AppearanceMixin from './Mixins/AppearanceMixin'
import ThresholdObserverMixin from './Mixins/ThresholdObserverMixin'
import DateHeadingMixin from './Mixins/DateHeadingMixin'
import MediaContentResizeMixin from './Mixins/MediaContentResizeMixin'

import { setMaxMessageContentWidth } from './Utils'
import { uiStore } from '@/store'

export default {
  name: 'ChatHook',
  components: {
    'scrollable-area': ScrollableArea,
    BaseSpinner,
    'resize-sensor': ResizeSensor,
  },
  mixins: [HookUtilsMixin, AppearanceMixin, DateHeadingMixin, ThresholdObserverMixin, MediaContentResizeMixin],
  computed: {
    chatId () {
      const { getters } = this.$store
      return getters.currentChat
    },
  },
  watch: {
    chatId () {
      this.init()
    },
  },
  created () {
    this.handlers = {
      [Events.REINIT_CHAT]: this.reinit,
      [Events.SCROLL_CHAT]: params => {
        if (!params) return this.scrollDown()
        const { offset, threshold, action } = params

        if (!offset) {
          return action
            ? this.scrollDownIfNeeded({ action, threshold })
            : this.scrollDown(threshold)
        }
        this.scrollTo(offset)
      },
      [Events.UPDATE_CHAT_SCROLLBAR]: () => {
        this.updateScrollBar()
      },
      [Events.CHAT_READY]: () => {
        this.DHMHandler && this.DHMHandler()
      },
      [Events.READ_MESSAGES_IN_ACTIVE_CHAT]: () => {
        this.readMessages()
      },
    }
  },
  mounted () {
    Object.keys(this.handlers).forEach(eventName => {
      ChatEventBus.$on(eventName, this.handlers[eventName])
    })

    this.init()
  },
  beforeDestroy () {
    Object.keys(this.handlers).forEach(eventName => {
      ChatEventBus.$off(eventName, this.handlers[eventName])
    })

    this.instance && this.instance.destroy()

    const { dispatch } = this.$store
    dispatch(actionTypes.SET_CURRENT_ACTIVE_MESSAGE_DATA, null)
  },
  methods: {
    init () {
      if (!this.instance) {
        const { container } = this.$refs
        if (!container) return

        this.tapeWidth = container.scrollWidth

        setMaxMessageContentWidth(this.tapeWidth)
        this.instance = new ChatInstance(container)
      }

      this.flushAppearanceMixin()
      this.flushThresholdObserverMixin()

      this.instance.init(this.chatId)
    },
    reinit () {
      uiStore.actions.clearSelectedMessages()

      Messages.clearChatStore(this.chatId)
      this.init()
    },
    moveBottom () {
      // this.reinit()
      const payload = {
        chatId: this.chatId,
        messageId: Messages.getLastMessage(this.chatId).messageId,
      }

      if (!this.instance.messages[payload.messageId]) {
        this.$store.dispatch(actionTypes.OPEN_CHAT, payload)
        uiStore.actions.openChat()
        ChatEventBus.$emit(Events.REINIT_CHAT)
      }

      this.instance.scrollController.scrollToMessage(payload.messageId)
    },
    readMessages () {
      this.instance.readChatToLastMessage(null, true)
    },
  },
}
