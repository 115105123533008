//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Events, TaskEventBus } from '@/components/Tasks/EventBus'
import { throttle } from 'quasar'

const noop = () => false

const cursor = 'ns-resize'

const applyEvents = (it, events, target) => Object.entries(events).forEach(([k, v]) => target.addEventListener(k, it[v]))
const detachEvents = (it, events, target) => Object.entries(events).forEach(([k, v]) => target.removeEventListener(k, it[v]))

const windowEvents = {
  mouseup: 'dragStop',
  touchend: 'dragStop',
  touchcancel: 'dragStop',
  mousemove: 'dragMove',
  touchmove: 'dragMove',
}

function disableSelection (el) {
  el.addEventListener('selectstart', noop)
  el.addEventListener('dragstart', noop)

  el.style.userSelect = 'none'
  el.style.webkitUserSelect = 'none'
  el.style.MozUserSelect = 'none'
  el.style.pointerEvents = 'none'
}

function enableSelection (el) {
  el.removeEventListener('selectstart', noop)
  el.removeEventListener('dragstart', noop)

  el.style.userSelect = ''
  el.style.webkitUserSelect = ''
  el.style.MozUserSelect = ''
  el.style.pointerEvents = ''
}

const dragStart = function (event) {
  this.dragging = true
  const { view, top, split, bottom } = this.$refs

  event.preventDefault()

  applyEvents(this, windowEvents, window)

  disableSelection(top)
  disableSelection(bottom)

  view.style.cursor = cursor
  split.style.cursor = cursor
  document.body.style.cursor = cursor

  this.calculateSizes()
}

const dragMove = throttle(function (e) {
  this.noClick = true
  let offset
  // let {top} = this.$refs

  if ('touches' in e) {
    offset = e.touches[0].clientY - this.start
  } else {
    offset = e.clientY - this.start
  }

  this.setTopSize(offset, false)
}, 0)

const dragStop = function () {
  if (!this.noClick) {
    this.onSplitClick()
  }
  this.noClick = false
  this.dragging = false
  const { view, top, split, bottom } = this.$refs

  detachEvents(this, windowEvents, window)

  enableSelection(top)
  enableSelection(bottom)

  view.style.cursor = ''
  split.style.cursor = ''
  document.body.style.cursor = ''

  this.setTopSize(this.topSize, true)
}

export default {
  props: ['maxTopSize'],
  data () {
    return {
      start: 0,
      size: 0,
      snapOffset: 40,
      bottomSnapOffset: 0,
      topMinSize: 76,
      bottomMinSize: 150,
      aGutterSize: 20,

      topSize: 200,
      dragging: false,
      noClick: false,
    }
  },
  computed: {
    isTopCollapsed () {
      return this.topSize <= this.topMinSize + this.snapOffset / 20 + 1
    },
  },
  watch: {
    isTopCollapsed (v) {
      TaskEventBus.$emit(Events.TOP_SPLIT_COLLAPSE_STATE, v)
    },
  },
  created () {
    this.dragStop = dragStop.bind(this)
    this.dragMove = dragMove.bind(this)
  },
  methods: {
    dragStart,
    calculateSizes () {
      // Figure out the parent size minus padding.
      const { top, bottom } = this.$refs

      const aBounds = top.getBoundingClientRect()
      const bBounds = bottom.getBoundingClientRect()

      this.size = aBounds.height + bBounds.height + this.aGutterSize
      this.start = aBounds.top
    },
    setTopSize (offset, fixed = true, onlyIfCollapsed = false) {
      if (onlyIfCollapsed && !this.topCollapsed) {
        return
      }

      offset = offset === null ? this.topSize : offset

      if (offset <= this.topMinSize + this.snapOffset + this.aGutterSize) {
        offset = this.topMinSize + (fixed ? 0 : ((offset - this.topMinSize - this.aGutterSize) / 20))
      } else if (offset >= this.size - (this.bottomMinSize + this.bottomSnapOffset + this.aGutterSize)) {
        offset = this.size - (this.bottomMinSize + this.aGutterSize) + (fixed ? 0 : ((offset - this.size + this.bottomMinSize + this.aGutterSize) / 20))
      }

      if (offset > this.maxTopSize) {
        offset = this.maxTopSize + (fixed ? 0 : ((offset - this.maxTopSize) / 20))
      }

      this.topSize = offset
    },
    onSplitClick () {
      if (this.noClick) return
      this.calculateSizes()
      const maxSize = Math.min(this.maxTopSize, this.size - (this.bottomMinSize + this.aGutterSize))

      if (this.topSize - this.topMinSize > maxSize - this.topSize) {
        this.setTopSize(0)
        window.goal('taskControls', { taskControls: 'Свернуть шторку задачи' })
      } else {
        this.setTopSize(100000)
        window.goal('taskControls', { taskControls: 'Равернуть шторку задачи' })
      }
    },
  },
}
