import DataGenerator from '@/api/v3/DataGenerator'
import VueI18n from 'vue-i18n'
import store, {
  contactsStore,
  uiStore,
} from '@/store'
import Messages from '@/store/messages'

import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { createAttachmentContainer } from '@/components/Chat/Instance/DOM/Components/Attachments'
import DOMUtils, { searchAncestorByAttribute } from '@/utils/DOM'

interface MessageRelatedDialog {
  message: TADA.Message;
  dialogText: VueI18n.TranslateResult
  buttonText: VueI18n.TranslateResult
  title: VueI18n.TranslateResult
  callback?: (chatId: string, messageId: string) => void
}

export const getMessageElement = (e: Event): HTMLElement | null => {
  return searchAncestorByAttribute(e.target as Node, MessageDOMAttribute.ID) as HTMLElement | null
}

export const getActiveMessageId = (target: HTMLElement): string | null => {
  const messageElement = searchAncestorByAttribute(target, MessageDOMAttribute.ID) as HTMLElement
  if (!messageElement) return null

  const messageId = messageElement.getAttribute(MessageDOMAttribute.ID)
  return messageId
}

export const getActiveMessageSource = (target: HTMLElement): string | null => {
  const messageElement = searchAncestorByAttribute(target, MessageDOMAttribute.ID) as HTMLElement
  if (!messageElement) return null

  const source = messageElement.getAttribute(MessageDOMAttribute.SOURCE)
  if (source) return source

  const { getters } = store
  return getters.currentChat
}

export const getMessageText = (chatId: string, messageId: string): string => {
  const message = Messages.getMessage(chatId, messageId)
  if (!message || !message.content) return ''

  const { sender, created, content } = message

  const { displayName } = contactsStore.getters.contact(sender) || DataGenerator.generateDummyContact()
  const date = new Date(created).toLocaleString()
  return `${displayName} [${date}]:\n${content.text || 'Empty message'}`
}

export const showMessageRelatedDialog = ({ message, dialogText, title, buttonText, callback }: MessageRelatedDialog) => {
  if (!callback) return

  const { chatId, messageId, content, linkedMessages } = message

  callback = callback.bind(null, chatId, messageId)

  const factory = (context: HTMLElement) => {
    const attachmentsElement = createAttachmentContainer(content.text ? [message] : linkedMessages, context)
    return DOMUtils.createElement('div', {
      style: { margin: '10px' },
    }, attachmentsElement)
  }

  uiStore.actions.showModal({
    instance: 'universal-yes-no',
    payload: {
      title,
      text: dialogText,
      html: factory,
      yesText: buttonText,
      yes: callback,
    },
  })
}
