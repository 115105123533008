import Quill from 'quill'

import store from '@/store'
import { getChatType, replaceAll, transformEntityName } from '@/utils'
import { SearchType } from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule'

export const TRIGGER_SYMBOLS = ['@', '#', '.']
const Embed = Quill.import('blots/embed')

/**
 * Interface with mention data
 * @property id - example - '[Artem Shuvaev](tadateam://jid)'
 * @property type - example - '@'
 * @property sequence - example - 'Artem Shuvaev'
 */
export interface MentionBlotOptions {
  id: string
  type: SearchType
  sequence: string
}

class MentionBlot extends Embed {
  /**
   * Regex for finding TADATEAM links
   */
  static get regexMdLinks (): RegExp {
    const schemas: Array<string> = window.FEATURES?.app_schemes ?? ['tadateam']
    const schemasRegexStr = schemas.join('|')

    // [label](tadateam://link)
    const regexMdLinks = new RegExp(
      `\\[(.*?)](\\((${schemasRegexStr}):\\/\\/\\S+\\))`,
      'gm',
    )

    return regexMdLinks
  }

  static create (data: MentionBlotOptions) {
    const node = super.create() as Element

    const { id, type, sequence } = data

    node.setAttribute('data-id', id)
    node.setAttribute('data-type', type)
    node.setAttribute('data-sequence', sequence)
    node.setAttribute('spellcheck', 'false')

    node.classList.add('o-bg-color')

    let displayName = sequence

    if (type === SearchType.GROUP) {
      const { getters } = store
      const entity = getters.entity(id)

      if (entity && getChatType(entity.jid) === 'task') {
        displayName = entity.displayName.substr(1)
      }
    }

    node.innerHTML = transformEntityName(type + displayName)

    return node
  }

  static value (node: Element) {
    return {
      id: node.getAttribute('data-id'),
      type: node.getAttribute('data-type'),
      sequence: node.getAttribute('data-sequence'),
    }
  }

  /**
   * Uses for Quill format text for message editing
   * @see Utils.ts|insertAndFormat()
   * @param text raw text from editble area
   * @returns text with mentions as HTML after MD parsing
   */
  static textProcessor (text: string) {
    const matches = [...new Set(text.match(MentionBlot.regexMdLinks))]

    if (!matches) return text

    for (let i = 0; i < matches.length; i++) {
      const link = MentionBlot.regexMdLinks.exec(matches[i])
      if (!link || !TRIGGER_SYMBOLS.includes(link[1].charAt(0))) return

      const blot: MentionBlotOptions = {
        id: matches[i],
        type: link[1].charAt(0) as SearchType,
        sequence: link[1].substring(1),
      }

      text = replaceAll(text, link[0], MentionBlot.create(blot).outerHTML)
    }

    return text
  }

  /**
   * Method for getting display text of mentionblot
   * @param {MentionBlotOptions} value options of Blot
   * @returns string for UI displaying
   */
  static toRawText (value: MentionBlotOptions): string {
    // if we don't need to paste type
    if (value.id.charAt(0) !== '[' || TRIGGER_SYMBOLS.includes(value.id[1])) { return value.id }
    return '[' + value.type + value.id.substr(1, value.id.length)
  }
}

MentionBlot.blotName = 'mention'
MentionBlot.tagName = 'span'
MentionBlot.className = 'mention'

export default MentionBlot
