import { getChatType } from '@/utils'
import store from '@/store'
import AutocompleteModule, { SearchType } from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule'
import { EntriesPack, AutocompleteEntry } from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/AutocompleteDataStore'

export default class {
  constructor (public autocompleteModule: AutocompleteModule) {
    const button = document.getElementById('notice-button')
    if (!button) return

    button.addEventListener('click', this.toggleList)
    button.addEventListener('mousedown', this.handleMouseDown)
  }

  public destroy = () => {
    const button = document.getElementById('notice-button')
    if (!button) return

    button.removeEventListener('click', this.toggleList)
    button.removeEventListener('mousedown', this.handleMouseDown)
  }

  private handleUpdatedEntries = (pack: EntriesPack) => {
    const { entries } = pack
    this.emit(entries)
  }

  private emit = (entries: AutocompleteEntry[]) => {
    if (!entries || entries.length === 0) return

    this.autocompleteModule.emitData({
      context: SearchType.CONTACT,
      entries: entries,
    })
  }

  private handleMouseDown = (event: MouseEvent) => {
    event.preventDefault()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private toggleList = (event: MouseEvent) => {
    const { getters } = store
    const chatId = getters.currentChat
    const chatType = getChatType(chatId)
    if (chatType === 'direct') return

    const { dataStore, isOpen } = this.autocompleteModule
    if (isOpen) {
      this.autocompleteModule.emitData(null)
      return
    }

    const entries = dataStore.getEntries(chatId, this.handleUpdatedEntries, {
      type: SearchType.CONTACT,
      sequence: '',
      needUpdate: true,
    })
    this.emit(entries)

    // window.setTimeout(() => EventBus.$emit(EventTypes.FOCUS), 100)
  }
}
