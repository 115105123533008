import { extendLinks } from './Links'
import { collapseLongTextBlock, messageToHTML } from './Utils'

export const createPlainContent = (contentElement: HTMLElement, sender: string, message: TADA.Message) => {
  const messageHtml = messageToHTML(message)
  contentElement.innerHTML = `<span>${messageHtml}</span>`

  const isFormatted = message.content.text.length !== messageHtml.length
  isFormatted && collapseLongTextBlock(contentElement)

  extendLinks(contentElement, message.links, sender)
}
