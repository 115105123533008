//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import ChatForm from '@/components/Chat/ChatForm'
import Messages from '@/components/Chat/Instance/Hooks/MainHook'
import ImportantMessagesList from '@/components/Chat/Instance/Hooks/ImportantHook'
import SelectedMessagesBar from '@/components/HeaderBar/ChatControls/SelectedMessagesBar'
import FileDropMixin from '@/components/MiddleColumn/Mixins/FileDrop'
import { tasksStore, uiStore } from '@/store'

export default {
  components: {
    Messages,
    ChatForm,
    SelectedMessagesBar,
    ImportantMessagesList,
  },
  props: ['importantViewerActive', 'chatId'],
  mixins: [FileDropMixin],
  computed: {
    ...mapGetters([
      'currentChat',
    ]),
    currentChatId () {
      return this.chatId ?? this.currentChat
    },
    selectedMessagesIds () {
      return uiStore.getters.selectedMessagesIds
    },
    task () {
      return tasksStore.state.data[this.currentChat]
    },
    canSendMessage () {
      if (this.chatId) return true
      return this.task && this.task.canSendMessage
    },
  },
}
