import i18n from '@/i18n'

import { calendar } from '@/utils/DataUtils'
import DOMUtils from '@/utils/DOM'
import { setDescription, attachTooltip } from '@/components/UI/Popouts/TooltipController'

import { CONTENT_CLASS_NAME, ATTRIBUTE_CONTENT_TYPE } from '@/components/Chat/Instance/DOM/Components/Content'

const EDITED_CLASS_NAME = 'edited'

const createDescription = (model: TADA.Message): string => {
  const edited = new Date(model.edited || '')
  return calendar(edited)
}

const createEditedMarkElement = (model: TADA.Message): HTMLElement => {
  const element = DOMUtils.createElement('span', {
    class: EDITED_CLASS_NAME + ' o-interline o-no-select',
  }, ' ' + i18n.t('chattape.messageIsEdited').toString())

  const description = createDescription(model)
  setDescription(element, description)

  return element
}

export const updateEditedMark = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model || !model.edited) return

  // если вы сюда когда-то попадете, помните,
  // что у нас рекурсивное отображение с одинаковыми классами - ищите только в скопах текущего элемента
  const contentElement = element.querySelector(`:scope > .${CONTENT_CLASS_NAME}`)
  if (!contentElement || contentElement.getAttribute(ATTRIBUTE_CONTENT_TYPE) === TADA.MessageType.CHANGE) return

  const editedElement = createEditedMarkElement(model)

  const currentEditedElement = contentElement.querySelector(`.${EDITED_CLASS_NAME}`)
  if (currentEditedElement) {
    currentEditedElement.replaceWith(editedElement)
    return
  }

  contentElement.appendChild(editedElement)
  attachTooltip({ element: editedElement, cacheable: false })
}
