import Quill, { DeltaOperation, Sources } from 'quill'
import store from '@/store'
import { preventHtmlTags } from '@/utils'
import { Formats } from '@/components/Chat/ReplyArea/EditableArea/Instance'
import { defaultLogger } from '@/loggers'

export const isEmpty = (quill: Quill): boolean => {
  const { editor } = quill as any
  if (!editor || !editor.isBlank || typeof editor.isBlank !== 'function') return quill.getLength() === 1

  return editor.isBlank()
}

export const updateContents = (quill: Quill, ops: Array<DeltaOperation>, source: Sources = 'api') => {
  ops = ops.filter(op => op.retain === undefined || op.retain > 0)

  defaultLogger.debug('[Utils.ts updateContents]', ops)

  const DeltaCtor = Quill.import('delta')
  const delta = new DeltaCtor({ ops })
  quill.updateContents(delta, source)
}

export const getCursorPosition = (quill: Quill, focus?: boolean): number | null => {
  const range = quill.getSelection(focus)
  if (!range) return null

  const { index } = range
  return index < 0 ? null : index
}

export const insertAndFormat = (quill: Quill, text: string, source: Sources = 'api') => {
  const { clipboard } = quill
  if (!clipboard) return

  text = preventHtmlTags(text)
  text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')

  Object.keys(Formats).forEach(formatKey => {
    try {
      const formatHandler = Quill.import('formats/' + (Formats as any)[formatKey])
      if (!formatHandler) return

      const { textProcessor } = formatHandler
      if (!textProcessor) return

      text = textProcessor(text)
    } catch (e) {
      defaultLogger.warn('[Utils.insertAndFormat]', e)
    }
  })
  return clipboard.dangerouslyPasteHTML(text, source)
}

export const clearArea = (quill: Quill, source: Sources = 'api') => quill.setText('', source)

export const focusArea = (quill: Quill, moveCaretToEnd?: boolean, source: Sources = 'api') => {
  quill.focus()

  moveCaretToEnd && quill.setSelection(quill.getLength() - 1, 0, source)
}

export const getRawText = (quill: Quill): string | null => {
  const content = quill.getContents()
  const { ops } = content
  if (!ops) return null

  const formatNameList = Object.keys(Formats).map(key => (Formats as any)[key])
  const formatInstanceList = formatNameList.map(name => Quill.import('formats/' + name))

  let result = ''
  ops.forEach(operation => {
    const { insert } = operation
    if (!insert) return

    if (typeof insert === 'string') {
      result += insert
      return
    }

    formatNameList.some((formatName, index) => {
      const data = insert[formatName]
      if (!data) return false

      const formatInstance = formatInstanceList[index]
      if (!formatInstance) return true

      const { toRawText } = formatInstance
      if (!toRawText) return true

      result += toRawText(data)
      return true
    })
  })
  return result
}

export const splitContentToMessages = (quill: Quill, prepend?: string): string | Array<string> => {
  let text = getRawText(quill)
  if (!text) return ''

  text = text.trim()
  if (!text) return ''

  prepend && (text = prepend + text)

  const { getters } = store
  const maxLength = getters.maxMessageLength as number

  let content: string | Array<string>
  if (text.length < maxLength) {
    content = text
  } else {
    const messages = Math.ceil(text.length / maxLength)
    content = []
    for (let i = 0; i < messages; i++) {
      const part = text.substr(i * maxLength, maxLength)
      content.push(part)
    }
  }
  return content
}
