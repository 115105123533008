//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './Emoji/style.css'
import { EventBus, EventTypes } from './RAEventBus'
import { mapGetters } from 'vuex'
import Autocomplete from './Autocomplete'
import EditableArea from './EditableArea'
import { contactsStore, uiSettingsStore } from '@/store'

export default {
  name: 'ReplyArea',
  components: {
    EditableArea,
    Autocomplete,
    IconAt: () => import('@/components/UI/icons/IconAt'),
    IconDeer: () => import('@/components/UI/icons/IconDeer'),
    IconMic: () => import('@/components/UI/icons/IconMic'),
    IconSmile: () => import('@/components/UI/icons/IconSmile'),
    IconSmileSolid: () => import('@/components/UI/icons/IconSmileSolid'),
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      autocompleteData: null,
      emojiIconHovered: false,
    }
  },
  beforeDestroy () {
    EventBus.$off(EventTypes.AUTOCOMPLETE_CHANGE)
    EventBus.$off(EventTypes.COMMIT_MESSAGE)
  },
  mounted () {
    EventBus.$on(EventTypes.AUTOCOMPLETE_CHANGE, async data => {
      this.autocompleteData = data
      const contactsJID = []
      const entries = data?.entries
      entries && entries.forEach(e => {
        if (!e.meta || !e.meta.jid || !e.meta.jid.startsWith('d-')) return
        contactsJID.push(e.meta.jid)
      })
      await contactsStore.actions.loadContacts(contactsJID)
    })
    EventBus.$on(EventTypes.COMMIT_MESSAGE, content => {
      if (!content) return

      if (typeof content.text === 'string') {
        this.$emit('submit', content)
      } else if (Array.isArray(content.text)) {
        content.text.forEach((textPart, index) => {
          // emit forwarded messages with the last part of a very long message
          const partContent = index === content.text.length - 1
            ? { text: textPart, forwardedMessages: content.forwardedMessages }
            : { text: textPart }
          this.$emit('submit', partContent)
        })
      }
    })
  },
  computed: {
    ...mapGetters([
      'chatIsDirect',
    ]),
    isThemeNY () {
      return uiSettingsStore.getters.isThemeNY
    },
    isCurrentChatDirect () {
      return this.chatId && this.chatIsDirect(this.chatId)
    },
    emojiIcon () {
      if (this.isThemeNY) return 'IconDeer'
      return this.emojiIconHovered ? 'IconSmileSolid' : 'IconSmile'
    },
  },
}
