import i18n from '@/i18n'

import store from '@/store'
import { stringifyDate, isDatesEquals } from '@/utils'
import DOMUtils, { appendSort } from '@/utils/DOM'

import { insertMessage, extractMessage, isElementMessage, MessageDOMAttribute, insertMessageOptions } from '@/components/Chat/Instance/DOM'
import { collapseDayContainerIfNeeded } from '@/components/Chat/Instance/DOM/Components/Processing/Deleted'
// import { defaultLogger } from '@/loggers'

const DAY_CONTAINER_CLASS_NAME = 'day-container'
const ATTRIBUTE_LABEL = 'data-date'
const ATTRIBUTE_TIMESTAMP = 'data-ts'

const { getters } = store

export const getDayContainer = (messageElement: HTMLElement): HTMLElement | null => {
  const rosterElement = messageElement.parentElement
  if (!rosterElement) return null

  return rosterElement.parentElement
}

export default class {
  root: HTMLElement

  todayDate: Date | null
  yesterdayDate: Date | null

  reverse?: boolean

  constructor (root: HTMLElement, reverse?: boolean) {
    this.root = root
    this.reverse = reverse

    this.todayDate = this.yesterdayDate = null

    this.recalculateDaysLabels()
  }

  extract (element: HTMLElement) {
    const dayRoster = element.parentElement
    if (!dayRoster) throw new Error(`[DayContainer.extract] Message element [${MessageDOMAttribute.ID}="${element.getAttribute(MessageDOMAttribute.ID)}"] is not in roster of day container (no parent element)`)

    extractMessage({ element })

    const day = dayRoster.parentElement
    if (!day) throw new Error(`[DayContainer.extract] Message element [${MessageDOMAttribute.ID}="${element.getAttribute(MessageDOMAttribute.ID)}"] is in roster but roster is not in day container`)

    if (this.isDayEmpty(day)) {
      const success = !!DOMUtils.removeElement(day)
      if (!success) throw new Error(`[DayContainer.extract] Unable to remove empty day container [${ATTRIBUTE_TIMESTAMP}="${day.getAttribute(ATTRIBUTE_TIMESTAMP)}"] from DOM`)
    }
  }

  put (message: TADA.Message | HTMLElement, inverse?: boolean): HTMLElement {
    const options: Partial<insertMessageOptions> = {
      startFromTop: inverse,
    }

    let timestamp = null

    const messageIsElement = message instanceof HTMLElement
    if (messageIsElement) {
      message = message as HTMLElement
      const timestampAttribute = message.getAttribute(MessageDOMAttribute.TIMESTAMP) || ''
      timestamp = +timestampAttribute

      options.messageElement = message
    } else {
      message = message as TADA.Message
      const date = new Date(message.created)
      timestamp = date.getTime()

      options.date = date
      options.model = message
    }

    const day = this.searchDay(timestamp)
    options.container = day.lastElementChild as HTMLElement

    const messageElement = insertMessage(options as insertMessageOptions)

    const isMessageDeleted = !messageElement.getAttribute(MessageDOMAttribute.OWNER)
    isMessageDeleted
      ? collapseDayContainerIfNeeded(day, messageElement)
      : day.removeAttribute(MessageDOMAttribute.COLLAPSED)

    return messageElement
  }

  getEdgeMessageElement (
    first?: boolean,
    isOwn?: boolean,
    deleted = true,
  ): HTMLElement | null {
    const day = (first ? this.root.firstElementChild : this.root.lastElementChild) as HTMLElement
    if (!day) return null

    const { children } = day.lastElementChild as HTMLElement
    for (let i = 0; i < children.length; i++) {
      const index = first ? i : (children.length - i - 1)
      const messageElement = children[index] as HTMLElement

      if (
        !isElementMessage(messageElement) ||
        (!deleted && messageElement.classList.contains('deleted-message'))
      ) continue

      if (!isOwn) return messageElement

      const messageOwner = messageElement.getAttribute(MessageDOMAttribute.OWNER)
      if (messageOwner === getters.getUserId) return messageElement
    }
    return null
  }

  recalculateDaysLabelsIfNeeded () {
    if (!this.todayDate) {
      this.recalculateDaysLabels()
      return
    }

    const day = this.todayDate.getDate()

    const currentDate = new Date()
    const currentDay = currentDate.getDate()

    if (day === currentDay) return

    this.recalculateDaysLabels(currentDate)
  }

  private searchDay (elementTimestamp: number): HTMLElement {
    const { children } = this.root

    for (let i = children.length - 1; i >= 0; i--) {
      const day = children[i] as HTMLElement

      const timestampAttribute = day.getAttribute(ATTRIBUTE_TIMESTAMP)
      if (!timestampAttribute) continue

      const timestamp = +timestampAttribute
      if (this.isDayEmpty(day)) {
        DOMUtils.removeElement(day)
        return this.searchDay(elementTimestamp)
      }

      if (elementTimestamp >= timestamp) {
        if (elementTimestamp < timestamp + 86400000) {
          return day
        } else {
          break
        }
      }
    }
    return this.createDay(elementTimestamp)
  }

  private createDay (elementTimestamp: number): HTMLElement {
    const date = new Date(elementTimestamp)
    date.setHours(0, 0, 0, 0)

    const timestamp = date.getTime()
    const label = this.getLabel(date)

    const headingClasses = [`${DAY_CONTAINER_CLASS_NAME}__heading`]

    const headingElement = DOMUtils.createElement('div', {
      class: headingClasses.join(' '),
      [ATTRIBUTE_LABEL]: label,
    })
    const rosterElement = DOMUtils.createElement('div', { class: `${DAY_CONTAINER_CLASS_NAME}__roster` })

    const element = DOMUtils.createElement('div', {
      class: DAY_CONTAINER_CLASS_NAME,
      [ATTRIBUTE_TIMESTAMP]: timestamp,
    }, headingElement, rosterElement)

    appendSort({
      container: this.root,
      element,
      attribute: ATTRIBUTE_TIMESTAMP,
      predicate: this.reverse ? (value: string, childValue: string) => childValue < value : null,
    })

    const previousDay = element.previousElementSibling as HTMLElement
    if (previousDay) {
      const previousTimestamp = previousDay.getAttribute(ATTRIBUTE_TIMESTAMP)
      previousTimestamp && +previousTimestamp === timestamp && this.recalculateDaysLabels()
    }

    return element
  }

  private recalculateDaysLabels (todayDate?: Date) {
    this.todayDate = todayDate || new Date()
    this.yesterdayDate = new Date()
    this.yesterdayDate.setDate(this.yesterdayDate.getDate() - 1)

    const { children } = this.root
    for (let i = 0; i < children.length; i++) {
      const day = children[i]
      if (!day) continue

      const timestamp = day.getAttribute(ATTRIBUTE_TIMESTAMP)
      if (!timestamp) continue

      const date = new Date(+timestamp)
      const label = this.getLabel(date)
      day.setAttribute(ATTRIBUTE_LABEL, label)
    }
  }

  private getLabel (date: Date): string {
    const todayDate = this.todayDate
    const yesterdayDate = this.yesterdayDate
    if (!todayDate || !yesterdayDate) return ''

    return isDatesEquals(date, todayDate)
      ? i18n.t('common.today').toString()
      : isDatesEquals(date, yesterdayDate)
        ? i18n.t('common.yesterday').toString()
        : stringifyDate(date, todayDate.getFullYear())
  }

  private isDayEmpty (day: HTMLElement): boolean {
    const rosterElement = day.lastChild as HTMLElement
    if (!rosterElement) return false

    const children = rosterElement.children.length
    return (
      children === 0 ||
      (
        children === 1 &&
        !isElementMessage(rosterElement.children[0] as HTMLElement)
      )
    )
  }
}
