import { uiStore } from '@/store'
import DOMUtils from '@/utils/DOM'

import { selectionClickHandler } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonEvents'

const SELECT_MARK_CLASS_NAME = 'select-mark'

const createSelectionMark = () => {
  const element = DOMUtils.createElement('div', { class: SELECT_MARK_CLASS_NAME })
  element.addEventListener('click', () => {
    selectionClickHandler(element.parentNode as HTMLElement)
  })
  return element
}

export const updateSelectionMark = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  if (model.content.type === TADA.MessageType.CHANGE) return
  if (model.state !== TADA.MessageState.NORMAL) return
  if (model.isStandalone) return
  if ((window as any).isHeadless) return

  let selectionMarkElement = element.querySelector(`.${SELECT_MARK_CLASS_NAME}`)
  if (!selectionMarkElement) {
    selectionMarkElement = createSelectionMark()
    element.appendChild(selectionMarkElement)
  }

  const list = uiStore.getters.selectedMessagesIds
  if (!list || list.length <= 0) return

  list.indexOf(model.messageId) >= 0 && selectionClickHandler(element, true)
}
