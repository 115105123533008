import Quill from 'quill'

import { uiStore } from '@/store'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'
export default class {
  constructor (public quill: Quill) {
    quill.root.addEventListener('paste', this.handlePaste)

    // Only for Chrome and Safari:
    // this hook is called for every element on insertion and we just check
    // the userSelect (or webkitUserSelect for Safari) of the inserted element.
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: HTMLElement, delta) => {
      const { userSelect, webkitUserSelect } = getComputedStyle(node)
      if (userSelect === 'none' || webkitUserSelect === 'none') delta.ops = []
      return delta
    })

    EventBus.$on(EventTypes.BEFORE_DESTROY, this.destroy)
  }

  private handlePaste = (event: ClipboardEvent) => {
    const { originalEvent } = event as any
    const fromEvent = (originalEvent || event).clipboardData

    let { clipboardData } = window as any
    clipboardData = fromEvent || clipboardData

    const blob = this.handlePastingBlob(clipboardData)
    if (blob) {
      event.preventDefault()
      return false
    }
    return true
  }

  private handlePastingBlob = (clipboardData: DataTransfer): boolean => {
    if (!clipboardData) return false

    const { items, types } = clipboardData

    if (!items) return false

    // Parse meta in html for finding xml schema of Excel app
    const html = clipboardData.getData('text/html')
    const isExcel = html.match(
      'xmlns:x="urn:schemas-microsoft-com:office:excel"',
    )

    // Usually types are presented as Array:
    // ["text/plain", "text/html", "text/rtf"?, "Files", etc]
    const rtfType = types.includes('text/rtf')

    /**
     * If its not Excel but Rich Text Format (ex. Word, Libre, etc)
     * We must paste it as TEXT
     * Else - try past as IMAGE
     */
    if (rtfType && !isExcel) return false

    // Find item with Image type and paste it
    for (let index = 0; index < items.length; index++) {
      const item = items[index]
      if (item.type.includes('image')) {
        const blob = item.getAsFile()
        if (!blob) return false
        uiStore.actions.showModal({
          instance: 'FileUploader',
          // TODO: remove any
          payload: [
            new File([blob], 'Clipboard image', {
              type: 'image/png',
              lastModified: Date.now(),
            }),
          ] as any,
        })
        return true
      }
    }

    return false
  }

  private destroy = () => {
    this.quill.root.removeEventListener('paste', this.handlePaste)
  }
}
