




































































import type { AutocompleteEntry } from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/AutocompleteDataStore'
import { SearchType } from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/index'
import ScrollableAreaWrapper from '@/components/UI/Wrappers/ScrollableAreaWrapper.jsx'
import { Contact, Chat } from '@tada-team/tdproto-ts'
import { contactsStore, groupsStore } from '@/store'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { defaultLogger } from '@/loggers'
import { EventBus, EventTypes } from '../RAEventBus'
import { preventHtmlTags, parseEmoji, replaceAll, sortEntriesByChatType } from '@/utils'
import { throttle } from 'quasar'
import { searchAncestorByAttribute } from '@/utils/DOM'

const LIST_PADDING = 0
const ITEM_HEIGHT = 47
const MAX_ITEM_DISPLAY = 10
const MAX_ITEMS_RENDER_LIMIT = 100

// ScrollableAreaWrapper is not typed, so we cannot use
// its methods. We will temporarily hardcode this type here.
// TODO: remove this after adding typing to ScrollableAreaWrapper
type CustomScrollableArea = {
  getDistanceFromTop: () => number,
  scrollTo: (n: number) => void,
  $el: HTMLElement,
}

@Component({
  name: 'Autocomplete',
  components: {
    ScrollableAreaWrapper,
  },
})

export default class Autocomplete extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly data!: {
    context: SearchType,
    entries: Array<AutocompleteEntry>
  }

  @Ref() readonly scrollableArea!: CustomScrollableArea

  selected = 0
  dropdownOptions!: {
    listPadding: number,
    itemHeight: number,
    maxItemDisplay: number,
    maxItemsRenderLimit: number,
    maxListHeight: number
  }

  autocompleteIndexListener!: ({ index, fromKeyboard }: { index: number, fromKeyboard?: boolean }) => void
  handleMouseMove!: ReturnType<typeof throttle>

  get autocompleteStyle (): Record<string, string> {
    return {
      height: `${this.listHeight}px`,
      padding: `${this.dropdownOptions.listPadding}px`,
    }
  }

  get listHeight (): number {
    const { entries } = this.data
    if (!entries) return 0

    const { listPadding, itemHeight, maxItemDisplay, maxListHeight } = this.dropdownOptions
    if (entries.length < maxItemDisplay) return entries.length * itemHeight + listPadding * 2 + 2

    return maxListHeight
  }

  get dataEntriesSort (): Array<AutocompleteEntry> {
    return this.data.entries
      ? sortEntriesByChatType(this.data.entries).slice(0, this.dropdownOptions.maxItemsRenderLimit) : []
  }

  get isContextCommand (): boolean {
    return this.data.context === SearchType.COMMAND
  }

  created () {
    const listPadding = LIST_PADDING
    const itemHeight = ITEM_HEIGHT
    const maxItemDisplay = MAX_ITEM_DISPLAY
    const maxItemsRenderLimit = MAX_ITEMS_RENDER_LIMIT

    const padding = listPadding * 2 + 2
    const limit = Math.max(window.innerHeight - 150, itemHeight + padding)

    const maxListHeight = Math.min(maxItemDisplay * itemHeight + padding, limit)

    this.dropdownOptions = {
      listPadding,
      itemHeight,
      maxItemDisplay,
      maxItemsRenderLimit,
      maxListHeight,
    }

    this.autocompleteIndexListener = ({ index, fromKeyboard }: { index: number, fromKeyboard?: boolean }) => {
      this.selected = index
      fromKeyboard && this.$nextTick(this.fixScrollPosition)
    }
    EventBus.$on(EventTypes.AUTOCOMPLETE_SELECTED_INDEX, this.autocompleteIndexListener)

    this.handleMouseMove = throttle(this.handleMouseOver, 20)
  }

  beforeDestroy () {
    EventBus.$off(EventTypes.AUTOCOMPLETE_SELECTED_INDEX, this.autocompleteIndexListener)
  }

  handleItemClick (entry: AutocompleteEntry): void {
    defaultLogger.debug('[Autocomplete/index.vue handleItemClick]', entry)
    EventBus.$emit(EventTypes.AUTOCOMPLETE_COMMIT, { id: entry.key, type: this.data.context, title: entry.title })
    window.setTimeout(() => EventBus.$emit(EventTypes.FOCUS), 100)
  }

  handleMouseOver (event: MouseEvent): void {
    if (!event.target) return
    const entryElement = searchAncestorByAttribute(event.target as Node, 'data-index')
    if (!entryElement) return

    const indexAttribute = (entryElement as HTMLElement).getAttribute('data-index')
    if (!indexAttribute) return

    EventBus.$emit(EventTypes.AUTOCOMPLETE_SELECTED_INDEX, { index: +indexAttribute })
  }

  transformName (displayName: string): string {
    const { context: tag } = this.data

    const wrap = (value: string) => `<span style="color: rgba(0, 0, 0, 0.7); margin-right:1px;">${value}</span>`

    let result = preventHtmlTags(displayName)
    result = ` ${tag}` + result
    result = replaceAll(result, tag, wrap(tag))
    return parseEmoji(result)
  }

  getEntryName (entry: AutocompleteEntry): string | undefined {
    return this.isContextCommand
      ? entry.key
      : entry.title
        ? this.transformName(entry.title)
        : ''
  }

  isShowStatus (entry: AutocompleteEntry): boolean {
    if (!entry.meta || !entry.meta.jid) return false
    const isContextMention = this.data.context === '@'
    const isOnline = contactsStore.getters.contactOnlineType(entry.meta.jid) !== 'none'

    return Boolean(entry.meta.jid && isContextMention && isOnline)
  }

  isOnline (jid: string): boolean {
    return contactsStore.getters.contactOnlineType(jid) !== 'none'
  }

  isAfk (jid: string): boolean {
    return contactsStore.getters.contactIsAfk(jid)
  }

  getIcon (entry: AutocompleteEntry): string | undefined {
    const { entity } = this.$store.getters
    if (!(entry?.meta?.jid && !entry.meta.jid.startsWith('t-'))) return
    const entityObj = entity(entry.meta.jid)
    if (entityObj instanceof Contact) { return contactsStore.getters.contactIcon(entityObj?.jid) } else if (entityObj instanceof Chat) {
      return groupsStore.getters.icon(entityObj.jid, true)
    }
    return entityObj && entityObj.icon
  }

  getRole (entry: AutocompleteEntry): string | undefined {
    if (!entry?.meta?.jid) return

    const contact = contactsStore.getters.contact(entry.meta?.jid)
    return contact?.role
  }

  getEntryPublic (entry: AutocompleteEntry): string {
    const jid = entry?.meta?.jid
    if (!jid) return this.$t('chattape.openedGroup').toString()
    return jid.startsWith('t-')
      ? this.$t('chattape.publicTask').toString()
      : jid.startsWith('m-')
        ? this.$t('chattape.publicMeeting').toString() : this.$t('chattape.openedGroup').toString()
  }

  fixScrollPosition (): void {
    const { scrollableArea } = this
    if (!scrollableArea) return

    const container = scrollableArea.$el
    if (!container) return

    const targetElement = container.querySelector('.entry--selected')
    if (!targetElement) return

    const { top, bottom } = container.getBoundingClientRect()
    const { y, height } = targetElement.getBoundingClientRect()

    const targetYCenter = y + height / 2

    if (targetYCenter > top && targetYCenter < bottom) return

    const offset = targetYCenter >= bottom
      ? (y - bottom + height)
      : (targetYCenter <= top ? -(top - y) : 0)

    const currentOffset = scrollableArea.getDistanceFromTop()
    scrollableArea.scrollTo(currentOffset + offset)
  }
}
