var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.task.items.length || _vm.editUid || _vm.loading || _vm.lastDeletedItem)?_c('div',{staticClass:"task-checklist"},[_c('Header',{attrs:{"show-toggle-completed-btn":_vm.doneItems > 0,"show-completed":_vm.showChecked},on:{"toggleShowCompleted":_vm.toggleShowChecked}}),(_vm.errorText)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e(),(_vm.task.items.length || _vm.lastDeletedItem)?_c('div',{staticClass:"task-progress"},[_c('div',{staticClass:"percent"},[_vm._v(" "+_vm._s(_vm.percent)+"% ")]),_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"bar-inner",style:(_vm.progressStyle)})])]):_vm._e(),_c('List',{staticClass:"task-checklist__list",class:{ editable: _vm.canEdit },attrs:{"items":_vm.displayItems,"counters":_vm.counters,"can-edit":_vm.canEdit,"deleted-items":_vm.deletedItems,"loading-items":_vm.itemsLoading,"unsaved-items":_vm.unsavedItems,"editing-item":_vm.editUid},on:{"add":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.editItem.apply(void 0, args);
},"clickItem":_vm.handleItemClick,"closeEditor":function($event){_vm.editUid = null},"closeDeleted":function($event){_vm.lastDeletedItem = null},"createTask":_vm.onCreateTask,"delete":_vm.onDelete,"edit":_vm.handleEdit,"orderChanged":_vm.onOrderChanged,"restoreDeleted":_vm.restoreItem,"save":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.onUnsaved.apply(void 0, args);
},"toggleItem":function (uid) { return _vm.toggleItem(uid); },"viewUnsaved":_vm.toggleEdit,"discardUnsaved":function (uid) { return _vm.onUnsaved(uid, { text: null }); }}}),(_vm.canEdit)?_c('div',{staticClass:"add column items-stretch"},[(_vm.editUid === 'new')?_c('Editor',{attrs:{"initial-text":_vm.unsavedItems[_vm.task.jid] && _vm.unsavedItems[_vm.task.jid].text || '',"is-loading":_vm.loading},on:{"close":function($event){_vm.editUid = null},"unsaved":function (text) { return _vm.onUnsaved(null, { text: text }); },"submit":_vm.addItem}}):_c('div',{staticClass:"add-btn",on:{"click":_vm.startAddItem}},[_vm._v(" "+_vm._s(_vm.$t('tasks.taskItems.addItem'))+" ")])],1):_vm._e()],1):(_vm.canEdit)?_c('div',{staticClass:"empty-checklist",on:{"click":_vm.startAddItem}},[_c('i',{staticClass:"fas fa-check-square-o",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('tasks.taskItems.addCheckList'))+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }