//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResizeSensor from 'vue-resize-sensor'

import api from '@/api/v3'
import Messages from '@/store/messages'
import { flushElement } from '@/utils/DOM'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { mapGetters } from 'vuex'

import CheckBox from '@/components/UI/CheckBox'
import SearchInput from '@/components/UI/SearchInput'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import ScrollableArea from '@/components/UI/ScrollableArea'

import { setMaxMessageContentWidth, applyMoveByClick, applyFilterHighlight } from './Utils'
import HookUtilsMixin from './Mixins/HookUtilsMixin'
import AppearanceMixin from './Mixins/AppearanceMixin'
import DateHeadingMixin from './Mixins/DateHeadingMixin'
import ThresholdObserverMixin from './Mixins/ThresholdObserverMixin'
import MediaContentResizeMixin from './Mixins/MediaContentResizeMixin'

import Tape from '../ChatTape'

import { teamsStore } from '@/store'

export default {
  name: 'ImportantHook',
  components: {
    'scrollable-area': ScrollableArea,
    BaseSpinner,
    checkbox: CheckBox,
    'search-input': SearchInput,

    'resize-sensor': ResizeSensor,
  },
  mixins: [
    HookUtilsMixin,
    AppearanceMixin,
    DateHeadingMixin,
    ThresholdObserverMixin,
    MediaContentResizeMixin,
  ],
  props: {
    chatId: String,
  },
  data () {
    return {
      currentChatOnly: true,
      count: 0,
      loading: true,
      filter: '',
    }
  },
  watch: {
    filter (currentValue, previousValue) {
      if (
        currentValue.length >= this.minFilterLength ||
        (
          previousValue.length > currentValue.length &&
          previousValue.length >= this.minFilterLength
        ) || !currentValue.length
      ) {
        this.load()
      }
      this.previousFilter = previousValue
    },
    currentChatOnly () {
      this.$nextTick(() => { this.load() })
    },
  },
  computed: {
    ...mapGetters([
      'getUserId',
    ]),
    showInChatOnlyCheckbox () {
      return !teamsStore.getters.isSingleGroupTeam()
    },
  },
  created () {
    this.minFilterLength = 3
    this.previousFilter = ''

    this.messages = {}
  },
  beforeDestroy () {
    const { container } = this.$refs
    if (!container) return

    Object.keys(this.handlers).forEach(eventName => {
      ChatEventBus.$off(eventName, this.handlers[eventName])
    })

    flushElement(container)
    Messages.clearStandaloneMessages(this.chatId)
  },
  mounted () {
    const { container } = this.$refs
    container && setMaxMessageContentWidth(container.scrollWidth)

    this.tape = new Tape(container, false)

    this.handlers = {
      [Events.SET_CHAT_LOADING_STATUS]: ({ chatId, status }) => {
        if (chatId !== this.chatId) return

        this.loading = status
      },
      [Events.ADD_SINGLE_MESSAGE]: ({ chatId, messages, delayed, confirmed }) => {
        if (chatId !== this.chatId) return

        if (!delayed || confirmed) return

        const { orderIds, data } = messages
        const message = data[orderIds[orderIds.length - 1]]
        if (!message) return

        const { important, messageId } = message
        if (important) {
          this.load()
          return
        }

        const object = this.messages[messageId]
        object && this.load()
      },
      [Events.SET_MESSAGE_IMPORTANCE]: ({ chatId, message }) => {
        if (this.chatId !== chatId) return

        const { important, sender } = message
        const isOwnMessage = sender === this.getUserId

        if (isOwnMessage && !important) {
          this.removeMessage(message)
          this.count -= 1
          return
        }
        this.load()
      },
    }
    Object.keys(this.handlers).forEach(eventName => {
      ChatEventBus.$on(eventName, this.handlers[eventName])
    })

    this.load()
  },
  methods: {
    createPagination ({ text, chat }) {
      return api.messages.filter({ text, chat, important: 'true' }, 25)
    },
    async load () {
      this.messages = {}
      this.tape.flush()
      this.end = false

      this.changeLoadingStatus(true)

      this.pagination && this.pagination.cancel()

      this.pagination = this.createPagination({
        text: this.filter,
        chat: this.currentChatOnly ? this.chatId : '',
      })

      const messages = await this.pagination.next()
      messages && messages.reverse().forEach(this.handleMessage)

      const { offset, count } = this.pagination
      this.end = offset >= count
      this.count = count

      this.changeLoadingStatus(false)
      this.DHMHandler && this.DHMHandler()
      this.scrollDown()
    },
    async loadNext () {
      if (this.end) return

      this.changeLoadingStatus(true, true)

      this.pagination && this.pagination.cancel()
      const messages = await this.pagination.next()
      messages && messages.reverse().forEach(this.handleMessage)

      const { offset, count } = this.pagination
      this.end = offset >= count
      this.count = count

      this.changeLoadingStatus(false, true)
    },
    removeMessage (message) {
      const { messageId } = message
      const object = this.messages[messageId]
      if (!object) return

      const { element } = object
      this.tape.removeMessage(element)

      delete this.messages[messageId]
    },
    handleMessage (message) {
      if (!message) return

      const { messageId } = message
      const object = this.messages[message.messageId]
      if (object) {
        const { element } = object
        this.tape.updateMessage(message, element)

        Object.assign(this.messages[messageId], {
          model: message,
        })
        return
      }

      const element = this.tape.addMessage(message)
      this.messages[messageId] = { model: message, element }

      this.postprocessElement(element, message)
    },
    postprocessElement (element, message) {
      applyMoveByClick(element, message)

      if (this.previousFilter !== this.filter) {
        applyFilterHighlight(this.filter.length >= this.minFilterLength ? this.filter : '', element, message)
      }
    },
    changeLoadingStatus (status, next) {
      const event = next ? Events.SET_NEW_MESSAGES_LOADING_STATUS : Events.SET_CHAT_LOADING_STATUS
      ChatEventBus.$emit(event, { chatId: this.chatId, status })
    },
  },
}
