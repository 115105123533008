import i18n from '@/i18n'

import { formatText } from '@/utils/Common'
import { calendar } from '@/utils/DataUtils'
import DOMUtils from '@/utils/DOM'
import {
  contactsStore,
  uiStore,
} from '@/store'
import { attachTooltip } from '@/components/UI/Popouts/TooltipController'
import { extendLinks } from './Links'
import { messageToHTML } from './Utils'

const createActorTooltip = async (actor: string | null, created: any) => {
  const element = DOMUtils.createElement('span', {
    class: 'o-icon-question',
  })
  if (actor && !contactsStore.getters.contact(actor)) {
    await contactsStore.actions.loadContact(actor)
  }
  const name = contactsStore.getters.contactDisplayName(actor)
  const date = calendar(new Date(created), true)

  attachTooltip({
    element,
    content: `${name}, ${date}`,
  })

  return element
}

const createDetailsButton = ({ title, oldValue, newValue }: { title: string; oldValue: string | null; newValue: string | null }): HTMLElement => {
  const detailsElement = DOMUtils.createElement('a', {
    href: '#',
  }, i18n.t('chattape.changeMessage.moreInfo').toString())

  oldValue = formatText({ value: oldValue || '', links: false })
  newValue = formatText({ value: newValue || '', links: false })
  const text = `<div class=pre>${oldValue}</div> → <div class=pre>${newValue}</div>`

  detailsElement.addEventListener('click', (e: Event) => {
    e.preventDefault()

    uiStore.actions.showModal({
      instance: 'universal-yes-no',
      payload: { title, noText: i18n.t('common.close').toString(), text },
    })
  })

  return detailsElement
}

export const createChangeContent = async (contentElement: HTMLElement, message: TADA.Message) => {
  const links = message.links
  const content = message.content as TADA.MessageContentChange
  let { title, text, old: oldValue, new: newValue } = content
  const { actor } = content

  // This wraps content that should be selectable by dblclick.
  // Its parent has a dblclick listener that start replying sequence.
  // Since this wrapper will (typically be smaller) than its parent,
  // click inside parent but outside wrapper will still trigger reply.
  // Clicking inside wrapper will trigger native browser handler and not reply.
  const wrapper = document.createElement('span')
  contentElement.appendChild(wrapper)

  oldValue = (oldValue || i18n.t('chattape.changeMessage.emptyField').toString()).trim()
  newValue = (newValue || i18n.t('chattape.changeMessage.emptyField').toString()).trim()

  if (title !== text && oldValue.length + newValue.length > 200) {
    wrapper.textContent = title + '. '
    const el = createDetailsButton({ title, oldValue, newValue })
    wrapper.appendChild(el)
  } else {
    const description = messageToHTML(message)
    wrapper.innerHTML = description + ' '
  }

  extendLinks(contentElement, links, message.sender)
  contentElement.appendChild(
    await createActorTooltip(actor, message.created),
  )
}
