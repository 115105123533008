





















import {
  clearCurrentActiveMessageTarget,
  toggleActiveMessageAppearence,
} from '@/components/Chat/Instance/DOM/Components/Helpers/ActiveMessage'
import { defaultLogger } from '@/loggers'
import { format } from 'quasar'
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconTimes from '@/components/UI/icons/IconTimes.vue'

const { capitalize } = format

@Component({
  name: 'ActiveMessages',
  components: {
    IconTimes,
  },
})

export default class ActiveMessages extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly data!: { goal: string, messageId: string[]}

  get goalText (): string {
    const goalTranslateKey = this.data.goal === 'edit'
      ? 'glossary.editing'
      : this.data.messageId.length === 1
        ? 'chattape.replyTo'
        : 'chattape.messageActions.forwardHere'
    return capitalize(this.$t(goalTranslateKey).toString())
  }

  get toggleCaption (): string {
    const { messageId } = this.data
    const count = Array.isArray(messageId) ? messageId.length : 1
    return count === 1
      ? this.$tc('glossary.message', 1)
      : this.$tc('glossary.message_count', count)
  }

  clear (): void {
    defaultLogger.info('Active message clearing current active message target')
    clearCurrentActiveMessageTarget()
  }

  toggle (): void {
    toggleActiveMessageAppearence()
  }
}
