// import { numeral } from '@/utils'

const formatTime = date => {
  return date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2)
}

export const toStringMicroseconds = date => new Date(date).getTime() +
  (date.length >= 26 ? date.substr(23, 3) : '000')
// const units = {
//   second: 1000,
//   minute: 1000 * 60,
//   hour: 1000 * 60 * 60,
//   day: 1000 * 60 * 60 * 24,
//   week: 1000 * 60 * 60 * 24 * 7
// }

// const getTimeAgo = date => {
//   if (!date) return

//   if (typeof date === 'string') {
//     date = new Date(date)
//   }

//   if (!(date instanceof Date)) return

//   console.log(date)
//   date = date.getTime()

//   const difference = Date.now() - date
//   if (difference < 0) return

//   if (difference < units.minute) return 'только что'

//   const max = 4 * units.week
//   if (difference >= max) return

//   const labels = ['minute', 'hour', 'day', 'week']
//   const locales = [
//     ['минуту', 'минуты', 'минут'],
//     ['час', 'часа', 'часов'],
//     ['день', 'дня', 'дней'],
//     ['неделю', 'недели', 'недель']
//   ]

//   let ago = null

//   labels.some((label, index) => {
//     const lower = units[label]
//     const upper = units[labels[index + 1]] || max

//     if (difference >= lower && difference < upper) {
//       const count = Math.floor(difference / lower)
//       return (ago = numeral(count, ...locales[index]))
//     }
//   })

//   return ago && (ago + ' назад')
// }

export default {
  formatTime,
}
