import { sortBy } from 'lodash'
import { Chat } from '@tada-team/tdproto-ts'
import store from '@/store'
import i18n from '@/i18n'
import { date as quasarDate } from 'quasar'

const {
  getDateDiff,
  getWeekOfYear,
  formatDate,
} = quasarDate

export const enum SortingType {
  DEFAULT = 0,
  NAME = 1
}

const orderByPinned = (entityA: any, entityB: any) => {
  const { getters } = store

  if (entityA.chatType === undefined) {
    entityA = getters.chat(entityA.jid) || {}
  }

  if (entityB.chatType === undefined) {
    entityB = getters.chat(entityB.jid) || {}
  }

  const pinnedA = entityA.pinned
  const pinnedB = entityB.pinned
  if (pinnedA && pinnedB) {
    return entityA.pinnedSortOrdering < entityB.pinnedSortOrdering ? -1 : 1
  } else if (pinnedA) {
    return -1
  } else if (pinnedB) {
    return 1
  }
  return null
}

const sortByName = (entityA: TADA.Chat | TADA.Entity | Chat, entityB: TADA.Chat | TADA.Entity | Chat) => {
  const pinnedOrder = orderByPinned(entityA, entityB)
  if (pinnedOrder !== null) return pinnedOrder

  const a = entityA as any
  const b = entityB as any

  let nameA = a.displayName
  let nameB = b.displayName

  const { getters } = store

  if (nameA === undefined) {
    const entity = getters.entity(a.chatId || a.jid)
    entity && (nameA = entity.displayName)
  }

  if (nameB === undefined) {
    const entity = getters.entity(b.chatId || b.jid)
    entity && (nameB = entity.displayName)
  }

  if (nameA) nameA = nameA.toLowerCase()
  if (nameB) nameB = nameB.toLowerCase()

  return nameA > nameB ? 1 : -1
}

interface SortableEntity {
  lastActivity: string;
  created?: string;
}

/**
 * Sort predicate to sort by last activity.
 * If last activity is not present, defaults to created.
 * @param entityA chat to sort
 * @param entityB chat to sort
 */
const sortByActivity = (entityA: SortableEntity | Chat, entityB: SortableEntity | Chat) => {
  const pinnedOrder = orderByPinned(entityA, entityB)
  if (pinnedOrder !== null) return pinnedOrder

  const a = entityA.lastActivity ?? entityA.created ?? ''
  const b = entityB.lastActivity ?? entityB.created ?? ''
  return a > b ? -1 : a < b ? 1 : 0
}

const orderByUnread = (entity: any) => {
  if (entity.chatType === undefined) {
    const { getters } = store
    entity = getters.chat(entity.jid) || {}
  }

  if (entity.pinned) return -1

  return entity.numUnread ? -1 : 0
}

export const sortChats = (
  list: Array<TADA.Chat | TADA.Task | Chat | TADA.Contact>,
  type: SortingType = SortingType.DEFAULT, unreadFirst = false,
): Array<TADA.Chat | TADA.Entity | Chat> => {
  if (!list) {
    throw new TypeError(
    `Incorrect list passed to sortChats. Expected Array, got: ${list}`,
    )
  }

  switch (type) {
    case SortingType.DEFAULT: {
      list = list.sort(sortByActivity)
      break
    }
    case SortingType.NAME: {
      list = list.sort(sortByName)
      break
    }
  }

  if (unreadFirst) {
    list = sortBy(list, orderByUnread)
  }

  // list = _.sortBy(list, orderByPinned)

  return list
}

export const dataURItoBlob = (dataURI: string): Blob => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return blob
}

export const calendar = (date: Date, showTime = false): string => {
  date = new Date(date || '')
  if (isNaN(date.getTime())) {
    return ''
  }

  let dateFormat = (i18n.locale === 'ru')
    ? 'DD.MM.YYYY'
    : 'MM/DD/YYYY'

  const currentWeekNumber = getWeekOfYear(new Date())
  const dateWeekNumber = getWeekOfYear(date)

  if (currentWeekNumber === dateWeekNumber) {
    dateFormat = 'dddd'
  }

  const diffDays = getDateDiff(Date(), date, 'days')

  let dateString
  if (diffDays === 0) {
    dateString = i18n.t('common.today').toString().toLowerCase()
  } else if (diffDays === 1) {
    dateString = i18n.t('common.yesterday').toString().toLowerCase()
  } else {
    dateString = formatDate(date, dateFormat)
    i18n.locale === 'ru' && (dateString = dateString.toLowerCase())
  }

  if (!showTime) {
    return dateString.toString()
  }

  const timeFormat = (i18n.locale === 'ru')
    ? 'HH:mm'
    : 'hh:mm A'

  const timeString = ` ${i18n.t('common.at')} ${formatDate(date, timeFormat)}`

  return dateString + timeString
}
