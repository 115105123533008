import { Actions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule'

export interface ActionOptions { messageId: string | string[]; goal: Actions }

export default abstract class BaseAction {
  protected chatId: string | null = null

  constructor (chatId: string) {
    this.setChatId(chatId)
  }

  setChatId (chatId: string) { this.chatId = chatId }
  abstract action (options: ActionOptions): boolean
  abstract flush (): void
}
