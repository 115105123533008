import Quill from 'quill'

import Messages from '@/store/messages'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'

import BaseAction, { ActionOptions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule/BaseAction'
import { Actions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule'

export default class ReplyingAction extends BaseAction {
    private replyingContent: {
        text: string;
        forwardedMessages: Array<TADA.Message>;
        replyTo: TADA.Message | null;
    }

    constructor (public quill: Quill, chatId: string) {
      super(chatId)

      this.replyingContent = { text: '', forwardedMessages: [], replyTo: null }
    }

    getReplyingContent = () => this.replyingContent

    action (options: ActionOptions): boolean {
      if (!this.chatId) return false

      const { messageId: id, goal } = options
      if (!id || goal !== Actions.REPLYING) return false
      this.flush()

      const ids = Array.isArray(id) ? id : [id]
      if (ids.length === 1) {
        this.replyingContent.replyTo = Messages.getMessage(this.chatId, ids[0])
      } else {
        ids.forEach(messageId => {
          const message = Messages.getMessage(this.chatId!, messageId)
          if (!message) return false
          this.replyingContent.forwardedMessages.push(message)
        })
      }

      Utils.focusArea(this.quill, true)

      return true
    }

    flush () {
      this.replyingContent = { text: '', forwardedMessages: [], replyTo: null }
    }
}
