import { createReactionsPopoutContent } from '@/components/Chat/Instance/DOM/Components/Helpers/Reactions'
import Popout, { attachLazyPopout, ATTRIBUTE_ANCHOR_POPOUT_ID } from '@/components/UI/Popouts/Popout'
import { REACTION_POPOUT_ID } from '@/components/Chat/Instance/DOM/Components/Tools/Models'

type LazyContentCreator = (popout: Popout) => HTMLElement | string

const getToolPopoutContent = (element: HTMLElement, id?: string | null): LazyContentCreator | null => {
  id = id || element.getAttribute(ATTRIBUTE_ANCHOR_POPOUT_ID)

  switch (id) {
    case REACTION_POPOUT_ID: return createReactionsPopoutContent
    default: return null
  }
}

const onPopoutShow = (anchor: HTMLElement) => {
  const toolsElement = anchor.parentNode as HTMLElement
  if (!toolsElement) return

  anchor.classList.add('force')
  toolsElement.classList.add('force')
}

const onPopoutHide = (anchor: HTMLElement) => {
  const toolsElement = anchor.parentNode as HTMLElement
  if (!toolsElement) return

  anchor.classList.remove('force')
  toolsElement.classList.remove('force')
}

export const applyPopoutTool = (buttonElement: HTMLElement, popoutId: string, content?: LazyContentCreator | null) => {
  buttonElement.setAttribute(ATTRIBUTE_ANCHOR_POPOUT_ID, popoutId)

  content = content || getToolPopoutContent(buttonElement)
  if (!content) return

  attachLazyPopout({
    anchor: buttonElement,
    content,
    behaviour: {
      onShow: onPopoutShow,
      onHide: onPopoutHide,
      local: true,
    },
  })
}
