import i18n from '@/i18n'

import store, { contactsStore, groupsStore } from '@/store'
import DOMUtils from '@/utils/DOM'
import { toStringMicroseconds } from '@/utils/Date'
import { calendar } from '@/utils/DataUtils'
import { transformEntityName } from '@/utils'

import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { createContent, CONTENT_CLASS_NAME } from '@/components/Chat/Instance/DOM/Components/Content'
import { createSenderNameElement, getSender } from '@/components/Chat/Instance/DOM/Components/Common'
import { checkAttributesToCollapse } from '@/components/Chat/Instance/DOM/Components/SenderData'
import { getMaxMessageContentWidth, setMaxMessageContentWidth } from '@/components/Chat/Instance/Hooks/Utils'
import { nameClickEvent, groupClickEvent } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonEvents'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { Contact, Chat, SingleIcon } from '@tada-team/tdproto-ts'
import * as Sentry from '@sentry/browser'

export const ATTACHMENT_CONTAINER_CLASS_NAME = 'message-attachment-container'
export const ATTACHMENT_CLASS_NAME = 'attachment-message'
export const ATTACHMENT_SENDER_DATA_CLASS_NAME = 'attachment-sender-data'
export const ATTACHMENT_MESSAGE_DATA_CLASS_NAME = 'attachment-message-data'

export const ATTACHMENT_MEDIA_HEIGHT_LIMIT = 200
export const ATTACHMENT_MEDIA_WIDTH_DIFF = 45

export const createAttachedSenderData = (sender: TADA.Entity | Contact | Chat) => {
  let icon: string | SingleIcon | undefined
  if (sender instanceof Contact) {
    icon = contactsStore.getters.contactIcon(sender.jid)
  } else if (sender instanceof Chat) {
    icon = groupsStore.getters.icon(sender.jid)
  } else {
    icon = sender.icon
  }
  const senderAvatarElement = DOMUtils.createElement('img', { class: 'avatar', src: icon })

  if (sender.jid === 'dummy') {
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Error)
      scope.setTag('tech debt', 'createSenderData')
      scope.setContext('chat', { sender })
      Sentry.captureException('Unexpected jid detected (2).')
    })
  }

  const senderIsGroup = sender instanceof Chat && sender.chatType === 'group'
  const groupClickFunc = (
    senderIsGroup &&
    (
      (sender instanceof Chat && sender.isPublic) ||
      sender?.canSendMessage
    )
  ) ? groupClickEvent : () => { }

  const senderNameElement = createSenderNameElement({ sender, onClick: senderIsGroup ? groupClickFunc : nameClickEvent })
  return DOMUtils.createElement('div', { class: ATTACHMENT_SENDER_DATA_CLASS_NAME }, senderAvatarElement, senderNameElement)
}

const createAttachedMessageData = (chatName: string | null, date: Date) => {
  let sourceElement = null
  if (chatName) {
    sourceElement = DOMUtils.createElement('span', { class: 'source o-text-cutter' })
    sourceElement.innerHTML = transformEntityName(chatName)
  }
  const dateElement = DOMUtils.createElement('span', { class: chatName ? 'date' : null }, calendar(date, true))
  return DOMUtils.createElement('div', { class: ATTACHMENT_MESSAGE_DATA_CLASS_NAME }, sourceElement, dateElement)
}

const createAttachmentMessage = (model: TADA.Message, date?: Date, showName = true) => {
  const { getters } = store
  const { sender, created, chatId, chatType } = model
  const contact = getSender(sender)

  const senderDataElement = createAttachedSenderData(contact)

  const contentElement = createContent(model, ATTACHMENT_MEDIA_HEIGHT_LIMIT)

  let messageDataElement: HTMLElement | null = null
  let chatName: string | null = null

  date = date || new Date(created)

  if (showName) {
    if (chatType !== 'direct') {
      const messageOrigin = getters.entity(chatId)
      if (messageOrigin) {
        chatName = messageOrigin.displayName
      }
    }

    if (!chatName) {
      switch (chatType) {
        case 'direct': {
          chatName = i18n.t('chattape.messageFromDirect').toString()
          break
        }
        case 'group': {
          chatName = i18n.t('chattape.messageFromGroup').toString()
          break
        }
        case 'task': {
          chatName = i18n.t('chattape.messageFromTask').toString()
          break
        }
      }
    }
  }
  messageDataElement = createAttachedMessageData(chatName, date)

  return DOMUtils.createElement('div', {
    class: ATTACHMENT_CLASS_NAME,
    [MessageDOMAttribute.TIMESTAMP]: date.getTime(),
    [MessageDOMAttribute.DATE]: toStringMicroseconds(created),
    [MessageDOMAttribute.OWNER]: sender,
  }, senderDataElement, contentElement, messageDataElement)
}

const expandAttachmentMessage = (attachmentMessageElement: HTMLElement, model: TADA.Message) => {
  if (!attachmentMessageElement) return

  const messageDataElm = attachmentMessageElement.lastChild
  if (!messageDataElm) return

  if ((messageDataElm as HTMLElement).className !== ATTACHMENT_MESSAGE_DATA_CLASS_NAME) return

  const contentElement = createContent(model, ATTACHMENT_MEDIA_HEIGHT_LIMIT)
  attachmentMessageElement.insertBefore(contentElement, messageDataElm)
}

/**
 * Создает контейнер списка приклепленных сообщений, используется в чате и в диалоговых окнах
 * @param linkedMessages Массив моделей сообщеений
 * @param context Элемент-контейнер списка, с которого будет взята максимально допустимая ширина сообщения (для корректного отображения Image-сообщений)
 */
export const createAttachmentContainer = (linkedMessages: Array<TADA.Message>, context?: HTMLElement, attachDate = true): HTMLElement => {
  let tempMaxMessageContentWidth: number | null = null
  tempMaxMessageContentWidth = getMaxMessageContentWidth()

  context
    ? setMaxMessageContentWidth(context.scrollWidth)
    : setMaxMessageContentWidth(tempMaxMessageContentWidth - ATTACHMENT_MEDIA_WIDTH_DIFF, true)

  const element = DOMUtils.createElement('div', { class: ATTACHMENT_CONTAINER_CLASS_NAME })

  let lastAttachedMessageElement: HTMLElement | null = null
  linkedMessages.forEach(message => {
    const { created, sender } = message
    const date = new Date(created)

    if (lastAttachedMessageElement && checkAttributesToCollapse(date.getTime(), sender, lastAttachedMessageElement)) {
      expandAttachmentMessage(lastAttachedMessageElement, message)
      return
    }

    const attachmentMessageElement = createAttachmentMessage(message, date, attachDate)
    element.appendChild(attachmentMessageElement)

    lastAttachedMessageElement = attachmentMessageElement
  })

  tempMaxMessageContentWidth && setMaxMessageContentWidth(tempMaxMessageContentWidth, true)

  return element
}

export const updateAttachments = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  let { linkedMessages } = model
  const { replyTo } = model
  if ((!linkedMessages || linkedMessages.length === 0) && !replyTo) return

  const containerBeforeText = replyTo
  let useLinkedInsteadReply = !replyTo

  // поменять этот костыль когда-нибудь!
  // если нет текста и вложений в том, на что ты ответил
  // значит внутри этого сообщения (на которое ответил)
  // могут быть прилинкованные (linked) или ответ на другое сообщение (replyTo)
  // и их надо вынести на уровень выше и показать
  if (replyTo?.content.text === '' && replyTo?.uploads?.length === 0) {
    linkedMessages = replyTo?.replyTo
      ? [replyTo.replyTo]
      : replyTo.linkedMessages
    useLinkedInsteadReply = true
  }

  let newAttachmentContainerElement = null
  // если рисуем реплаи - не отображаем данные о сендере, дате и чате
  if (!useLinkedInsteadReply && replyTo) {
    newAttachmentContainerElement = createAttachmentContainer([replyTo], undefined, false)
  } else {
    newAttachmentContainerElement = createAttachmentContainer(linkedMessages)
  }

  const attachmentContainerElement = element.querySelector(`.${ATTACHMENT_CONTAINER_CLASS_NAME}`)

  if (replyTo) {
    newAttachmentContainerElement.classList.add('cursor-pointer')
    newAttachmentContainerElement.addEventListener('click', () => {
      ChatEventBus.$emit(Events.GO_TO_MESSAGE, {
        chatId: model.replyTo?.chatId,
        messageId: model.replyTo?.messageId,
      })
    })
  }

  if (attachmentContainerElement) {
    attachmentContainerElement.replaceWith(newAttachmentContainerElement)
    return
  }

  const contentElement = element.querySelector(`.${CONTENT_CLASS_NAME}`)

  contentElement && containerBeforeText ? element.insertBefore(newAttachmentContainerElement, contentElement) : element.appendChild(newAttachmentContainerElement)
}
