import Quill from 'quill'

import { teamsStore } from '@/store'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'
import { popHandler } from '@/components/Chat/ReplyArea/EditableArea/Keyboard'
import { Modules } from '@/components/Chat/ReplyArea/EditableArea/Instance'
import MessageActionModule, { Actions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule'
import ReplyingAction from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule/ReplyingAction'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'

export default class {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor (public quill: Quill, options: any) {
    // shift+enter - always new line
    // ctrl/cmd+enter - always send
    // enter - depends on me.altSend

    this.quill.keyboard.addBinding({ key: 'Enter', metaKey: true } as any, range => {
      const { me } = teamsStore.getters.currentTeam

      if (me.altSend) {
        return this.send()
      } else {
        this.quill.insertText(range.index, '\n')
        return true
      }
    })
    popHandler(this.quill, 13)

    this.quill.keyboard.addBinding({ key: 'Enter', ctrlKey: true } as any, () => {
      const { me } = teamsStore.getters.currentTeam

      if (me.altSend) {
        return this.send()
      } else {
        return true
      }
    })
    popHandler(this.quill, 13)

    this.quill.keyboard.addBinding({ key: 'Enter' }, () => {
      const { me } = teamsStore.getters.currentTeam

      if (me.altSend) {
        return true
      } else {
        return this.send()
      }
    })
    popHandler(this.quill, 13)

    EventBus.$on(EventTypes.COMMIT_MESSAGE, () => { Utils.clearArea(this.quill) })
    EventBus.$on(EventTypes.BEFORE_DESTROY, this.destroy)

    const button = document.getElementById('send-message-button')
    button && button.addEventListener('click', this.send)
  }

  private destroy = () => {
    const button = document.getElementById('send-message-button')
    button && button.removeEventListener('click', this.send)
  }

  private send = () => {
    const replyingContent = this.getReplyingContent()

    if (replyingContent) {
      const text = Utils.splitContentToMessages(this.quill, replyingContent.text)
      const forwardedMessages = replyingContent.forwardedMessages
      const replyTo = replyingContent.replyTo

      const hasText = text.length > 0
      const hasForwardedMessages = forwardedMessages.length > 0

      if (hasText || hasForwardedMessages) {
        EventBus.$emit(EventTypes.COMMIT_MESSAGE, { text, forwardedMessages, replyTo })
      }
    }

    return false
  }

  private getReplyingContent = (): (
    { text: string; forwardedMessages: Array<TADA.Message>; replyTo: TADA.Message | null } | null
  ) => {
    const actionModule = this.quill.getModule(Modules.MESSAGE_ACTION) as MessageActionModule
    if (!actionModule) return null

    const actions = actionModule.getActions()
    if (!actions) return null

    const replyingAction = actions[Actions.REPLYING] as ReplyingAction
    if (!replyingAction) return null

    return replyingAction.getReplyingContent()
  }
}
