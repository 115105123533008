import { getRegularTools, getUndeliveredTools, ToolsType, Tool } from '@/components/Chat/Instance/DOM/Components/Tools/Models'

export const getHandlers = (type: ToolsType): Array<Tool> | null => {
  switch (type) {
    case ToolsType.REGULAR: return getRegularTools()
    case ToolsType.UNDELIVERED: return getUndeliveredTools()
    default: return null
  }
}

export const getProperTools = (model: TADA.Message): ToolsType => {
  const { content, state } = model
  if (content.type === TADA.MessageType.CHANGE) return ToolsType.NONE

  switch (state) {
    case TADA.MessageState.NORMAL: return ToolsType.REGULAR
    case TADA.MessageState.SENDING_ERROR: return ToolsType.UNDELIVERED
    default: return ToolsType.NONE
  }
}
