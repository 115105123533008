import Tooltip, { ATTRIBUTE_TOOLTIP_DESCRIPTION } from '@/components/UI/Popouts/Tooltip'

const CONTEXT = '@@Tooltip'

export const setDescription = (element: HTMLElement, value: string) => {
  element.setAttribute(ATTRIBUTE_TOOLTIP_DESCRIPTION, value)
}

const getTooltip = (element: HTMLElement): Tooltip | null => {
  return (element as any)[CONTEXT]
}

const setupTooltip = (element: HTMLElement, parameters: any): Tooltip => {
  (element as any)[CONTEXT] = new Tooltip(parameters)
  return (element as any)[CONTEXT]
}

export const detachTooltip = (element: HTMLElement, cleanup?: boolean) => {
  const tooltip = getTooltip(element)
  if (!tooltip) return

  tooltip.hide(true as any)
  cleanup && tooltip.cleanup()
}

export const attachTooltip = ({ element, content, shared = true, cacheable = true, maxWidth }: { element: HTMLElement; content?: string; cacheable?: boolean; shared?: boolean; maxWidth?: number }) => {
  if (!element) return

  element.addEventListener('mouseenter', () => {
    const tooltip = getTooltip(element) || setupTooltip(element, {
      anchor: element,
      content: content,
      shared,
      local: true,
      cacheable,
      maxWidth,
    })

    tooltip.show()
  })

  element.addEventListener('mouseleave', () => {
    const tooltip = getTooltip(element)
    if (!tooltip) return

    tooltip.hide()
  })
}

export const attachTooltipScope = ({ element, maxWidth, dynamicContent }: { element: HTMLElement; maxWidth?: number; dynamicContent?: boolean }) => {
  if (!element) return

  const handler = function () {
    for (let index = 0; index < element.children.length; index++) {
      const child = element.children[index]
      const description = child.getAttribute(ATTRIBUTE_TOOLTIP_DESCRIPTION)
      if (!description) continue

      attachTooltip({ element: child as HTMLElement, maxWidth, content: dynamicContent ? undefined : description, cacheable: !dynamicContent })
    }
    element.removeEventListener('mouseenter', handler)
  }
  element.addEventListener('mouseenter', handler)
}
