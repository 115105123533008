import DayController from '@/components/Chat/Instance/Controllers/DayController'
import { createForInsertion, update, insertMessage, extractMessage, MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import {
  createAttachedSenderData,
  ATTACHMENT_CONTAINER_CLASS_NAME,
  ATTACHMENT_SENDER_DATA_CLASS_NAME,
  ATTACHMENT_CLASS_NAME,
} from '@/components/Chat/Instance/DOM/Components/Attachments'
import { createSenderData, SENDER_DATA_CLASS_NAME } from '@/components/Chat/Instance/DOM/Components/SenderData'

import { flushElement } from '@/utils/DOM'
import { toStringMicroseconds } from '@/utils/Date'

import { Contact } from '@tada-team/tdproto-ts'

const ATTRIBUTE_CONTAINER_TYPE = 'data-messages-type'

const enum ContainerType {
  REGULAR = 'regular',
  UNDELIVERED = 'undelivered'
}

export default class {
  dayController: DayController

  regular: HTMLElement
  undelivered: HTMLElement | null

  constructor (root: HTMLElement, reverse?: boolean) {
    const regular = this.getContainer(root, ContainerType.REGULAR)
    if (!regular) throw new Error('[ChatTape.constructor] Regular container is not found')

    const undelivered = this.getContainer(root, ContainerType.UNDELIVERED)

    this.regular = regular as HTMLElement
    this.undelivered = undelivered as (HTMLElement | null)

    this.dayController = new DayController(this.regular, reverse)
  }

  reset () {
    this.dayController.recalculateDaysLabelsIfNeeded()
  }

  addMessage (message: TADA.Message, inverse?: boolean): HTMLElement {
    const isUndelivered = message.state !== TADA.MessageState.NORMAL
    inverse = inverse || !!message.replyTo
    const element = isUndelivered ? this.addUndeliveredMessage(message) : this.addRegularMessage(message, inverse)

    return element
  }

  updateMessage (message: TADA.Message, messageElement: HTMLElement): HTMLElement {
    const relocateMessage = this.prepareToRelocate(message, messageElement)
    relocateMessage && this.addRegularMessage(messageElement)

    update({
      model: message,
      element: messageElement,
    })

    return messageElement
  }

  removeMessage (messageElement: HTMLElement) {
    const isUndelivered = messageElement.getAttribute(MessageDOMAttribute.STATE) !== TADA.MessageState.NORMAL
    if (isUndelivered) {
      extractMessage({ element: messageElement })
      return
    }
    this.dayController.extract(messageElement)
  }

  flush () {
    this.undelivered && flushElement(this.undelivered)
    flushElement(this.regular)
  }

  getEdgeMessageId (
    first?: boolean,
    undelivered?: boolean,
    isOwn?: boolean,
    deleted = true,
  ): string | null {
    let element: HTMLElement | null = null
    if (undelivered && this.undelivered) {
      element = (first ? this.undelivered.firstChild : this.undelivered.lastChild) as HTMLElement
    }

    if (!element) {
      element = this.dayController.getEdgeMessageElement(first, isOwn, deleted)
    }

    return element ? element.getAttribute(MessageDOMAttribute.ID) : null
  }

  unselectAllMessages () {
    const elements = this.regular.getElementsByClassName('selected')
    for (let i = elements.length - 1; i >= 0; i--) {
      elements[i].classList.remove('selected')
    }
  }

  updateContact (contact: Contact) {
    if (!contact) return

    const { jid } = contact

    const messageSelector = '.msg' +
      `[${MessageDOMAttribute.OWNER}="${jid}"]` +
      ':not(.collapsed)'
    const messages = this.regular.querySelectorAll(messageSelector)
    messages.forEach(messageEl => {
      const dataElement = messageEl.querySelector(
        `.${SENDER_DATA_CLASS_NAME}`,
      )
      if (!dataElement) return

      const updatedSenderDataElement = createSenderData(
        messageEl as HTMLElement,
        contact,
      )
      dataElement.replaceWith(updatedSenderDataElement)
    })

    const attachmentSelector = '.msg' +
      `>.${ATTACHMENT_CONTAINER_CLASS_NAME}` +
      `>.${ATTACHMENT_CLASS_NAME}` +
      `[${MessageDOMAttribute.OWNER}="${jid}"]` +
      ':not(.collapsed)'
    const attachments = this.regular.querySelectorAll(attachmentSelector)
    attachments.forEach(attachmentEl => {
      const dataElement = attachmentEl.querySelector(
        `.${ATTACHMENT_SENDER_DATA_CLASS_NAME}`,
      )
      if (!dataElement) return

      const updatedSenderDataElement = createAttachedSenderData(contact)
      dataElement.replaceWith(updatedSenderDataElement)
    })
  }

  getMessagesElements (): Array<HTMLElement> {
    const query = '.msg[data-message-id]'
    let regularMessages: any = this.regular.querySelectorAll(query)
    let undeliveredMessages: any = this.undelivered && this.undelivered.querySelectorAll(query)

    regularMessages = Array.prototype.slice.call(regularMessages)
    undeliveredMessages = undeliveredMessages ? Array.prototype.slice.call(undeliveredMessages) : []

    return [...regularMessages, ...undeliveredMessages]
  }

  private prepareToRelocate (model: TADA.Message, element: HTMLElement): boolean {
    const { state, confirmed } = model
    const isNormal = state === TADA.MessageState.NORMAL
    if (!isNormal) return false

    const isWasUndelivered = element.getAttribute(MessageDOMAttribute.STATE) !== TADA.MessageState.NORMAL
    if (isWasUndelivered) return true

    const isWasConfirmed = !!element.getAttribute(MessageDOMAttribute.CONFIRMED)
    if (isWasConfirmed && !confirmed) {
      const timestamp = new Date(model.created).getTime()
      element.setAttribute(MessageDOMAttribute.TIMESTAMP, timestamp + '')
      element.setAttribute(MessageDOMAttribute.DATE, toStringMicroseconds(model.created))
      return true
    }

    return false
  }

  private getContainer (root: HTMLElement, type: ContainerType): Element | null {
    if (root.getAttribute(ATTRIBUTE_CONTAINER_TYPE) === type) return root

    return root.querySelector(`[${ATTRIBUTE_CONTAINER_TYPE}="${type}"]`)
  }

  private addRegularMessage (message: TADA.Message | HTMLElement, inverse?: boolean): HTMLElement {
    return this.dayController.put(message, inverse)
  }

  private addUndeliveredMessage (message: TADA.Message): HTMLElement {
    const container = this.undelivered
    if (!container) {
      throw new Error('[ChatTape.addUndeliveredMessage] Undelivered container is null')
    }

    if (container.children.length === 0) {
      const lastMessageElement = this.dayController.getEdgeMessageElement()
      const messageElement = createForInsertion(message, new Date(message.created), lastMessageElement)
      return container.appendChild(messageElement)
    }

    return insertMessage({ container, model: message })
  }
}
