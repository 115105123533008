import * as Sentry from '@sentry/browser'
import DOMUtils, { insertAfter } from '@/utils/DOM'

import ToolsEvents from '@/components/Chat/Instance/DOM/Components/Tools/Events'

import { createFileContent } from '@/components/Chat/Instance/DOM/Components/Content/File'
import { createPlainContent } from '@/components/Chat/Instance/DOM/Components/Content/Plain'
import { createImageContent } from '@/components/Chat/Instance/DOM/Components/Content/Image'
import { createVideoContent } from '@/components/Chat/Instance/DOM/Components/Content/Video'
import { createChangeContent } from '@/components/Chat/Instance/DOM/Components/Content/Change'
import { createAudioMsgContent } from '@/components/Chat/Instance/DOM/Components/Content/AudioMsg'
import { prepareUploads } from '@/components/Chat/Instance/DOM/Components/Content/Utils'
import { isMessageEditable } from '@/utils'
import { goalChatOneMessageActions } from '@/analytics'

export const CONTENT_CLASS_NAME = 'message-content'
export const ATTRIBUTE_CONTENT_TYPE = 'data-content-type'
export const ATTRIBUTE_CONTENT_SUBTYPE = 'data-content-subtype'

const enum UploadType {
  IMAGE = 'image',
  VIDEO = 'video'
}

const appendContentByType = (contentElement: HTMLElement, message: TADA.Message, maxMediaHeight?: number) => {
  const { content, sender } = message
  switch (content.type) {
    case TADA.MessageType.IMAGE: {
      const element = createImageContent(content as TADA.MessageContentImage, maxMediaHeight)
      contentElement.appendChild(element)
      break
    }
    case TADA.MessageType.VIDEO: {
      const element = createVideoContent(content as TADA.MessageContentVideo, maxMediaHeight)
      contentElement.appendChild(element)
      break
    }
    case TADA.MessageType.FILE:
    case TADA.MessageType.AUDIO: {
      const element = createFileContent(content as TADA.MessageContentFile, message.sender)
      contentElement.appendChild(element)
      break
    }
    case TADA.MessageType.AUDIOMSG: {
      const element = createAudioMsgContent(content as TADA.MessageContentAudioMsg)
      contentElement.appendChild(element)
      break
    }
    case TADA.MessageType.CHANGE: {
      createChangeContent(contentElement, message)
      break
    }
    case TADA.MessageType.PLAIN:
    default: {
      if (!content || (!content.text && content.text !== '')) {
        Sentry.withScope(scope => {
          scope.setExtra('msg', JSON.stringify(message))
          Sentry.captureMessage('Message without text content')
        })
      }
      createPlainContent(contentElement, sender, message)
      break
    }
  }

  const images: TADA.Upload[] = []
  const other: TADA.Upload[] = []
  message.uploads.forEach(u => {
    const ct = u.contentType?.split('/')[0]
    ct === UploadType.IMAGE
      ? images.push(u)
      : other.push(u)
  })

  const uploads = [...images, ...other]

  const preparedUploads = prepareUploads(uploads)
  preparedUploads && preparedUploads.forEach(upload => {
    if (upload.type === TADA.MessageType.IMAGE) {
      contentElement.appendChild(createImageContent(upload, maxMediaHeight))
    } else if (upload.type === TADA.MessageType.VIDEO) {
      contentElement.appendChild(createVideoContent(upload as TADA.MessageContentVideo, maxMediaHeight))
    } else {
      contentElement.appendChild(createFileContent(upload, message.sender))
    }
  })
}

export const createContent = (message: TADA.Message, maxMediaHeight?: number): HTMLElement => {
  const classname = message.content.type === 'change' ? `${CONTENT_CLASS_NAME} message-content-left` : CONTENT_CLASS_NAME

  const attributes = message.content.subtype ? {
    class: classname,
    [ATTRIBUTE_CONTENT_TYPE]: message.content.type,
    [ATTRIBUTE_CONTENT_SUBTYPE]: message.content.subtype,
  } : {
    class: classname,
    [ATTRIBUTE_CONTENT_TYPE]: message.content.type,
  }

  const contentElement = DOMUtils.createElement('div', attributes)

  appendContentByType(contentElement, message, maxMediaHeight)

  const isSameElement = (el: EventTarget | null) => el === contentElement

  contentElement.addEventListener('dblclick', (e: Event) => {
    if (!isSameElement(e.target)) return
    const parentNode = contentElement.parentNode
    if (!parentNode || !(parentNode instanceof HTMLElement)) return
    goalChatOneMessageActions.replyDblclick()
    ToolsEvents.reply(parentNode)
  })

  if (isMessageEditable(message)) {
    contentElement.addEventListener('webkitmouseforceup', (e: Event) => {
      if (!isSameElement(e.target)) return
      const parentNode = contentElement.parentNode
      if (!parentNode || !(parentNode instanceof HTMLElement)) return
      ToolsEvents.edit(parentNode)
    })
  }

  return contentElement
}

export const isContentElement = (element: HTMLElement): boolean => {
  return !!element.getAttribute(ATTRIBUTE_CONTENT_TYPE)
}

export const updateContent = (element: HTMLElement, model: TADA.Message | null) => {
  if (model === null) return

  const newContentElement = createContent(model)
  const isContentFirst = !model.replyTo

  // если вы сюда когда-то попадете, помните,
  // что у нас рекурсивное отображение с одинаковыми классами - ищите только в скопах текущего элемента
  const contentElement = element.querySelector(`:scope > .${CONTENT_CLASS_NAME}`)
  const isContentValid = contentElement && isContentElement(contentElement as HTMLElement)

  if (isContentValid) {
    (contentElement as HTMLElement).replaceWith(newContentElement)
    return
  }

  const child = isContentFirst ? element.firstElementChild as HTMLElement : element.lastElementChild as HTMLElement
  if (child === null) return element.appendChild(newContentElement)
  // Нигде не используется, а логику реплаев рушит.
  // if (!isSenderDataElement(child)) return prepend(element, newContentElement)
  insertAfter(newContentElement, child)
}
