import DOMUtils from '@/utils/DOM'
import { updateSenderData } from '@/components/Chat/Instance/DOM/Components/SenderData'
import router from '@/router'
import store, { teamsStore } from '@/store'
import * as actionTypes from '@/store/actionTypes'

export const CLASS_NAME = 'thread'
const FLAG_WRAPPER_CLASS_NAME = 'thread-wrapper'

const icon = '<svg width="16" height="16" viewBox="0 0 16 16" fill="#459DED" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.03691 11.2858C3.22383 11.1026 3.47512 11 3.73684 11H13C13.5523 11 14 10.5523 14 10V4C14 3.44772 13.5523 3 13 3H3C2.44772 3 2 3.44772 2 4V12.302L3.03691 11.2858ZM3.73684 12H13C14.1046 12 15 11.1046 15 10V4C15 2.89543 14.1046 2 13 2H3C1.89543 2 1 2.89543 1 4V13.5C1 13.943 1.53355 14.1672 1.84997 13.8571L3.73684 12Z"/></svg>'

const createThreadFlag = (count: number, threadId: string) => {
  const div = document.createElement('div')

  if (count) {
    div.innerHTML = count + icon
  } else {
    div.innerHTML = icon
  }

  div.classList.add('thread-counter')
  div.classList.add('td-body-regular-s')

  div.addEventListener('click', () => {
    store.dispatch(actionTypes.LOAD_CHAT, threadId)
    window.goal('messageCommentClickButton', {
      messageCommentClickButton: 'Кнопка перехода в существующий тред из чата',
      teamId: teamsStore.getters.currentTeam.uid,
      jid: threadId,
    })

    router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: threadId,
      },
    })
  })

  return DOMUtils.createElement('div', { class: FLAG_WRAPPER_CLASS_NAME }, div)
}

export const isThread = (element: HTMLElement) => {
  return element.classList.contains(CLASS_NAME)
}

export const toggleThreadAppearance = (element?: HTMLElement, value?: boolean, count?: number, threadId?: string) => {
  if (!element) return

  const isThreadNow = isThread(element)
  value = value === undefined ? !isThreadNow : value

  if (!isThreadNow) {
    element.classList.add(CLASS_NAME)

    const flagElement = createThreadFlag(count!, threadId!)
    element.appendChild(flagElement)
  }

  const el = element.getElementsByClassName('thread-counter')

  el[0].innerHTML = count + icon
}

export const updateThreadState = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  const threadId = model.threadJID!
  const thread = Boolean(threadId)
  if (!thread) {
    return
  }

  toggleThreadAppearance(element, true, model.threadMessagesCount, threadId)
  updateSenderData(element, model)
}
