//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// не знаю куда лучще это положить
import { createMessage } from '@/api/v3/DataGenerator'
import api from '@/api/v3'
import store from '@/store'
import * as actionTypes from '@/store/actionTypes'

export default {
  data: () => ({
    stickers: [],
  }),
  async mounted () {
    this.stickers = [].concat(...(await api.stickers()).map(p => p.stickers))
  },
  methods: {
    send (sticker) {
      const { message_content: content } = sticker
      const { getters, dispatch } = store

      const message = createMessage({ sender: getters.getUserId, recipient: getters.currentChat, text: '' })
      message.content = content
      dispatch(actionTypes.SOCKET_SEND_MESSAGE, { chatId: getters.currentChat, message })
    },
  },
}
