import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { prepend } from '@/utils/DOM'

export default {
  created () {
    this.loadingIndicatorStatus = 'none'
    this.historyBarDisplayed = false
    this.undeliveredContainerDisplayed = true

    window.FEATURES.is_testing && (window.debugAppearance = () => {
      console.warn('loadingIndicatorStatus', this.loadingIndicatorStatus, 'historyBarDisplayed', this.historyBarDisplayed, 'undeliveredContainerDisplayed', this.undeliveredContainerDisplayed)
    })

    this.loadingOldHandler = ({ chatId, status }) => {
      if (this.chatId !== chatId) return

      const { loadingIndicator } = this.$refs
      if (!loadingIndicator) return

      if (this.loadingIndicatorStatus === 'all') return

      if (this.loadingIndicatorStatus === 'old' || !status) {
        loadingIndicator.style.display = status ? 'block' : 'none'
        if (!status) this.loadingIndicatorStatus = 'none'
        return
      }

      const { container } = this.$refs
      if (!container) return

      prepend(container, loadingIndicator)
      loadingIndicator.style.display = 'block'

      this.loadingIndicatorStatus = 'old'
    }

    this.loadingNewHandler = ({ chatId, status }) => {
      if (this.chatId !== chatId) return

      const { loadingIndicator } = this.$refs
      if (!loadingIndicator) return

      if (this.loadingIndicatorStatus === 'all') return

      if (this.loadingIndicatorStatus === 'new' || !status) {
        loadingIndicator.style.display = status ? 'block' : 'none'
        if (!status) this.loadingIndicatorStatus = 'none'
        return
      }

      const { container } = this.$refs
      if (!container) return

      container.appendChild(loadingIndicator)
      loadingIndicator.style.display = 'block'

      this.loadingIndicatorStatus = 'new'
    }

    this.loadingAroundHandler = ({ chatId, status }) => {
      this.loadingOldHandler({ chatId, status })
      this.loadingNewHandler({ chatId, status })
    }

    this.appearanceHandlers = {
      [Events.SET_OLD_MESSAGES_LOADING_STATUS]: this.loadingOldHandler,
      [Events.SET_LAST_MESSAGES_LOADING_STATUS]: this.loadingOldHandler,
      [Events.SET_NEW_MESSAGES_LOADING_STATUS]: this.loadingNewHandler,
      [Events.SET_AROUND_MESSAGES_LOADING_STATUS]: this.loadingAroundHandler,
      [Events.SET_CHAT_LOADING_STATUS]: ({ status }) => {
        const { loadingIndicator } = this.$refs
        if (!loadingIndicator) return

        if (!status && this.loadingIndicatorStatus === 'none') return

        if (!status || this.loadingIndicatorStatus === 'all') {
          loadingIndicator.style.display = status ? 'block' : 'none'
          if (!status) {
            this.$el.classList.remove('loading')
            this.loadingIndicatorStatus = 'none'
          } else {
            this.$el.classList.add('loading')
          }
          return
        }

        this.$el.classList.add('loading')
        this.$el.appendChild(loadingIndicator)
        loadingIndicator.style.display = 'block'

        this.loadingIndicatorStatus = 'all'
      },
      [Events.TOGGLE_HISTORY_MODE]: ({ chatId, value }) => {
        if (this.chatId !== chatId) return

        this.toggleHistoryBar(value)
        this.toggleUndeliveredContainer(!value)
      },
      // Может лучше добавлять этот хенделр когда туглится historyMode?
      [Events.ADD_UNDELIVERED_MESSAGE]: ({ chatId }) => {
        if (this.chatId !== chatId || !this.historyBarDisplayed) return

        this.moveBottom && this.moveBottom()
      },
    }
  },
  mounted () {
    Object.keys(this.appearanceHandlers).forEach(eventName => {
      ChatEventBus.$on(eventName, this.appearanceHandlers[eventName])
    })
  },
  beforeDestroy () {
    Object.keys(this.appearanceHandlers).forEach(eventName => {
      ChatEventBus.$off(eventName, this.appearanceHandlers[eventName])
    })
  },
  methods: {
    flushAppearanceMixin () {
      ChatEventBus.$emit(Events.SET_CHAT_LOADING_STATUS, { status: false })
      this.toggleUndeliveredContainer(true)
      this.toggleHistoryBar(false)
    },
    toggleUndeliveredContainer (visible) {
      if (this.undeliveredContainerDisplayed === visible) return

      const { container } = this.$refs
      if (!container) return

      const undelivered = container.querySelector('[data-messages-type="undelivered"]')
      if (!undelivered) return

      if (visible) {
        undelivered.removeAttribute('style')
      } else {
        undelivered.style.display = 'none'
      }
      this.undeliveredContainerDisplayed = visible
    },
    toggleHistoryBar (value) {
      if (this.historyBarDisplayed === value) return

      const { historyBar } = this.$refs
      if (!historyBar) return

      historyBar.style.display = value ? 'block' : 'none'

      this.historyBarDisplayed = value
    },
  },
}
