import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { reproduce, ReproduceTarget } from '@/components/Chat/Instance/DOM/Constructor'
import { MessageObject } from '@/components/Chat/Instance/ChatInstance'

export const toggleMessageCollapsedState = (element: HTMLElement) => {
  reproduce({
    basis: element,
    target: ReproduceTarget.DATA,
    model: null,
  })
}

export const toggleMessageReceivedState = (object: MessageObject) => {
  const { element, model } = object

  reproduce({
    basis: element,
    target: ReproduceTarget.RECEIVED,
    model,
  })
}

export const toggleMessageImportantState = (object: MessageObject) => {
  const { element, model } = object

  reproduce({
    basis: element,
    target: ReproduceTarget.IMPORTANT,
    model,
  })
}

export const toggleMessageState = (object: MessageObject) => {
  const { element, model } = object
  const isUndelivered = element.getAttribute(MessageDOMAttribute.STATE) === TADA.MessageState.UNDELIVERED
  if (!isUndelivered) return

  reproduce({
    basis: element,
    model,
    target: [ReproduceTarget.STATE, ReproduceTarget.TOOLS],
  })
}

export const toggleMessageThreadState = (object: MessageObject) => {
  const { element, model } = object

  reproduce({
    basis: element,
    target: ReproduceTarget.THREAD,
    model,
  })
}

export const updateTools = (object: MessageObject) => {
  const { element, model } = object
  reproduce({
    basis: element,
    model,
    target: [ReproduceTarget.TOOLS],
  })
}

export const updateMessageReactions = (object: MessageObject) => {
  const { element, model } = object

  reproduce({
    basis: element,
    model,
    target: ReproduceTarget.REACTIONS,
  })
}

export const createMessage = (basis: HTMLElement, model: TADA.Message, fully?: boolean) => {
  const target = model.content.type === TADA.MessageType.DELETED || model.content.type === TADA.MessageType.NEW_TASK
    ? [ReproduceTarget.DELETED]
    : (fully ? ReproduceTarget.ALL : ReproduceTarget.ALL_NO_DATA)

  reproduce({ basis, model, target })
}

export const updateMessage = (basis: HTMLElement, model: TADA.Message) => {
  createMessage(basis, model, true)
}
