import i18n from '@/i18n'

import DOMUtils from '@/utils/DOM'
import store from '@/store'

import { getDayContainer } from '@/components/Chat/Instance/Controllers/DayController'
import ChatInstance, { MessageObject } from '@/components/Chat/Instance/ChatInstance'
import { toggleMessageCollapsedState } from '@/components/Chat/Instance/DOM/ConstructorAliases'
import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { CONTENT_CLASS_NAME, ATTRIBUTE_CONTENT_TYPE } from '@/components/Chat/Instance/DOM/Components/Content'

export const ID_UNREAD_DIVIDER = 'unread-divider'
const ATTRIBUTE_NEW_MESSAGES_CAPTION = 'data-new-messages'

export default class {
  private instance: ChatInstance
  private barElement?: HTMLElement

  constructor (instance: ChatInstance) {
    this.instance = instance

    this.barElement = this.createBarElement()
    this.show(false)
  }

  placeBar (chatId: string, object: MessageObject | null) {
    const { getters } = store
    const lastReadMessageId = getters.chatLastReadMessageId(chatId)

    object = object || this.instance.messages[lastReadMessageId]
    if (!object) return this.show(false)

    const { model, element } = object
    const { messageId, state } = model
    if (lastReadMessageId !== messageId || state !== TADA.MessageState.NORMAL) return this.show(false)

    const lastMessage = getters.chatLastMessage(chatId)
    const { messageId: lastMessageId } = lastMessage

    if (lastReadMessageId === lastMessageId) return this.show(false)

    const anchorElement = this.searchValidAnchorElement(element)
    if (!anchorElement) return this.show(false)

    const { parentElement } = anchorElement
    if (!parentElement) return

    if (this.barElement) {
      parentElement.insertBefore(this.barElement, anchorElement)
    }
    toggleMessageCollapsedState(anchorElement)

    this.show(true)
  }

  searchValidAnchorElement (lastReadMessageElement: HTMLElement): HTMLElement | null {
    const nextMessageElement = lastReadMessageElement.nextElementSibling as HTMLElement | null

    if (nextMessageElement) {
      const isValid = this.isValidMessageAnchor(nextMessageElement)
      return isValid ? nextMessageElement : this.searchValidAnchorElement(nextMessageElement)
    }

    const dayContainerElement = getDayContainer(lastReadMessageElement)
    if (!dayContainerElement) return null

    const nextDayContainerElement = dayContainerElement.nextElementSibling
    if (!nextDayContainerElement) return null

    const rosterElement = nextDayContainerElement.lastElementChild
    if (!rosterElement) return null

    const firstMessageElement = rosterElement.firstElementChild as HTMLElement | null
    if (!firstMessageElement) return null

    const isValid = this.isValidMessageAnchor(firstMessageElement)
    return isValid ? firstMessageElement : this.searchValidAnchorElement(firstMessageElement)
  }

  isValidMessageAnchor (anchor: Element): Element | null {
    const owner = anchor.getAttribute(MessageDOMAttribute.OWNER)
    if (!owner || owner === store.getters.getUserId) return null

    for (let i = 0; i < anchor.children.length; i++) {
      const child = anchor.children[i]
      if (child.className !== CONTENT_CLASS_NAME) continue

      const contentType = child.getAttribute(ATTRIBUTE_CONTENT_TYPE)
      if (contentType === TADA.MessageType.CHANGE) return null
    }

    return anchor
  }

  hideBar () {
    this.show(false)
  }

  destroy () {
    DOMUtils.removeElement(this.barElement)
    delete this.barElement
  }

  private show (value: boolean) {
    if (!this.barElement) return
    this.barElement.style.display = value ? 'block' : 'none'
  }

  private createBarElement (): HTMLElement {
    const element = DOMUtils.createElement('div', { id: ID_UNREAD_DIVIDER })
    element.setAttribute(ATTRIBUTE_NEW_MESSAGES_CAPTION, i18n.t('chattape.unreadMessages').toString())
    return element
  }
}
