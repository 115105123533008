import ScrollableArea from '../ScrollableArea'

export default {
  name: 'ScrollableAreaWrapper',
  functional: true,
  props: {
    enabled: {
      type: Boolean,
    },
  },
  render (h, context) {
    const { props, data, children } = context
    const { ref, attrs } = data

    if (!props.enabled) return children

    const { appearance, flex, blinking, castShadow, absolute } = attrs
    return (
      <ScrollableArea ref={ref} appearance={appearance} absolute={absolute} flex={flex} castShadow={castShadow} blinking={blinking}>
        {children}
      </ScrollableArea>
    )
  },
}
