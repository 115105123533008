import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import store from '@/store'
import * as actionTypes from '@/store/actionTypes'

const { dispatch } = store
export default class {
  sendLastReadTimeout = -1
  isInHistoryModeTemp = false

  toggleHistoryModeIfNeeded = (chatId: string, lastMessageIdFromDOM: string) => {
    const { getters } = store
    const lastMessage = getters.chatLastMessage(chatId)
    if (!lastMessage) return

    const { messageId: lastMessageId } = lastMessage
    const isInHistoryMode = lastMessageId !== lastMessageIdFromDOM
    if (this.isInHistoryModeTemp === isInHistoryMode) return

    this.isInHistoryModeTemp = isInHistoryMode
    ChatEventBus.$emit(Events.TOGGLE_HISTORY_MODE, { chatId, value: isInHistoryMode })
  }

  flush = () => {
    clearTimeout(this.sendLastReadTimeout)
    this.sendLastReadTimeout = -1
    this.isInHistoryModeTemp = false
  }

  readChat = (chatId: string, messageId?: string | null, immediate?: boolean) => {
    if (immediate) {
      dispatch(actionTypes.SOCKET_SEND_LAST_READ_MESSAGE, { chatId, messageId })
      return
    }

    const isTimerActive = this.sendLastReadTimeout >= 0
    if (isTimerActive) {
      dispatch(actionTypes.SOCKET_ON_LAST_READ_MESSAGE, { chatId, messageId })
      clearTimeout(this.sendLastReadTimeout)
    } else {
      dispatch(actionTypes.SOCKET_SEND_LAST_READ_MESSAGE, { chatId, messageId })
    }

    this.sendLastReadTimeout = setTimeout((isActive: boolean = isTimerActive) => {
      isActive && dispatch(actionTypes.SOCKET_SEND_LAST_READ_MESSAGE, { chatId, messageId })
      this.sendLastReadTimeout = -1
    }, 500)
  }
}
