//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { transformEntityName } from '@/utils'
import { contactsStore, teamsStore } from '@/store'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    openProfileOnClick: {
      type: Boolean,
      default: true,
    },
    withName: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    indicator: {
      type: String,
      default: null,
    },
  },
  computed: {
    img () {
      return contactsStore.getters.contactIcon(this.id)
    },
    name () {
      return contactsStore.getters.contactDisplayName(this.id)
    },
    nameEmoji () {
      return transformEntityName(this.name)
    },
    tooltip () {
      return this.withName ? false : { text: this.name, align: 'top', cacheable: false, options: { marginFromAnchor: 7 } }
    },
  },
  methods: {
    onClick (e) {
      e.preventDefault()

      if (this.openProfileOnClick) {
        const { getters } = this.$store
        if (this.id === getters.getUserId || !contactsStore.getters.contact(this.id)) {
          return
        }
        this.$router.push({
          name: 'Chat',
          params: {
            jid: this.id,
            teamId: teamsStore.getters.currentTeam.uid,
          },
        })
      } else {
        this.$emit('click', e)
      }
    },
  },
}
