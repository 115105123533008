//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAlignStyle, getArrowStyle } from './utils'

export default {
  name: 'PopupWrapper',
  props: {
    immediateInit: {
      type: Boolean,
      default: false,
    },
    alignBy: {
      type: String,
      default: 'window',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    framePadding: {
      type: Number,
      default: undefined,
    },
    arrow: Boolean,
    alignOffset: {
      type: Object,
      default: () => { return {} },
    },
    cleanup: Boolean,
    onToggle: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      init: false,
      isOpen: false,

      alignStyle: {},
      arrowStyle: null,
    }
  },
  created () {
    this.init = this.immediateInit

    this.handleWindowClick = e => {
      let { target } = e
      if (!target) return

      while (target) {
        if (target === this.$el) return

        target = target.parentNode
      }
      this.close()
    }
    this.onToggle && this.$watch(vm => vm.isOpen, this.onToggle)
  },
  beforeDestroy () {
    this.close()
  },
  methods: {
    open () {
      this.init = true

      if (this.isOpen) {
        this.close()
        return
      }

      this.alignStyle = getAlignStyle(this.$el, this.alignBy, this.alignOffset)
      this.framePadding !== undefined && Object.assign(this.alignStyle, { padding: `${this.framePadding}px` })

      this.arrow && this.determineArrowPointer()

      this.isOpen = true

      window.addEventListener('click', this.handleWindowClick)
    },
    determineArrowPointer () {
      if (this.arrowStyle) return

      const { trigger } = this.$refs
      if (!trigger) return

      const pointer = trigger.querySelector('[data-arrow-pointer]')
      if (!pointer) {
        this.arrowStyle = { display: 'none' }
        return
      }

      this.arrowStyle = getArrowStyle({
        pointer,
        trigger,
        alignBy: this.alignStyle.top ? 'bottom' : 'top',
        offset: this.alignOffset,
      })
    },
    close () {
      this.isOpen = false
      window.removeEventListener('click', this.handleWindowClick)

      if (this.cleanup) this.init = false
    },
  },
}
