import i18n from '@/i18n'

export default {
  created () {
    this.$taskDropLastDragTarget = null
  },
  mounted () {
    this.$taskDrop = {
      dragOverEvent: e => e.preventDefault(),
      dropEvent: e => {
        e.preventDefault()
        this.$taskDrop.drop(e)
      },
      dragEnterEvent: e => this.$taskDrop.dragEnter(e),
      dragLeaveEvent: e => this.$taskDrop.dragLeave(e),
      toggleDragEffect: on => {
        const { chatForm } = this.$refs
        if (!chatForm) return

        const textArea = chatForm.$el.querySelector('.textarea-box')
        if (!textArea) return

        if (on) {
          textArea.setAttribute('data-tip', i18n.t('chattape.dropTaskHint'))
          textArea.classList.add('drag-over-task')
          return
        }

        textArea.removeAttribute('data-tip')
        textArea.classList.remove('drag-over-task')
      },
      drop: event => {
        this.$taskDrop.toggleDragEffect(false)

        if (!event.dataTransfer.getData('tadateam/x-task')) return

        const { num } = JSON.parse(event.dataTransfer.getData('tadateam/x-task'))

        if (num) {
          const chatForm = this.$refs.chatForm
          chatForm && chatForm.insert(`#${num} `)
        }
      },
      containsTask: event => {
        if (event.dataTransfer.types) {
          for (let i = 0; i < event.dataTransfer.types.length; i++) {
            if (event.dataTransfer.types[i] === 'tadateam/x-task') {
              return true
            }
          }
        }
        return false
      },
      dragEnter: event => {
        this.$taskDropLastDragTarget = event.target
        this.$taskDrop.containsTask(event) && this.$taskDrop.toggleDragEffect(true)
      },
      dragLeave: event => {
        try {
          if (event.target !== this.$taskDropLastDragTarget) return
        } catch (e) {
          console.warn(`[TaskDrop err]: ${e}`)
        }
        this.$taskDrop.toggleDragEffect(false)
      },
    }

    this.$el.addEventListener('dragover', this.$taskDrop.dragOverEvent)
    this.$el.addEventListener('drop', this.$taskDrop.dropEvent)
    this.$el.addEventListener('dragenter', this.$taskDrop.dragEnterEvent)
    this.$el.addEventListener('dragleave', this.$taskDrop.dragLeaveEvent)
  },
  beforeDestroy () {
    this.$el.removeEventListener('dragover', this.$taskDrop.dragOverEvent)
    this.$el.removeEventListener('drop', this.$taskDrop.dropEvent)
    this.$el.removeEventListener('dragenter', this.$taskDrop.dragEnterEvent)
    this.$el.removeEventListener('dragleave', this.$taskDrop.dragLeaveEvent)
  },
}
