import store from '@/store'
import { SET_CURRENT_ACTIVE_MESSAGE_DATA } from '@/store/actionTypes'
import { calculateTopOffset } from '@/utils/DOM'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'

import Messages from '@/store/messages'
import { getDateWithDifference } from '@/api/v3/DataGenerator'

import { selectionClickHandler } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonEvents'
import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { defaultLogger, replyLogger } from '@/loggers'
import * as Sentry from '@sentry/browser'

const ID_ACTIVE_MARK = 'current-active-message-target'

export const clearCurrentActiveMessageTarget = () => {
  defaultLogger.info('Clearing current active message target...')
  const { dispatch, getters } = store
  const data = getters.getCurrentActiveMessageData
  if (!data) return

  const clearTarget = (id?: string | null) => {
    const targetElement = document.getElementById(id ? `${ID_ACTIVE_MARK}-${id}` : ID_ACTIVE_MARK)
    if (!targetElement) return

    targetElement.removeAttribute('id')
    selectionClickHandler(targetElement, false)
  }

  clearTarget()

  let messageIds: string[] = data.messageId
  messageIds = Array.isArray(messageIds) ? messageIds : [messageIds]
  messageIds.forEach(clearTarget)

  dispatch(SET_CURRENT_ACTIVE_MESSAGE_DATA, null)
}

export const toggleActiveMessageAppearence = () => {
  const { getters } = store
  const data = getters.getCurrentActiveMessageData
  if (!data) return

  const toggleTarget = (id?: string | null, index?: number) => {
    const targetElement = document.getElementById(id ? `${ID_ACTIVE_MARK}-${id}` : ID_ACTIVE_MARK)
    if (!targetElement) return

    const selected = selectionClickHandler(targetElement)
    if (!selected) return

    if (index === 0) {
      const offset = calculateTopOffset(targetElement) - 200
      ChatEventBus.$emit(Events.SCROLL_CHAT, { offset })
    }
  }

  toggleTarget(null, -1)

  let messageIds: string[] = data.messageId
  messageIds = Array.isArray(messageIds) ? messageIds : [messageIds]
  messageIds.forEach(toggleTarget)
}

export const startEditingMessage = (chatId: string, element: HTMLElement) => {
  defaultLogger.info('startEditingMessage called with', { chatId, element })
  clearCurrentActiveMessageTarget()

  const messageId = element.getAttribute(MessageDOMAttribute.ID)
  if (!messageId) return

  const model = Messages.getMessage(chatId, messageId)
  if (!model) return

  const { editableUntil } = model
  if (editableUntil && getDateWithDifference('iso') > editableUntil) return

  element.setAttribute('id', ID_ACTIVE_MARK)

  const { dispatch } = store
  dispatch(SET_CURRENT_ACTIVE_MESSAGE_DATA, { messageId, goal: 'edit' })
}

export const startReplyingMessage = (chatId: string, elements: HTMLElement[]) => {
  clearCurrentActiveMessageTarget()

  replyLogger.info('startReplyingMessage: chatId', chatId)

  const ids = elements
    .map(element => {
      const messageId = element.getAttribute(MessageDOMAttribute.ID)
      if (!messageId) {
        replyLogger.info('startReplyingMessage: not found message id for', element)
        Sentry.withScope(scope => {
          scope.setLevel(Sentry.Severity.Error)
          scope.setExtra('messageId', JSON.stringify(element))
          Sentry.captureMessage('TOGGLE_MESSAGE_ACTIVE: not found element id')
        })
        return null
      }

      element.setAttribute('id', `${ID_ACTIVE_MARK}-${messageId}`)

      return messageId
    })
    .filter(messageId => messageId !== null)

  if (elements.length !== ids.length) {
    replyLogger.info('startReplyingMessage:', elements.length, ids.length)
  }

  const { dispatch } = store
  dispatch(SET_CURRENT_ACTIVE_MESSAGE_DATA, { messageId: ids, goal: 'reply' })
}
