import Quill, { RangeStatic } from 'quill'

import store from '@/store'
import * as actionTypes from '@/store/actionTypes'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'

import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'

export default class {
  private chatId: string
  private timeout: number | null

  constructor (public quill: Quill, options: any) {
    this.chatId = options.chatId
    this.timeout = null

    this.quill.on('text-change', () => { Utils.isEmpty(this.quill) ? this.stopComposing() : this.keepComposing() })
    this.quill.on('selection-change', (range: RangeStatic) => { !range && this.stopComposing() })

    EventBus.$on(EventTypes.SET_CHAT_ID, (chatId: string) => {
      this.stopComposing()
      this.chatId = chatId
    })

    EventBus.$on(EventTypes.BEFORE_DESTROY, this.stopComposing)
    EventBus.$on(EventTypes.COMMIT_MESSAGE, this.stopComposing)
  }

  private keepComposing = () => {
    if (this.timeout !== null) return

    this.dispatchComposingState(true)
    this.timeout = window.setTimeout(this.stopComposing, 30 * 1000)
  }

  private stopComposing = () => {
    if (this.timeout === null) return

    clearTimeout(this.timeout)
    this.timeout = null

    this.dispatchComposingState(false)
  }

  private dispatchComposingState = (value: boolean) => {
    const { dispatch } = store
    dispatch(actionTypes.SOCKET_SEND_MESSAGE_COMPOSING, { chatId: this.chatId, composing: value })
  }
}
