import DOMUtils, { searchAncestorByAttribute } from '@/utils/DOM'
import { attachTooltipScope, setDescription } from '@/components/UI/Popouts/TooltipController'

import { closeActivePopout } from '@/components/UI/Popouts/Popout'
import { executeAtPopout } from '@/components/UI/Popouts/Utils'
import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { getMessageElement } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import * as Utils from '@/components/Chat/Instance/DOM/Components/Tools/Utils'
import { Tool, debugTool, MAX_SHORTCUT_BUTTONS } from '@/components/Chat/Instance/DOM/Components/Tools/Models'
import { applyPopoutTool } from '@/components/Chat/Instance/DOM/Components/Tools/Popouts'
import ToolsEvents from '@/components/Chat/Instance/DOM/Components/Tools/Events'
import { goalChatOneMessageActions } from '@/analytics'

// import { defaultLogger } from '@/loggers'

const TOOLS_CLASS_NAME = 'tools'
const TOOLS_BUTTON_CLASS_NAME = 'tool-sign'
const ATTRIBUTE_TOOLS_TYPE = 'data-type'
const ATTRIBUTE_TOOL_ACTION = 'data-action'

const applyAppearance = (element: HTMLElement, tool: Tool) => {
  const { icon, fa, size, offsetX } = tool

  if (fa) {
    element.classList.add(...fa.split(' '))
    size && (element.style.fontSize = `${size}px`)
    return
  }

  element.classList.add('icon')
  element.style.backgroundImage = `url(/assets/i/${icon}.svg)`
  size && (element.style.backgroundSize = `${size}px`)
  offsetX && (element.style.backgroundPositionX = `${offsetX}px`)
}

const createSignElement = (tool: Tool): HTMLElement => {
  const { icon, fa, description } = tool

  const isIconSign = icon || fa

  const buttonElement = DOMUtils.createElement('div', {
    class: `${TOOLS_BUTTON_CLASS_NAME}`,
  })

  if (isIconSign) {
    applyAppearance(buttonElement, tool)
  } else {
    buttonElement.classList.add('text')
    buttonElement.setAttribute('data-caption', description || 'Empty')
  }

  isIconSign
    ? applyAppearance(buttonElement, tool)
    : buttonElement.classList.add('text')

  return buttonElement
}

const buttonClickHandler: GlobalEventHandlers['onclick'] = (e: MouseEvent) => {
  closeActivePopout()
  e.stopPropagation()

  const buttonElement = searchAncestorByAttribute(e.target as Node, ATTRIBUTE_TOOL_ACTION) as HTMLElement | null
  if (!buttonElement) return

  const eventName = buttonElement.getAttribute(ATTRIBUTE_TOOL_ACTION)
  if (!eventName) return

  const event = ToolsEvents[eventName]
  if (!event) return

  const messageElement = getMessageElement(e)
  if (!messageElement) return

  event(messageElement)
}

const applyActionAttribute = (
  element: HTMLElement,
  tool: Tool,
  handler?: GlobalEventHandlers['onclick'],
) => {
  const { event, popoutId, icon } = tool

  if (popoutId) return

  const eventName = event || icon
  eventName && element.setAttribute(ATTRIBUTE_TOOL_ACTION, eventName)

  handler = handler || buttonClickHandler
  handler && element.addEventListener('click', handler)
}

const createButton = (tool: Tool): HTMLElement => {
  const { popoutId, description, icon, fa, onClick } = tool

  const element = createSignElement(tool)
  onClick && element.addEventListener('click', onClick)
  description && (icon || fa) && setDescription(element, description)

  popoutId
    ? applyPopoutTool(element, popoutId)
    : applyActionAttribute(element, tool)

  return element
}

const applyEllipsisPopout = (buttonElement: HTMLElement, tools: Array<Tool>) => {
  applyPopoutTool(buttonElement, 'tool-ellipsis', () => {
    const optionElements = tools.map(tool => {
      const { description, onClick } = tool

      const signElement = createSignElement(tool)
      const element = DOMUtils.createElement('li', { class: 'option' }, signElement)
      onClick && element.addEventListener('click', onClick)
      setDescription(element, description || '')
      applyActionAttribute(element, tool, e => {
        executeAtPopout(e, buttonClickHandler)
      })

      return element
    })
    return DOMUtils.createElement('ul', { class: 'o-no-default-ul popup-menu' }, ...optionElements)
  })
}

const createEllipsisButton = (tools: Array<Tool>): HTMLElement => {
  window.FEATURES.is_testing && tools.push(debugTool)

  const buttonElement = DOMUtils.createElement('div', { class: `${TOOLS_BUTTON_CLASS_NAME} icon` })

  applyAppearance(buttonElement, { fa: 'fas fa-ellipsis-h', event: 'ellipsis-h' })
  applyEllipsisPopout(buttonElement, tools)

  return buttonElement
}

const createTools = (model: TADA.Message, tools: Array<Tool> | null): HTMLElement => {
  const element = DOMUtils.createElement('div', {
    class: TOOLS_CLASS_NAME,
  })

  if (!tools) {
    const debugButton = createButton(debugTool)
    element.appendChild(debugButton)

    return element
  }

  const list = tools.filter(tool => {
    const { condition } = tool
    return !condition || condition(model)
  })

  const overflow = list.some((tool, index) => {
    const button = createButton(tool)
    element.appendChild(button)

    return index === MAX_SHORTCUT_BUTTONS - 1 && (list.length - 1) - index > 1
  })

  if (overflow) {
    const button = createEllipsisButton(list.slice(MAX_SHORTCUT_BUTTONS))
    button.addEventListener('click', () => {
      goalChatOneMessageActions.ellipsisClick()
    })
    element.appendChild(button)
  } else if (window.FEATURES.is_testing) {
    const button = createButton(debugTool)
    element.appendChild(button)
  }

  attachTooltipScope({ element })

  return element
}

export const updateTools = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  const type = Utils.getProperTools(model)
  const handlers = Utils.getHandlers(type)
  if (!handlers && !window.FEATURES.is_testing) return

  const currentToolsElement = element.querySelector(`.${TOOLS_CLASS_NAME}`)
  if (currentToolsElement &&
    currentToolsElement.getAttribute(ATTRIBUTE_TOOLS_TYPE) === type &&
    element.hasAttribute(MessageDOMAttribute.HAS_PREVIEWS) === model.hasPreviews) return

  const toolsElement = createTools(model, handlers)
  toolsElement.setAttribute(ATTRIBUTE_TOOLS_TYPE, type)

  currentToolsElement
    ? currentToolsElement.replaceWith(toolsElement)
    : element.appendChild(toolsElement)
}
