//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { isEscape } from '@/utils/KeyUtils'
import { mapGetters } from 'vuex'
import { canMarkMessageImportant, isMessageEditable } from '@/utils'
import Messages from '@/store/messages'
import { goalChatMultiMessageActions as goal, goalTaskControls } from '@/analytics'
import { teamsStore, uiStore } from '@/store'

export default {
  name: 'SelectedMessages',
  components: {
    IconArrowRight: () => import('@/components/UI/icons/IconArrowRight'),
    IconCopy: () => import('@/components/UI/icons/IconCopy'),
    IconBookmark: () => import('@tada/icons/dist/IconBookmark.vue'),
    IconReply: () => import('@/components/UI/icons/IconReply'),
    IconForwardHere: () => import('@/components/UI/icons/IconForwardHere'),
    IconTimes: () => import('@/components/UI/icons/IconTimes'),
    IconTrash: () => import('@/components/UI/icons/IconTrash'),
    IconCalendar: () => import('@tada/icons/dist/IconCalendar.vue'),
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  mounted () {
    this.keydownHandler = event => {
      event.stopImmediatePropagation()

      if (!isEscape(event)) return

      uiStore.actions.clearSelectedMessages()
    }
    window.addEventListener('keydown', this.keydownHandler)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keydownHandler)
  },
  computed: {
    ...mapGetters([
      'entity',
      'getUserId',
      'profile',
    ]),
    list () {
      return ([...this.data] || []).sort(this.sortPredicate)
    },
    showForwardAction () {
      return !teamsStore.getters.isSingleGroupTeam()
    },
    showTaskAction () {
      return !teamsStore.getters.isSingleGroupTeam()
    },
    showMeetingAction () {
      return !teamsStore.getters.isSingleGroupTeam() && !!teamsStore.getters.currentTeam.me.canCreateMeeting
    },
    showImportantAction () {
      return this.list.every(messageId => {
        const message = Messages.getMessage(this.chatId, messageId)
        return message && canMarkMessageImportant(message) && !this.isFirstInThread
      })
    },
    showReplyAction () {
      return (this.entity(this.chatId) || {}).canSendMessage
    },
    showDeleteAction () {
      return !this.isFirstInThread
    },
    maxForwardMessages () {
      return window.FEATURES.max_linked_messages
    },
    canForwardMessages () {
      const messages = this.list.map(messageId => Messages.getMessage(this.chatId, messageId) || {})

      const count = messages.reduce(
        (sum, m) => sum + m.linkedMessages?.length + (m.content && m.content.text ? 1 : 0),
        0,
      )

      return count <= this.maxForwardMessages
    },
    canDelete () {
      const chat = this.$store.getters.chat(this.chatId)
      if (chat && chat.canDeleteAnyMessage) return true

      return this.list.every(messageId => {
        const message = Messages.getMessage(this.chatId, messageId)
        return isMessageEditable(message)
      })
    },
    isFirstInThread () {
      return this.list.some(messageId => {
        const message = Messages.getMessage(this.chatId, messageId)
        return message.isFirst && message.chatType === 'thread'
      })
    },
  },
  methods: {
    sortPredicate (a, b) {
      const messageA = Messages.getMessage(this.chatId, a)
      if (!messageA) return -1

      const messageB = Messages.getMessage(this.chatId, b)
      if (!messageB) return 1

      const { created: createdA } = messageA
      const { created: createdB } = messageB

      return createdA > createdB ? 1 : createdA < createdB ? -1 : 0
    },
    handle (handle) {
      switch (handle) {
        case 'task': goalTaskControls.createTaskByMessagesClick(); break
        case 'meeting': goal.meetingClick(); break
        case 'important': goal.toggleImportantClick(); break
        case 'reply': (this.data.length === 1) ? goal.replyClick() : goal.forwardHereClick(); break
        case 'copy': goal.copyClick(); break
        case 'forward':
          goal.forwardClick()
          if (!this.canForwardMessages) return
          break
        case 'delete':
          goal.deleteClick()
          if (!this.canDelete) return
          break
      }

      // TODO: remove event bus on refactor
      ChatEventBus.$emit(Events.HANDLE_ALL_SELECTED, { handle, orderIds: [...this.list] })
    },
    unselectAll () {
      goal.unselectAllClick()
      uiStore.actions.clearSelectedMessages()
    },
    messagesCaption (count) {
      return this.$tc('glossary.message_count', count)
    },
  },
}
