import i18n from '@/i18n'

import DOMUtils, { measureHeight } from '@/utils/DOM'
import * as Image from './Image'
import * as Attachment from '../Attachments'
import { getMaxMessageContentWidth } from '@/components/Chat/Instance/Hooks/Utils'
import { DefaultNodeResolver, NodeFactory, toHTML } from './Markup'
import { parseEmoji } from '@/utils'
import { date as quasarDate } from 'quasar'

const { formatDate } = quasarDate

export const enum UploadType {
  IMAGE = 'image',
  VIDEO = 'video'
}

export const recalculateMediaSize = (messageElement: HTMLElement | null) => {
  if (!messageElement) return

  const imageElements = messageElement.querySelectorAll('.' + Image.IMAGE_CLASS_NAME)
  if (!imageElements || imageElements.length === 0) return

  for (let i = 0; i < imageElements.length; i++) {
    const imageElement = imageElements[i] as HTMLElement | null
    if (!imageElement) continue

    const previewWidth = imageElement.getAttribute(Image.PREVIEW_WIDTH_ATTRIBUTE)
    const previewHeight = imageElement.getAttribute(Image.PREVIEW_HEIGHT_ATTRIBUTE)
    if (!previewWidth || !previewHeight) continue

    // 300 - Default image/gif
    // 250 - Video preview
    // 200 - Attachment media
    const maxImageHeight = +(imageElement.getAttribute(Image.HEIGHT_LIMIT_ATTRIBUTE) || Image.IMAGE_HEIGHT_LIMIT)
    let maxImageWidth = getMaxMessageContentWidth()

    if (maxImageHeight === Attachment.ATTACHMENT_MEDIA_HEIGHT_LIMIT) {
      maxImageWidth -= Attachment.ATTACHMENT_MEDIA_WIDTH_DIFF
    }

    Image.setupDimensions({
      element: imageElement,
      previewWidth: +previewWidth || imageElement.clientWidth,
      previewHeight: +previewHeight || imageElement.clientHeight,
      maxImageHeight,
      maxImageWidth,
    })
  }
}

export const collapseLongTextBlock = (element: HTMLElement) => {
  const preElements = element.getElementsByClassName('pre')

  for (let i = 0; i < preElements.length; i++) {
    const preElement = preElements[i] as HTMLElement
    if (!preElement) return

    const height = measureHeight(preElement, '.msg')

    if (height > 190) {
      preElement.classList.add('collapsed')

      const toggleButtonElm = DOMUtils.createElement('button',
        {
          class: 'toggle-button o-button primary',
        },
        i18n.t('chattape.textBlockExpand').toString(),
      )
      toggleButtonElm.addEventListener('click', e => {
        e.stopPropagation()

        const { parentNode } = e.target as HTMLElement

        const { classList } = parentNode as HTMLElement
        const isCollapsed = classList.contains('collapsed')

        classList.remove(isCollapsed ? 'collapsed' : 'expanded')
        classList.add(isCollapsed ? 'expanded' : 'collapsed')

        toggleButtonElm.textContent = (isCollapsed
          ? i18n.t('chattape.textBlockCollapse')
          : i18n.t('chattape.textBlockExpand')
        ).toString()
      })
      preElement.appendChild(toggleButtonElm)
    }
  }
}

/**
 * Prepares an array of uploads to the desired format
 * @param uploads array uploads
 */
export const prepareUploads = (uploads: TADA.Upload[]) => {
  const resultUploads: Array<TADA.MessageContentImage | TADA.MessageContentFile | TADA.MessageContentVideo> = []
  uploads && uploads.forEach(u => {
    const ct = u.contentType?.split('/')[0]
    if (ct === UploadType.IMAGE && !!u.preview) {
      resultUploads.push({
        preview2xURL: u.preview.url2x,
        previewHeight: u.preview.height,
        previewURL: u.preview.url,
        previewWidth: u.preview.width,
        mediaURL: u.url,
        name: u.name,
        size: u.size,
        processing: u.processing,
        text: '',
        type: TADA.MessageType.IMAGE,
      })
    } else if (ct === UploadType.VIDEO && !!u.preview && !u.processing) {
      resultUploads.push({
        preview2xURL: u.preview.url2x,
        previewHeight: u.preview.height,
        previewURL: u.preview.url,
        previewWidth: u.preview.width,
        mediaURL: u.url,
        name: u.name,
        size: u.size,
        processing: u.processing,
        text: '',
        type: TADA.MessageType.VIDEO,
      })
    } else {
      resultUploads.push({
        mediaURL: u.url,
        name: u.name,
        size: u.size,
        processing: u.processing,
        text: '',
        type: TADA.MessageType.FILE,
      })
    }
  })
  return resultUploads
}

export const messageToHTML = (message: TADA.Message) => {
  const defaultResolver: DefaultNodeResolver = ({ text }) => {
    const el = document.createElement('span')
    if (text !== undefined) el.innerText = text
    return el
  }

  const factory: NodeFactory = {
    bold: ({ text }) => {
      const el = document.createElement('span')
      if (text !== undefined) el.innerText = text
      el.classList.add('bold')
      return el
    },
    code: ({ text }) => {
      const el = document.createElement('code')
      if (text !== undefined) el.innerText = text
      return el
    },
    codeblock: ({ text }) => {
      const el = document.createElement('div')
      if (text !== undefined) el.innerText = text
      el.classList.add('pre')
      return el
    },
    italic: ({ text }) => {
      const el = document.createElement('span')
      if (text !== undefined) el.innerText = text
      el.classList.add('italic')
      return el
    },
    link: ({ text, me }) => {
      const el = document.createElement('a')
      if (text !== undefined) el.innerText = text
      el.target = '_blank'
      el.setAttribute('data-local', 'true')
      if (me.url !== undefined) el.href = me.url
      return el
    },
    quote: ({ text }) => {
      const el = document.createElement('span')
      if (text !== undefined) el.innerText = text
      el.classList.add('quote')
      return el
    },
    strike: ({ text }) => {
      const el = document.createElement('span')
      if (text !== undefined) el.innerText = text
      el.classList.add('strike')
      return el
    },
    time: ({ text, me }) => {
      const el = document.createElement('span')
      if (me.time !== undefined) el.innerText = formatDate(me.time, 'DD.MM.YYYY, H:mm')
      else if (text !== undefined) el.innerText = text
      return el
    },
    underscore: ({ text }) => {
      const el = document.createElement('span')
      if (text !== undefined) el.innerText = text
      el.classList.add('underline')
      return el
    },
    unsafe: defaultResolver,
    default: defaultResolver,
  }

  let html = toHTML(message.content.text, message.markup, factory)
  html = parseEmoji(html)

  return html
}
