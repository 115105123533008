import Quill from 'quill'
import store, { draftsStore } from '@/store'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'
import { Draft } from '@/store/modules/drafts/types'

export default class {
  chatId = ''
  lastRevision = 0
  unwatch = () => {}

  constructor (public quill: Quill) {
    quill.on('text-change', this.onTextChange)
    EventBus.$on(EventTypes.SET_CHAT_ID, this.onChatIdChange)
    EventBus.$on(EventTypes.COMMIT_MESSAGE, this.onTextCommit)
    EventBus.$on(EventTypes.BEFORE_DESTROY, this.destroy)
  }

  private onChatIdChange = async (chatId: string) => {
    this.destroy()

    this.chatId = chatId
    this.unwatch = store.watch(() => draftsStore.state.data[chatId], async draft => {
      if (!draft || draft.revision <= this.lastRevision) return
      await this.renderDraft(draft)
    })

    const draft = draftsStore.state.data[this.chatId]
    if (draft) await this.renderDraft(draft)
  }

  private onTextChange = async () => {
    const chatId = this.chatId
    if (!chatId) return

    const context = this.getContext()
    if (context === undefined) return

    const draft = draftsStore.state.data[chatId]
    if (draft && draft.context === context) return

    const revision = Date.now()
    this.lastRevision = revision

    await draftsStore.actions.addDraft({
      id: chatId,
      context,
      revision,
    })
  }

  private onTextCommit = async () => {
    const chatId = this.chatId
    if (!chatId) return

    this.lastRevision = Date.now()
    await draftsStore.actions.forgetDraft({ id: chatId })
  }

  private getContext = (): string | undefined => {
    const quill = this.quill
    if (!quill) return

    const delta = quill.getContents()
    const context = JSON.stringify(delta)
    if (context === '{"ops":[{"insert":"\\n"}]}') return ''

    return context
  }

  private renderDraft = async ({ context, revision }: Draft) => {
    const quill = this.quill
    if (!quill) return

    this.lastRevision = revision

    if (!context.length) {
      Utils.clearArea(quill)
      return
    }

    const delta = JSON.parse(context)
    quill.setContents(delta, 'api')
    Utils.focusArea(quill, true)
  }

  private destroy = () => {
    this.unwatch()
    this.lastRevision = 0
  }
}
