import DOMUtils from '@/utils/DOM'
import Messages from '@/store/messages'
import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'

const UNDELIVERED_MARK_CLASS_NAME = 'undelivered-mark'
const ATTRIBUTE_STATE_TIMEOUT = 'data-state-timeout'

const clearTimeout = (element: HTMLElement) => {
  const stateTimeoutAttribute = element.getAttribute(ATTRIBUTE_STATE_TIMEOUT)
  if (!stateTimeoutAttribute) return

  const timeoutId = +stateTimeoutAttribute
  window.clearTimeout(timeoutId)

  element.removeAttribute(ATTRIBUTE_STATE_TIMEOUT)
}

const setupTimeout = (element: HTMLElement, fn: () => void, timeout: number) => {
  clearTimeout(element)

  const timeoutId = window.setTimeout(fn, timeout)
  element.setAttribute(ATTRIBUTE_STATE_TIMEOUT, timeoutId + '')
}

const createMarkElement = (): HTMLElement => {
  const iconElement = DOMUtils.createElement('div', {
    class: 'fas fa-fw fa-clock-o delivering o-absolute-center',
  })

  return DOMUtils.createElement('div', {
    class: UNDELIVERED_MARK_CLASS_NAME,
  }, iconElement)
}

const applyState = (element: HTMLElement, model: TADA.Message) => {
  const { messageId, state } = model
  switch (state) {
    case TADA.MessageState.UNDELIVERED: {
      const warningElement = createMarkElement()
      const iconElement = warningElement.firstChild as HTMLElement
      if (!iconElement) break

      setupTimeout(element, () => {
        iconElement && iconElement.classList.add('darken')

        setupTimeout(element, () => {
          Messages.handleSendingMessageError(messageId)
        }, 5000)
      }, 500)

      element.appendChild(warningElement)
      break
    }
    case TADA.MessageState.SENDING_ERROR: {
      let warningMark = element.querySelector(`.${UNDELIVERED_MARK_CLASS_NAME}`)
      if (!warningMark) {
        warningMark = createMarkElement()
        element.appendChild(warningMark)
      }

      const iconElement = warningMark.firstChild as HTMLElement
      if (!iconElement) return

      iconElement.className = 'fas fa-fw fa-exclamation-circle unable-to-send o-absolute-center'
      break
    }
    case TADA.MessageState.NORMAL: {
      const warningMark = element.querySelector(`.${UNDELIVERED_MARK_CLASS_NAME}`)
      if (!warningMark) break

      DOMUtils.removeElement(warningMark)
    }
  }
  element.setAttribute(MessageDOMAttribute.STATE, state)
}

const updateConfirmedState = (element: HTMLElement, model: TADA.Message) => {
  const isCurrentConfirmed = element.getAttribute(MessageDOMAttribute.CONFIRMED) ? true : null
  const isConfirmed = model.confirmed || null

  if (isCurrentConfirmed === isConfirmed) return

  isConfirmed
    ? element.setAttribute(MessageDOMAttribute.CONFIRMED, 'true')
    : element.removeAttribute(MessageDOMAttribute.CONFIRMED)
}

export const updateState = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  updateConfirmedState(element, model)

  if (element.getAttribute(MessageDOMAttribute.STATE) === model.state) return

  clearTimeout(element)
  applyState(element, model)
}
