import DOMUtils from '@/utils/DOM'
import { transformEntityName } from '@/utils'

import { showUserInfoPopup } from './UserInfoPopup'
import { contactsStore, groupsStore } from '@/store'
import DataGenerator from '@/api/v3/DataGenerator'
import i18n from '@/i18n'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { Contact, Chat } from '@tada-team/tdproto-ts'

const SENDER_NAME_CN = 'username'

export const createSenderNameElement = ({
  sender, onClick,
}: {
  sender: TADA.Entity | Contact | Chat;
  onClick?: (jid: string, element: HTMLElement) => void
}): HTMLElement => {
  const { jid, displayName } = sender

  const contentElement = DOMUtils.createElement('div', {
    class: 'o-text-cutter',
  })
  contentElement.innerHTML = transformEntityName(displayName)

  const element = DOMUtils.createElement('div', {
    class: SENDER_NAME_CN,
    'data-jid': jid,
  }, contentElement)

  if (jid === 'dummy') {
    element.classList.add('dummy')
  } else {
    onClick = onClick || showUserInfoPopup
    element.addEventListener('click', function (event: Event) {
      event.stopPropagation()

      if (!onClick) return

      const element = this
      if (!element || !(element instanceof HTMLElement) || element.className !== SENDER_NAME_CN) return

      const jid = element.getAttribute('data-jid')
      if (!jid) return

      onClick(jid, element)
    })
  }

  return element
}

export const getSender = (jid: string | null): TADA.Entity | Contact | Chat => {
  const dummy = DataGenerator.generateDummyContact({
    name: i18n.t('profiles.unknownUser').toString(),
  })
  if (!jid) return dummy

  // attempt to get entity locally
  let sender = contactsStore.getters.contact(jid) || groupsStore.state.data[jid]
  if (sender) return sender

  // no contact or group with this jid is available locally
  // assume it's a private group (channel), attempt to load from server
  const resolve = (group: Chat) => {
    ChatEventBus.$emit(Events.SENDER_UPDATED, group)
  }
  const reject = (e: any) => {
    if (e?.status === 403) {
      // group exists, but access denied - generate placeholder
      sender = DataGenerator.generateDummyGroup(undefined, true)
      // assign jid for DOM updates
      sender.jid = jid
      ChatEventBus.$emit(Events.SENDER_UPDATED, sender)
    }
  }
  if (jid?.startsWith('g-')) {
    groupsStore.actions.loadGroup(jid).then(resolve, reject)
  } else {
    contactsStore.actions.loadContact(jid).then(resolve, reject)
  }

  return dummy
}
