import Vue from 'vue'

export const EventTypes = {
  AUTOCOMPLETE_CHANGE: 'AUTOCOMPLETE_CHANGE',
  AUTOCOMPLETE_SELECTED_INDEX: 'AUTOCOMPLETE_SELECTED_INDEX',
  AUTOCOMPLETE_COMMIT: 'AUTOCOMPLETE_COMMIT',

  BEFORE_DESTROY: 'BEFORE_DESTROY',
  SET_CHAT_ID: 'SET_CHAT_ID',
  COMMIT_MESSAGE: 'COMMIT_MESSAGE',
  FOCUS: 'FOCUS',
}

export const EventBus = new Vue()
