export default {
  created () {
    this.DHMHeadingsLiveCollection = null
    this.DHMStuckedHeading = null

    this.DHMEnabled = CSS && (CSS.supports('position', 'sticky') || CSS.supports('position', '-webkit-sticky'))
  },
  mounted () {
    if (!this.DHMEnabled) return

    const { wrapper } = this.$refs
    this.DHMHeadingsLiveCollection = wrapper.getElementsByClassName('day-container__heading')

    this.DHMOnStart = () => {
      if (!this.DHMStuckedHeading) return

      this.DHMStuckedHeading.classList.remove('transparent')
    }

    this.DHMOnEnd = () => {
      this.DHMtimeoutId = null

      if (!this.DHMStuckedHeading) return

      this.DHMStuckedHeading.classList.add('transparent')
    }
  },
  beforeDestroy () {
    this.DHMHeadingsLiveCollection = null
    this.DHMStuckedHeading = null

    this.DHMStopTimeout()
  },
  methods: {
    DHMStopTimeout () {
      if (this.DHMtimeoutId !== null) {
        clearTimeout(this.DHMtimeoutId)
        this.DHMtimeoutId = null

        return true
      }
      return false
    },
    DHMHandler () {
      if (!this.DHMEnabled) return

      this.DHMCheckFloatingDateHeading()

      const stopped = this.DHMStopTimeout()

      if (!stopped) {
        this.DHMOnStart()
      }

      this.DHMtimeoutId = setTimeout(this.DHMOnEnd, 1000)
    },
    DHMCheckFloatingDateHeading () {
      if (!this.DHMHeadingsLiveCollection || this.DHMHeadingsLiveCollection.length === 0) {
        this.DHMStuckedHeading = null
        return
      }

      const headingsCount = this.DHMHeadingsLiveCollection.length

      const { scroll, wrapper } = this.$refs
      const { instance } = scroll

      const scrollTop = instance.contentElement.scrollTop

      if (scrollTop === 0) {
        this.DHMStuckedHeading && this.DHMStuckedHeading.classList.remove('stucked', 'transparent')
        this.DHMStuckedHeading = null
        return
      }

      const scrollHeight = instance.contentElement.scrollHeight

      const { top: tapeOffsetY } = wrapper.getBoundingClientRect()

      const isBottom = scrollTop > scrollHeight / 2
      const start = isBottom ? headingsCount - 1 : 0
      const end = isBottom ? -1 : headingsCount
      const step = isBottom ? -1 : 1

      for (let index = start; index !== end; index += step) {
        const heading = this.DHMHeadingsLiveCollection[index]
        if (!heading || !heading.parentElement) break

        const { top: headingOffsetY } = heading.getBoundingClientRect()
        const visibleAtTape = headingOffsetY > tapeOffsetY
        if (visibleAtTape) {
          const topBorderOffset = scrollTop
          const headingOffset = heading.offsetParent.offsetTop
          const aboveTopBorder = headingOffset < topBorderOffset
          if (!aboveTopBorder) continue

          if (this.DHMStuckedHeading === heading) return

          this.DHMStuckedHeading && this.DHMStuckedHeading.classList.remove('stucked', 'transparent')

          this.DHMStuckedHeading = heading
          this.DHMStuckedHeading.classList.add('stucked')

          return
        }
      }
    },
  },
}
