import i18n from '@/i18n'

import store, { teamsStore } from '@/store'
import { highlightMatches, preventHtmlTags, transformEntityName } from '@/utils'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import router from '@/router'

import { showMessageRelatedDialog } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import { updateContent, CONTENT_CLASS_NAME } from '@/components/Chat/Instance/DOM/Components/Content'

let messageContentWidth = 0

export const setMaxMessageContentWidth = (containerWidth: number, straight?: boolean): number => {
  containerWidth = containerWidth | 0

  const messageRightPadding = 80
  const messageLeftPadding = 20

  if (straight) {
    return (messageContentWidth = Math.max(0, containerWidth))
  }

  return (messageContentWidth = Math.max(0, containerWidth - messageRightPadding - messageLeftPadding))
}

export const getMaxMessageContentWidth = () => messageContentWidth

const moveHandler = (model: TADA.Message) => {
  const { chatId } = model

  const { getters } = store
  const sourceName = getters.chatName(chatId)

  showMessageRelatedDialog({
    message: model,
    dialogText: i18n.t('chattape.goToMessage.textHTML', { sourceName: transformEntityName(sourceName) }).toString(),
    title: i18n.t('chattape.goToMessage.title').toString(),
    buttonText: i18n.t('chattape.goToMessage.go').toString(),
    callback: (chatId: string, messageId: string) => {
      ChatEventBus.$emit(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, false)
      // Using openChat action and CHAT_READY listener
      // is necessary since the ChatInstance is not initialized
      // and no active listeners for GO_TO_MESSAGE event
      // (this is a trick, i don’t know how to do it better yet)
      router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: chatId,
        },
        query: {
          message: messageId,
        },
      })
      ChatEventBus.$once(Events.CHAT_READY, () => {
        ChatEventBus.$emit(Events.GO_TO_MESSAGE, { chatId, messageId })
      })
    },
  })
}

const isBubbleFromAnchor = (activeElement: HTMLElement, e: MouseEvent): boolean => {
  const { target } = e
  if (!target || !(target instanceof HTMLElement)) return false

  let element: HTMLElement | null = target
  while (element) {
    const fromAnchor = element.tagName.toLocaleLowerCase() === 'a'
    if (fromAnchor) return true

    if (element === activeElement) return false

    element = element.parentNode as HTMLElement
  }
  return false
}

export const applyMoveByClick = (element?: HTMLElement, model?: TADA.Message) => {
  if (!element || !model) return

  element.addEventListener('click', (e: MouseEvent) => {
    const stop = isBubbleFromAnchor(element, e)
    if (stop) return

    moveHandler(model)
  })
}

export const createMatchesElement = (text: string, filter: string) => {
  if (text.toLowerCase().indexOf(filter) < 0) return

  const elm = document.createElement('span')
  elm.innerHTML = highlightMatches(preventHtmlTags(text), filter)
  return elm
}

const replaceTextNode = (filter: string, node?: Node) => {
  if (!node || node.nodeType !== Node.TEXT_NODE) return

  const { textContent } = node
  const matchesElm = createMatchesElement(textContent || '', filter)
  if (!matchesElm) return

  const parent = node.parentNode
  parent && parent.replaceChild(matchesElm, node)

  return true
}

const replaceFirstTextNode = (filter: string, node: Node) => {
  const firstNode = node.childNodes[0]
  return replaceTextNode(filter, firstNode)
}

const traverse = (filter: string, node?: Node) => {
  if (!node) return

  if (replaceFirstTextNode(filter, node)) return

  for (const child of node.childNodes) {
    let success = replaceTextNode(filter, child)
    if (success) break

    success = replaceFirstTextNode(filter, child)
    if (success) break

    traverse(filter, child)
  }
}

export const applyFilterHighlight = (filter: string, element?: HTMLElement, model?: TADA.Message) => {
  if (!element || !model) return

  updateContent(element, model)

  const content = element.querySelector(`.${CONTENT_CLASS_NAME}`)
  if (!content) return

  traverse(filter, content)
}
