import { canMarkMessageImportant } from '@/utils'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { copy } from '@/utils/Common'
import { getMessageText } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import * as actionTypes from '@/store/actionTypes'
import ChatInstance from '@/components/Chat/Instance/ChatInstance'
import i18n from '@/i18n'
import Messages from '@/store/messages'
import store, { uiStore, meetingsStore, teamsStore } from '@/store'
import { replyLogger } from '@/loggers'

export default {
  task: (instance: ChatInstance, orderIds: Array<string>) => {
    uiStore.actions.showModal({
      instance: 'new-task',
      payload: { linkedMessages: orderIds },
    })
  },
  meeting: async (instance: ChatInstance, orderIds: Array<string>) => {
    const messages = orderIds
      .map(messageId => Messages.getMessage(instance.chatId, messageId))
      .filter((message): message is TADA.Message => !!message)

    const me = teamsStore.getters.currentTeam.me
    const members = messages
      .map(message => message.sender)
      .filter(jid => jid !== me.jid)

    const description = messages
      .map(message => message.content.text)
      .filter(text => text.length)
      .join('\n\n')

    const now = new Date()
    const startDate = now.toISOString()
    const endDate = new Date(now.getTime() + 60 * 60000).toISOString() // add 60 minutes

    await meetingsStore.actions.closeMeetingDialog()
    await meetingsStore.actions.openMeetingDialog({
      template: {
        description,
        members,
        startDate,
        endDate,
        beforeSave: () => {
          uiStore.actions.clearSelectedMessages()
        },
      },
    })
  },
  important: (instance: ChatInstance, orderIds: Array<string>) => {
    let selectedMessages = orderIds.map(messageId => {
      return Messages.getMessage(instance.chatId, messageId)
    })

    selectedMessages = selectedMessages.filter(message => {
      return message && canMarkMessageImportant(message)
    })

    selectedMessages.forEach(message => {
      if (!message) return

      const { messageId } = message
      Messages.toggleMessageImportance(instance.chatId, messageId)
    })

    uiStore.actions.clearSelectedMessages()
  },
  forward: (instance: ChatInstance, orderIds: Array<string>) => {
    const selectedMessages = orderIds.map(messageId => {
      return Messages.getMessage(instance.chatId, messageId)
    })

    // TODO: remove any
    uiStore.actions.showModal({
      instance: 'RedirectMessagesModal',
      payload: selectedMessages as any,
    })
  },
  reply: (instance: ChatInstance, orderIds: Array<string>) => {
    const { getters } = store

    replyLogger.info('ScopedEvents.reply: chatId', instance.chatId, orderIds)

    const chat = getters.entity(instance.chatId)
    if (!chat || !chat.canSendMessage) return

    replyLogger.info('ScopedEvents.reply: chat', chat)

    ChatEventBus.$emit(Events.TOGGLE_MESSAGE_ACTIVE, { messageId: orderIds, goal: 'reply' })
    uiStore.actions.clearSelectedMessages()
  },
  copy: (instance: ChatInstance, orderIds: Array<string>) => {
    const content: Array<string> = orderIds.map(messageId => {
      return getMessageText(instance.chatId, messageId)
    })

    const text = content.join('\n')
    copy(text)

    uiStore.actions.clearSelectedMessages()
  },
  delete: (instance: ChatInstance, orderIds: Array<string>) => {
    const { dispatch } = store

    uiStore.actions.showModal({
      instance: 'universal-yes-no',
      payload: {
        title: i18n.t('chattape.deleteMessagesTitle').toString(),
        text: i18n.t('chattape.deleteMessagesText').toString(),
        yesText: i18n.t('chattape.messageActions.delete').toString(),
        yes: async () => {
          orderIds.forEach(messageId => {
            dispatch(actionTypes.SOCKET_EDIT_MESSAGE, { chatId: instance.chatId, deleteMessage: messageId })
          })

          uiStore.actions.clearSelectedMessages()
        },
      },
    })
  },
} as { [key: string]: ((instance: ChatInstance, orderIds: Array<string>) => void) | null }
