import DOMUtils, { createSVGElement } from '@/utils/DOM'

import { updateSenderData } from '@/components/Chat/Instance/DOM/Components/SenderData'

export const CLASS_NAME = 'important'
const FLAG_WRAPPER_CLASS_NAME = 'important-flag-wrapper'

const createImportantFlag = () => {
  const polygon = createSVGElement('polygon', {
    points: '38.7,43 25.8,35 12.8,43 12.8,7.7 38.7,7.7',
  })

  const svg = createSVGElement('svg', {
    x: '0px',
    y: '0px',
    width: 20,
    height: 32,
    viewBox: '12.8 17 38.7 43',
  }, polygon)

  return DOMUtils.createElement('div', { class: FLAG_WRAPPER_CLASS_NAME }, svg)
}

export const isImportant = (element: HTMLElement) => {
  return element.classList.contains(CLASS_NAME)
}

export const toggleImportantAppearance = (element?: HTMLElement, value?: boolean) => {
  if (!element) return

  const isImportantNow = isImportant(element)
  value = value === undefined ? !isImportantNow : value

  if (isImportantNow === value) return

  if (value) {
    element.classList.add(CLASS_NAME)

    const flagElement = createImportantFlag()
    element.appendChild(flagElement)
    return
  }

  element.classList.remove(CLASS_NAME)

  const flagElement = element.querySelector(`.${FLAG_WRAPPER_CLASS_NAME}`)
  flagElement && DOMUtils.removeElement(flagElement)
}

export const updateImportantState = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  const { important } = model
  const isAlreadyImportant = isImportant(element)

  if (important === isAlreadyImportant) return

  if (important) {
    toggleImportantAppearance(element, true)
    updateSenderData(element, model)
    return
  }

  toggleImportantAppearance(element, false)
}
