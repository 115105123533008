import api from '@/api/v3'
import MentionBlot, {
  MentionBlotOptions,
  TRIGGER_SYMBOLS,
} from '@/components/Chat/ReplyArea/EditableArea/Blots/MentionBlot'
import { Formats } from '@/components/Chat/ReplyArea/EditableArea/Instance'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'
import { defaultLogger } from '@/loggers'
import { debounce } from 'quasar'
import Quill, { DeltaOperation, DeltaStatic, Sources } from 'quill'

const CHECK_TEXT_TIMEOUT = 500

export default class {
  private chatId: string
  private isThereTriggerSymbol: boolean
  private debouncedCheck: ReturnType<typeof debounce> | null
  private checkingTraverseDistance: number

  constructor (public quill: Quill, options: { chatId: string }) {
    this.chatId = options.chatId
    this.isThereTriggerSymbol = false
    this.debouncedCheck = null
    this.checkingTraverseDistance = 0

    this.quill.on('text-change', (delta, oldDelta, source) => {
      const isBlank = Utils.isEmpty(quill)
      if (isBlank) {
        this.isThereTriggerSymbol = false
        this.debouncedCheck && this.debouncedCheck.cancel()
        return
      }

      if (!this.isNeedToStartChecking(delta, source)) return

      this.debouncedCheck =
        this.debouncedCheck || debounce(this.check, CHECK_TEXT_TIMEOUT)
      this.debouncedCheck()
    })

    EventBus.$on(EventTypes.SET_CHAT_ID, (chatId: string) => {
      this.chatId = chatId
      this.flush()
    })
    EventBus.$on(EventTypes.COMMIT_MESSAGE, this.flush)
    EventBus.$on(EventTypes.BEFORE_DESTROY, this.flush)
  }

  private check = async () => {
    try {
      const contents = this.quill.getContents()
      const text = JSON.stringify(contents)
      defaultLogger.info('Saving draft from LinkChecker..', text)
      // Need refactor
      const { links } = (await api.linkscheck(this.chatId, text)) as any
      if (!links) return

      this.quill.update('silent')

      /**
       * * IT IS CHECK FOR MATCHES LINKS AS PATTERNS
       * @important Now you must use MARKDOWN links
       * @see AutoCompleteModule
       */
      const currentPosition = Utils.getCursorPosition(this.quill)
      const checkingStartPoisiton =
        currentPosition === null ? this.quill.getLength() - 1 : currentPosition

      let step = 0
      let [currentLeaf] = this.quill.getLeaf(checkingStartPoisiton)
      while (currentLeaf) {
        if (step > this.checkingTraverseDistance) break

        const matches = this.checkTextLeaf(currentLeaf, links)
        matches && this.handleMatches(currentLeaf, matches)

        step += 1
        currentLeaf = currentLeaf.prev
      }
    } catch (e) {
      defaultLogger.warn('[LinkCheckerModule.check]', e)
    }
  }

  private handleMatches = (
    leaf: any,
    matches: { [start: number]: TADA.MessageLink },
  ) => {
    const leafOffset = this.quill.getIndex(leaf)
    if (isNaN(leafOffset) || leafOffset < 0) return

    defaultLogger.debug('[LinkCheckerModule.handleMatches]', leaf, matches)

    /**
     * It is a bad method and we need refactor that module
     */

    let offsetDelta = 0
    Object.keys(matches).forEach(startIndex => {
      const linkIndex = +startIndex
      const link = matches[linkIndex]

      const { pattern } = link
      let { text } = link
      const type = text[0]
      text = text.substring(1)

      if (!TRIGGER_SYMBOLS.includes(type)) return

      /**
       * Paste link
       * @see AutocompleteModule for more examples
       */

      const data: MentionBlotOptions = {
        id: pattern,
        sequence: text,
        type: type as any, // because we can't prove that it is SearchType
      }

      const ops: Array<DeltaOperation> = [
        { retain: leafOffset + linkIndex - offsetDelta },
        { delete: pattern.length },
        { insert: { [Formats.MENTION]: data } },
      ]
      Utils.updateContents(this.quill, ops, 'silent')

      offsetDelta += pattern.length - 1
    })
  }

  private checkTextLeaf = (
    leaf: any,
    links: Array<TADA.MessageLink>,
  ): { [start: number]: TADA.MessageLink } | null => {
    const { text } = leaf
    if (!text) return null

    let matches: { [start: number]: TADA.MessageLink } | null = null
    links.forEach(link => {
      const { pattern } = link
      const locations = this.getPatternLocations(text, pattern)
      if (locations.length <= 0) return

      locations.forEach(index => {
        matches = matches || {}
        matches[index] = link
      })
    })

    return matches
  }

  private getPatternLocations = (text: string, pattern: string) => {
    const a = []
    let index = -1
    while (true) {
      index = text.indexOf(pattern, index + 1)
      if (index < 0) break

      a.push(index)
    }
    return a
  }

  private isNeedToStartChecking = (
    delta: DeltaStatic,
    source: Sources,
  ): boolean => {
    const { ops } = delta
    if (!ops) return false

    if (source === 'api') {
      this.checkingTraverseDistance = Infinity
      return ops.some(o => typeof o.insert === 'string')
    }

    if (ops.length >= 3) return false

    return ops.some(o => {
      const { insert } = o
      if (!insert) return false

      if (this.isThereTriggerSymbol && insert === ' ') {
        return true
      } else if (TRIGGER_SYMBOLS.indexOf(insert) >= 0) {
        this.isThereTriggerSymbol = true
      } else if (insert.match(MentionBlot.regexMdLinks)) return true

      return false
    })
  }

  private flush = () => {
    if (!this.debouncedCheck) return

    this.debouncedCheck.cancel()
    this.debouncedCheck = null

    this.checkingTraverseDistance = 0
    this.isThereTriggerSymbol = false
  }
}
