import { render, staticRenderFns } from "./TaskMeta.vue?vue&type=template&id=22cc0a7d&scoped=true&"
import script from "./TaskMeta.vue?vue&type=script&lang=js&"
export * from "./TaskMeta.vue?vue&type=script&lang=js&"
import style0 from "./TaskMeta.vue?vue&type=style&index=0&id=22cc0a7d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22cc0a7d",
  null
  
)

export default component.exports
import {QIcon,QTooltip,QChip,QList,QItem,QItemSection,QItemLabel,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon,QTooltip,QChip,QList,QItem,QItemSection,QItemLabel})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
