import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { clearCurrentActiveMessageTarget, startEditingMessage, startReplyingMessage } from '@/components/Chat/Instance/DOM/Components/Helpers/ActiveMessage'
import { selectionClickHandler } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonEvents'
import ScopedEvents from '@/components/Chat/Instance/DOM/Components/Helpers/ScopedEvents'
import store, { uiStore, teamsStore } from '@/store'
import router from '@/router'
import { OrderedMessages } from '@/store/messages'

import { CHAT_SET_STARTING_MESSAGE_ID } from '@/store/modules/chat/mutationTypes'

import * as Sentry from '@sentry/browser'
import {
  toggleMessageState,
  toggleMessageReceivedState,
  toggleMessageImportantState,
  updateTools,

  updateMessageReactions,
} from '@/components/Chat/Instance/DOM/ConstructorAliases'
import ChatInstance from '@/components/Chat/Instance/ChatInstance'
import { defaultLogger, replyLogger } from '@/loggers'

type MessagesBank = { chatId: string; messages: OrderedMessages; confirmed?: boolean; delayed?: boolean }

export default class {
  private instance: ChatInstance
  // TODO: this any is dangerous. Needs refactoring
  private handlers: { [event: string]: (args: any) => void }
  private unsubscribeListeners: () => void
  private visibilityChangeHandler: EventListenerOrEventListenerObject

  constructor (instance: ChatInstance) {
    this.instance = instance

    const needToScroll = ({ messages, delayed, confirmed }: MessagesBank): boolean => {
      if (!delayed || confirmed) return true

      const { orderIds } = messages
      if (orderIds.length > 1) return true

      const messageId = orderIds[0]
      return !this.instance.messages[messageId]
    }

    const newerIncomingHandler = (bank: MessagesBank) => {
      const { chatId, messages, confirmed, delayed } = bank
      if (this.instance.chatId !== chatId) return

      // If the message is from us and it is already in the chat,
      // then this is editing, and this action is allowed in isInHistoryMode
      if (this.instance.isInHistoryMode) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { orderIds, data } = messages
        if (!delayed || orderIds.length > 1) return

        const messageId = orderIds[0]

        const object = this.instance.messages[messageId]
        if (!object) return
      }

      if (this.instance.isInactive) {
        this.instance.handleIncomingMessages(messages)
        this.instance.cropChatIfNeeded()
        return
      }

      const callback = () => { this.instance.handleIncomingMessages(messages) }
      const scroll = needToScroll(bank)
      scroll
        ? this.instance.scrollController.scrollStraightDown(callback)
        : this.instance.scrollController.persistScrollSync(callback)

      const readIncoming = !confirmed
      readIncoming && this.instance.readChatToLastMessage(delayed ? undefined : messages)
      this.instance.cropChatIfNeeded()
    }

    this.handlers = {
      [Events.ADD_UNDELIVERED_MESSAGE]: ({ chatId, message }: { chatId: string; message: TADA.Message }) => {
        if (this.instance.chatId !== chatId || this.instance.isInHistoryMode) return
        this.instance.handleIncomingMessage(message)

        this.instance.unreadController.hideBar()
        this.instance.scrollController.scrollStraightDown()
      },
      [Events.REMOVE_UNDELIVERED_MESSAGE]: ({ chatId, messageId }: { chatId: string; messageId: string }) => {
        if (this.instance.chatId !== chatId) return

        this.instance.remove(messageId)
      },
      [Events.ADD_OLD_MESSAGES]: ({ chatId, messages }: MessagesBank) => {
        if (this.instance.chatId !== chatId) return

        this.instance.handleIncomingMessages(messages, true)
      },
      [Events.ADD_NEW_MESSAGES]: ({ chatId, messages }: MessagesBank) => {
        if (this.instance.chatId !== chatId) return

        this.instance.handleIncomingMessages(messages)
        this.instance.readChatToLastMessage(messages)
      },
      [Events.ADD_AROUND_MESSAGES]: ({ chatId, messages }: MessagesBank) => {
        if (this.instance.chatId !== chatId) return

        this.instance.handleIncomingMessages(messages, true)
      },
      [Events.ADD_SINGLE_MESSAGE]: newerIncomingHandler,
      [Events.MESSAGES_RESTORED]: newerIncomingHandler,
      [Events.SENDING_MESSAGE_ERROR]: ({ chatId, message }: { chatId: string; message: TADA.Message }) => {
        if (this.instance.chatId !== chatId) return

        const { messageId } = message
        const object = this.instance.messages[messageId]
        object && toggleMessageState(object)
      },
      [Events.MARK_MESSAGE_AS_RECEIVED]: ({ chatId, messageId }: { chatId: string; messageId: string }) => {
        if (this.instance.chatId !== chatId) return

        const object = this.instance.messages[messageId]
        object && toggleMessageReceivedState(object)
      },
      [Events.SET_MESSAGE_IMPORTANCE]: ({ chatId, message }: { chatId: string; message: TADA.Message }) => {
        if (this.instance.chatId !== chatId) return

        const { messageId } = message
        const object = this.instance.messages[messageId]
        object && toggleMessageImportantState(object)
      },
      [Events.TOGGLE_MESSAGE_REACTION]: ({ chatId, message }: { chatId: string; message: TADA.Message }) => {
        if (this.instance.chatId !== chatId) return

        const { messageId } = message
        const object = this.instance.messages[messageId]
        object && this.instance.scrollController.persistScrollSync(() => { updateMessageReactions(object) })
      },
      [Events.CHAT_WAS_READED_TO]: ({ chatId, messageId }: { chatId: string; messageId: string | null }) => {
        if (!messageId || this.instance.chatId !== chatId) return

        if (!this.instance.isInactive) return

        const object = this.instance.messages[messageId]
        if (!object) return

        this.instance.unreadController.placeBar(chatId, object)
        this.instance.scrollController.scrollToMessage(messageId)
      },
      [Events.TOGGLE_HISTORY_MODE]: ({ chatId, value }: { chatId: string; value: boolean }) => {
        if (this.instance.chatId !== chatId) return

        this.instance.isInHistoryMode = value
      },
      [Events.HANDLE_ALL_SELECTED]: ({ handle, orderIds }: { handle: string; orderIds: Array<string> }) => {
        const handler = ScopedEvents[handle]
        handler && handler(this.instance, orderIds)
      },
      [Events.TOGGLE_MESSAGE_ACTIVE]: ({ messageId, goal }: { messageId?: string[] | string | null; goal: string }) => {
        if (messageId === null) {
          defaultLogger.warn('TOGGLE_MESSAGE_ACTIVE: passed in NULL messageId')
          clearCurrentActiveMessageTarget()
          return
        }

        switch (goal) {
          case 'edit': {
            defaultLogger.info('TOGGLE_MESSAGE_ACTIVE editing message', messageId)
            messageId = messageId || this.instance.tape.getEdgeMessageId(false, false, true)
            if (!messageId) break

            const object = this.instance.messages[Array.isArray(messageId) ? messageId[0] : messageId]
            if (!object) break

            const { element } = object
            if (!element) break

            startEditingMessage(this.instance.chatId, element)
            break
          }
          case 'reply': {
            if (!messageId) {
              replyLogger.info('TOGGLE_MESSAGE_ACTIVE: no message id')
              Sentry.withScope(scope => {
                scope.setLevel(Sentry.Severity.Error)
                Sentry.captureMessage('TOGGLE_MESSAGE_ACTIVE: no message id')
              })
              break
            }

            const ids = Array.isArray(messageId) ? messageId : [messageId]
            const elements = ids
              .map(id => {
                const object = this.instance.messages[id]
                if (object) {
                  return object.element
                } else {
                  replyLogger.info('TOGGLE_MESSAGE_ACTIVE: not found element with id', id)
                  Sentry.withScope(scope => {
                    scope.setLevel(Sentry.Severity.Error)
                    scope.setExtra('messageId', id)
                    Sentry.captureMessage('TOGGLE_MESSAGE_ACTIVE: not found element with id')
                  })
                  return null
                }
              })
              .filter(element => !!element)

            if (ids.length !== elements.length) {
              replyLogger.info(
                'TOGGLE_MESSAGE_ACTIVE:',
                ids.length,
                elements.length,
                this.instance.messages,
              )
              Sentry.withScope(scope => {
                scope.setLevel(Sentry.Severity.Error)
                scope.setExtra('messages', JSON.stringify(this.instance.messages))
                Sentry.captureMessage('TOGGLE_MESSAGE_ACTIVE: not found some ids')
              })
            }

            startReplyingMessage(this.instance.chatId, elements as HTMLElement[])
            break
          }
          default:
            clearCurrentActiveMessageTarget()
            break
        }
      },
      [Events.OPEN_IMAGE_VIEWER]: ({ messageId, content }: { messageId?: string | null; content: TADA.MessageContentImage }) => {
        interface ViewerPayload {
          viewerContentList: Array<TADA.MessageContentImage>;
          targetIndex: number;
        }

        let payload: TADA.MessageContentImage | ViewerPayload | null = content

        const { messages: objects } = this.instance
        const isImageFromCurrentChat = messageId ? !!objects[messageId] : false

        if (isImageFromCurrentChat) {
          const messages: Array<TADA.Message> = []
          for (const id in objects) {
            if (!Object.prototype.hasOwnProperty.call(objects, id)) continue

            const object = objects[id]
            if (!object) continue

            const { content } = object.model
            content.type === 'image' && messages.push(object.model)
          }

          let targetIndex = -1

          const sortPredicate = (a: TADA.Message, b: TADA.Message) => a.created < b.created ? -1 : a.created > b.created ? 1 : 0
          const contentList = messages.sort(sortPredicate).map((message, index) => {
            const { content, messageId: id } = message

            id === messageId && (targetIndex = index)
            return content as TADA.MessageContentImage
          })
          targetIndex >= 0 && (payload = { viewerContentList: contentList, targetIndex })
        }

        // TODO: remove any
        uiStore.actions.showModal({ instance: 'FileViewer', payload: payload as any })
      },
      [Events.GO_TO_MESSAGE]: ({ chatId, messageId }: { chatId: string; messageId: string | null }) => {
        if (!messageId) return

        if (chatId === this.instance.chatId) {
          const message = this.instance.messages[messageId]

          if (message) {
            this.instance.scrollController.scrollToMessage(messageId)
            selectionClickHandler(message.element, true)
            return
          }

          store.commit(CHAT_SET_STARTING_MESSAGE_ID, { chatId, messageId })
          ChatEventBus.$emit(Events.REINIT_CHAT)
        } else {
          router.push({
            name: 'Chat',
            params: {
              teamId: teamsStore.getters.currentTeam.uid,
              jid: chatId,
            },
            query: {
              message: messageId,
            },
          })
        }

        ChatEventBus.$once(Events.CHAT_READY, ({ instance }: { instance: ChatInstance}) => {
          const message = instance.messages[messageId]
          message && selectionClickHandler(message.element, true)
        })
      },
      [Events.MESSAGE_PINNED]: async ({ chatId, messageId }: { chatId: string; messageId: string | null }) => {
        if (!messageId || this.instance.chatId !== chatId) return

        const object = this.instance.messages[messageId]
        object && updateTools(object)
      },
      [Events.SENDER_UPDATED]: async (contact: any) => {
        contact && this.instance.tape.updateContact(contact)
      },
    }
    Object.keys(this.handlers).forEach(eventName => {
      ChatEventBus.$on(eventName, this.handlers[eventName])
    })

    this.visibilityChangeHandler = () => {
      const hidden = document.hidden
      this.instance.isInactive = hidden

      if (hidden) {
        this.instance.unreadController.hideBar()
        return
      }

      if (this.instance.isInHistoryMode) return

      const { getters } = store
      const chatId = this.instance.chatId

      // If the chat has already been read to the end, do not move the scroll, and indeed, do nothing
      const messageId = getters.chatLastReadMessageId(chatId)
      const lastDOMMessageId = this.instance.tape.getEdgeMessageId()
      if (messageId === lastDOMMessageId) return

      // If the lastRead message is not in the local storage, then the chat has cut it (chatting now MAX_DISPLAY_MESSAGES messages)
      // We will re-initialize the chat to get into place.
      const object = this.instance.messages[messageId]
      if (!object) {
        this.instance.init()
        return
      }

      this.instance.unreadController.placeBar(chatId, object)
      this.instance.scrollController.scrollToMessage(messageId)
      this.instance.readChatToLastMessage()
    }
    document.addEventListener('visibilitychange', this.visibilityChangeHandler)

    const storeListener = ({ type, payload }: { type: string; payload: any }) => {
      switch (type) {
        case 'contacts/updateContact': {
          payload && this.instance.tape.updateContact(payload)
          break
        }
        case 'ui/clearSelectedMessages': {
          this.instance.tape.unselectAllMessages()
          break
        }
      }
    }
    this.unsubscribeListeners = store.subscribe(storeListener)
  }

  destroy () {
    Object.keys(this.handlers).forEach(eventName => {
      ChatEventBus.$off(eventName, this.handlers[eventName])
    })
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler)
    this.unsubscribeListeners && this.unsubscribeListeners()
  }
}
