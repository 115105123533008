








































import { callsStore, activeCallStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActiveCall } from '@/store/modules/activeCall/models'
import { ChatType, JID } from '@tada-team/tdproto-ts'
import { getChatType } from '@/utils'
import IconPhoneCall from '@/components/UI/icons/IconPhoneCall.vue'
import IconPhone from '@/components/UI/icons/IconPhone.vue'

type BtnType =
  | 'active-call-here'
  | 'active-call-elsewhere'
  | 'inactive-call-here'
  | 'start-call-direct'
  | 'start-call-group'

@Component({
  components: {
    StartCallDropdownBtn: () => import('./StartCallDropdownBtn.vue'),
    BaseCallActionBtn: () => import('./BaseCallActionBtn.vue'),
  },
})
export default class CallActionsBtn extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly chatId!: string

  IconPhoneCall = IconPhoneCall
  IconPhone = IconPhone

  private get btnType (): BtnType {
    const hasActiveCall = !!this.activeCall

    if (hasActiveCall) {
      if (this.isActiveCallHere) {
        return 'active-call-here'
      }
      return 'active-call-elsewhere'
    }

    if (this.currentChatCall) {
      return 'inactive-call-here'
    }
    if (this.chatType === 'direct') {
      return 'start-call-direct'
    }
    return 'start-call-group'
  }

  get chatType (): ChatType {
    return getChatType(this.chatId)
  }

  get activeCall (): ActiveCall | null {
    return activeCallStore.state.activeCall
  }

  get isActiveCallHere (): boolean {
    return this.activeCallJid === this.chatId
  }

  get activeCallJid (): JID {
    if (!this.activeCall) {
      throw new Error('Tried getting jid of a non-existent active call')
    }

    return this.activeCall.jid
  }

  get currentChatCall () {
    return callsStore.getters.call(this.chatId)
  }

  private answerCall (): void {
    activeCallStore.actions.answerCall({ jid: this.chatId })
  }

  private startCall (): void {
    activeCallStore.actions.startCall({ jid: this.chatId, startBuzzing: true })
  }
}
