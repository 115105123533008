import Quill from 'quill'

import store from '@/store'

import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { EventBus, EventTypes } from '@/components/Chat/ReplyArea/RAEventBus'
import { defaultLogger } from '@/loggers'

import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'

import BaseAction, { ActionOptions } from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule/BaseAction'
import EditingAction from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule/EditingAction'
import ReplyingAction from '@/components/Chat/ReplyArea/EditableArea/Modules/MessageActionModule/ReplyingAction'

export const enum Actions {
  EDITING = 'edit',
  REPLYING = 'reply'
}

export default class {
  private actions: { [key: string]: BaseAction }
  private unwatchFunction: () => void

  constructor (public quill: Quill, options: any) {
    this.actions = {
      [Actions.EDITING]: new EditingAction(quill, options.chatId),
      [Actions.REPLYING]: new ReplyingAction(quill, options.chatId),
    }

    this.unwatchFunction = store.watch(() => {
      const { getters } = store
      return getters.getCurrentActiveMessageData
    }, this.handleWatch)

    EventBus.$on(EventTypes.SET_CHAT_ID, this.flush)
    EventBus.$on(EventTypes.COMMIT_MESSAGE, () => { this.flush(null) })
    EventBus.$on(EventTypes.BEFORE_DESTROY, this.destroy)
  }

  public getActions = () => this.actions

  private handleWatch = (options: ActionOptions | null) => {
    defaultLogger.info('Message action module handles watch', options)
    if (!options) {
      this.flush(null)

      Utils.clearArea(this.quill)
      Utils.focusArea(this.quill, true)
      return
    }

    const actionInstance = this.actions[options.goal]
    if (!actionInstance) {
      defaultLogger.warn('[MessageActionModule.watch]', `Action "${options.goal}" is not present`)
      return
    }

    const success = actionInstance.action(options)
    if (!success) {
      defaultLogger.warn(
        'No success in watcher, TOGGLE_MESSAGE_ACTIVE',
        { messageId: null },
      )
      ChatEventBus.$emit(Events.TOGGLE_MESSAGE_ACTIVE, { messageId: null })
    }
  }

  private flush = (chatId: string | null) => {
    Object.keys(this.actions).forEach(actionName => {
      const actionInstance = this.actions[actionName]
      if (!actionInstance) return

      actionInstance.flush()
      chatId && actionInstance.setChatId(chatId)
    })
  }

  private destroy = () => {
    this.flush(null)

    this.unwatchFunction && this.unwatchFunction()
  }
}
