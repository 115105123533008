import { uiStore } from '@/store'
import i18n from '@/i18n'

export default {
  created () {
    this.lastDragTarget = null
  },
  mounted () {
    this.dragOverEvent = e => e.preventDefault()
    this.dropEvent = e => {
      e.preventDefault()
      this.drop(e)
    }
    this.dragEnterEvent = e => this.dragEnter(e)
    this.dragLeaveEvent = e => this.dragLeave(e)

    this.$el.addEventListener('dragover', this.dragOverEvent)
    this.$el.addEventListener('drop', this.dropEvent)
    this.$el.addEventListener('dragenter', this.dragEnterEvent)
    this.$el.addEventListener('dragleave', this.dragLeaveEvent)
  },
  beforeDestroy () {
    this.$el.removeEventListener('dragover', this.dragOverEvent)
    this.$el.removeEventListener('drop', this.dropEvent)
    this.$el.removeEventListener('dragenter', this.dragEnterEvent)
    this.$el.removeEventListener('dragleave', this.dragLeaveEvent)
  },
  methods: {
    toggleDragEffect (on) {
      const { chatForm } = this.$refs
      if (!chatForm) return

      const textArea = chatForm.$el.querySelector('.textarea-box')
      if (!textArea) return

      if (on) {
        textArea.setAttribute('data-tip', i18n.t('chattape.dropFileHint'))
        textArea.classList.add('drag-over')
        return
      }

      textArea.removeAttribute('data-tip')
      textArea.classList.remove('drag-over')
    },
    drop (event) {
      this.toggleDragEffect(false)
      if (!this.canSendMessage) return

      const { files } = event.dataTransfer
      if (files.length === 0) return
      uiStore.actions.showModal({ instance: 'FileUploader', payload: files })
    },
    containsFiles (event) {
      if (event.dataTransfer.types) {
        for (let i = 0; i < event.dataTransfer.types.length; i++) {
          if (event.dataTransfer.types[i] === 'Files') {
            return true
          }
        }
      }
      return false
    },
    dragEnter (event) {
      if (!this.canSendMessage) return
      this.lastDragTarget = event.target
      this.containsFiles(event) > 0 && this.toggleDragEffect(true)
    },
    dragLeave (event) {
      try {
        if (event.target !== this.lastDragTarget) return
      } catch (e) {
        console.warn(`[FileDrop err]: ${e}`)
      }
      this.toggleDragEffect(false)
    },
  },
}
