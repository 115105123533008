import DOMUtils, { insertAfter, toClassList } from '@/utils/DOM'
import { toStringMicroseconds } from '@/utils/Date'

import { toggleMessageCollapsedState, createMessage, updateMessage } from '@/components/Chat/Instance/DOM/ConstructorAliases'
import { checkAttributesToCollapse, COLLAPSED_CLASS_NAME } from '@/components/Chat/Instance/DOM/Components/SenderData'

import './styles/style.scss'
import './styles/style.day.css'
import './styles/style.tools.scss'
import './styles/style.unread.css'
import './styles/style.reactions.css'
import './styles/style.attachment.css'

export enum MessageDOMAttribute {
  ID = 'data-message-id',
  OWNER = 'data-owner-id',
  TIMESTAMP = 'data-ts',
  DATE = 'data-date',
  COLLAPSED = 'data-collapsed',
  STATE = 'data-state',
  SOURCE = 'data-source',
  CONFIRMED = 'data-confirmed',
  HAS_PREVIEWS = 'data-link-previews'
}

const setupClassNames = (model: TADA.Message, fully?: boolean): string => {
  return toClassList({
    msg: true,
    [COLLAPSED_CLASS_NAME]: !fully,
  })
}

const setupAttributes = (model: TADA.Message, date?: Date, fully?: boolean): { [key: string]: any } => {
  const attributes = {
    class: setupClassNames(model, fully),
    [MessageDOMAttribute.ID]: model.messageId,
    [MessageDOMAttribute.OWNER]: model.sender,
    [MessageDOMAttribute.TIMESTAMP]: (date || new Date(model.created)).getTime(),
    [MessageDOMAttribute.DATE]: toStringMicroseconds(model.created),
  }

  model.isStandalone && Object.assign(attributes, { [MessageDOMAttribute.SOURCE]: model.chatId })
  model.hasPreviews && Object.assign(attributes, { [MessageDOMAttribute.HAS_PREVIEWS]: true })

  return attributes
}

export const create = ({ message, date, fully }: { message: TADA.Message; date?: Date; fully?: boolean }): HTMLElement => {
  const attributes = setupAttributes(message, date, fully)
  const messageElement = DOMUtils.createElement('div', attributes)

  createMessage(messageElement, message, fully)

  return messageElement
}

export const update = ({ model, element }: { model: TADA.Message; element: HTMLElement }) => {
  updateMessage(element, model)
}

export const createForInsertion = (model: TADA.Message, date?: Date, previousElement?: HTMLElement | null): HTMLElement => {
  const { sender, created, important } = model
  date = date || new Date(created)
  const fully = important || !previousElement || !checkAttributesToCollapse(date.getTime(), sender, previousElement)

  return create({ message: model, date, fully })
}

const insertMessageTo = ({ container, model, date, messageElement }: { container: HTMLElement; model?: TADA.Message; messageElement?: HTMLElement; date?: Date }): HTMLElement => {
  messageElement ||= model && createForInsertion(model, date, container.lastElementChild as HTMLElement)
  if (!messageElement) throw new Error('[DOM.insertMessageTo] \'Model\' or \'messageElement\' is not specified')

  return container.appendChild(messageElement)
}

const insertMessageRelative = ({ ref, model, date, after, messageElement }: { ref: HTMLElement; model?: TADA.Message; messageElement?: HTMLElement; date?: Date; after?: boolean }): HTMLElement => {
  messageElement ||= model && createForInsertion(model, date, (after ? ref : ref.previousElementSibling) as HTMLElement)
  if (!messageElement) throw new Error(`[DOM.insertMessageRelative(after = ${after})] 'Model' or 'messageElement' is not specified`)

  if (after) {
    const element = insertAfter(messageElement, ref)
    if (!element) throw new Error(`[DOM.insertMessageRelative(after)] No container of message element: ${ref.getAttribute(MessageDOMAttribute.ID)}`)
    return element
  }

  const container = ref.parentElement
  if (!container) throw new Error(`[DOM.insertMessageRelative] No container of message element: ${ref.getAttribute(MessageDOMAttribute.ID)}`)

  messageElement = container.insertBefore(messageElement, ref)

  return messageElement
}

export const isElementMessage = (element?: Element | null): boolean => {
  if (!element) return false
  return !!element.getAttribute(MessageDOMAttribute.ID)
}

export type insertMessageOptions = { container: HTMLElement; model?: TADA.Message; messageElement?: HTMLElement; date?: Date; startFromTop?: boolean }
export const insertMessage = ({ container, model, date, startFromTop = true, messageElement }: insertMessageOptions): HTMLElement => {
  const { children } = container

  let value = null

  if (model) {
    date = date || new Date(model.created)
    value = date.getTime() * 1000
  } else if (messageElement) {
    const timestampAttribute = messageElement.getAttribute(MessageDOMAttribute.DATE) || ''
    value = +timestampAttribute
  }

  if (value === null) {
    throw new Error('[DOM.insertMessage] \'Model\' or \'messageElement\' is not specified')
  }

  let result: HTMLElement | null = null
  if (startFromTop) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLElement
      const childValue = child.getAttribute(MessageDOMAttribute.DATE)
      if (childValue === null) continue

      if (+childValue > value) {
        result = insertMessageRelative({ ref: child, model, date, messageElement })
        break
      }
    }
  } else {
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i] as HTMLElement
      const childValue = child.getAttribute(MessageDOMAttribute.DATE)
      if (childValue === null) continue

      if (+childValue < value) {
        result = insertMessageRelative({ ref: child, model, date, messageElement, after: true })
        break
      }
    }
  }
  result = result || insertMessageTo({ container, model, date, messageElement })

  if (!model) toggleMessageCollapsedState(result)

  const nextMessage = result.nextElementSibling
  nextMessage && isElementMessage(nextMessage) && toggleMessageCollapsedState(nextMessage as HTMLElement)

  return result
}

export const extractMessage = ({ element }: { element: HTMLElement }) => {
  const nextMessage = element.nextElementSibling

  const success = !!DOMUtils.removeElement(element)
  if (!success) throw new Error(`[DOM.extractMessage] Unable to remove message element [${MessageDOMAttribute.ID}="${element.getAttribute(MessageDOMAttribute.ID)}"] from DOM`)

  nextMessage && isElementMessage(nextMessage) && toggleMessageCollapsedState(nextMessage as HTMLElement)
}
