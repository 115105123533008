import store, { groupsStore } from '@/store'
import { calculateTopOffset, persistScrollPositionSync } from '@/utils/DOM'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'

import { selectionClickHandler } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonEvents'
import ChatInstance from '@/components/Chat/Instance/ChatInstance'
// import { defaultLogger } from '@/loggers'

export default class {
  instance: ChatInstance
  scrollableContent: HTMLElement | null = null

  SCROLL_DOWN_THRESHOLD_OFFSET = 300
  SCROLL_OFFSET_BAR = 120
  SCROLL_OFFSET_MESSAGE = 120
  SCROLL_OFFSET_FOR_PINNED_MESSAGE = 50

  constructor (instance: ChatInstance, root: HTMLElement) {
    this.instance = instance
    this.scrollableContent = root.parentNode as (HTMLElement | null)
  }

  persistScrollSync (callback: () => unknown) {
    if (!this.scrollableContent) return

    persistScrollPositionSync({
      container: this.scrollableContent,
      callback,
    })
  }

  scrollStraightDown (action?: () => unknown) {
    if (action) {
      ChatEventBus.$emit(Events.SCROLL_CHAT, { action, threshold: this.SCROLL_DOWN_THRESHOLD_OFFSET })
      return
    }

    ChatEventBus.$emit(Events.SCROLL_CHAT)
  }

  scrollToMessage (messageId: string | null, selectMessage = false): boolean {
    if (!messageId) {
      this.scrollStraightDown()
      return false
    }

    const object = this.instance.messages[messageId]
    if (!object) {
      this.scrollStraightDown()
      return false
    }

    const { element } = object
    if (!element) {
      this.scrollStraightDown()
      return false
    }

    const { getters } = store
    const startingMessageId = getters.chatStartingMessageId(this.instance.chatId)
    const isStarting = messageId === startingMessageId
    selectMessage && isStarting && selectionClickHandler(element)

    const lastReadMessageId = getters.chatLastReadMessageId(this.instance.chatId)
    if (lastReadMessageId === messageId) {
      const lastMessage = getters.chatLastMessage(this.instance.chatId)
      if (lastMessage && lastMessage.messageId === messageId) {
        this.scrollStraightDown()
        return true
      }
    }

    const { offsetHeight } = element
    // check if it's a group and has pinned message to adjust for its height
    const group = groupsStore.state.data[this.instance.chatId]
    const pinnedMessage = group && group.pinnedMessage

    const offset =
      calculateTopOffset(element) +
      (!isStarting ? offsetHeight : 0) -
      this.SCROLL_OFFSET_MESSAGE -
      (pinnedMessage ? this.SCROLL_OFFSET_FOR_PINNED_MESSAGE : 0)
    ChatEventBus.$emit(Events.SCROLL_CHAT, { offset })

    return true
  }
}
