import { defaultLogger } from '@/loggers'

const getDefaultLineBreakHandler = quill => {
  const { keyboard } = quill
  if (!keyboard) return

  const { bindings } = keyboard
  if (!bindings) return

  const enterKey = 13
  const enterBindings = bindings[enterKey]
  if (!enterBindings) return

  let defaultHandler = null
  for (const i in enterBindings) {
    const binding = enterBindings[i]
    if (!binding) continue

    const { key, shiftKey, handler } = binding
    if (key === enterKey && shiftKey === null) {
      defaultHandler = handler
      break
    }
  }
  return defaultHandler
}

export const popHandler = (quill, key) => {
  try {
    const { bindings } = quill.keyboard
    bindings[key].unshift(bindings[key].pop())
  } catch (e) {
    defaultLogger.warn(`[Keyboard.popHandler] Key: ${key} -`, e)
  }
}

export const getBindings = () => {
  return {
    altLineBreak: {
      key: 13,
      ctrlKey: true,
      handler: function (range, context) {
        const defaultHandler = getDefaultLineBreakHandler(this.quill)
        defaultHandler && defaultHandler.call(this, range, context)
      },
    },
  }
}
