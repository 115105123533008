import Quill, { DeltaOperation } from 'quill'
import Vue from 'vue'

import * as CommonUtils from '@/components/Chat/ReplyArea/EditableArea/Utils'
import i18n from '@/i18n'

import DOMUtils, { prepend } from '@/utils/DOM'
import { createEmoji, createEmojiContainer } from '@/components/Chat/Instance/DOM/Components/Helpers/Reactions'
import Popout, { attachLazyPopout, ATTRIBUTE_ANCHOR_POPOUT_ID, extractPopoutInstance } from '@/components/UI/Popouts/Popout'
import { Formats, Modules } from '@/components/Chat/ReplyArea/EditableArea/Instance'
import AutocompleteModule from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule'
import Stickers from '../../Emoji/Stickers.vue'
import { isEscape } from '@/utils/KeyUtils'

const maxLastUsedEmojiCount = 10

export default class {
  private isOpen: boolean
  private anchor: HTMLElement | null
  private stickers: any

  constructor (public quill: Quill) {
    this.isOpen = false

    const id = 'emoji-button'
    this.anchor = document.getElementById(id)
    if (!this.anchor) return

    this.anchor.setAttribute(ATTRIBUTE_ANCHOR_POPOUT_ID, id)

    attachLazyPopout({
      anchor: this.anchor,
      content: this.content,
      behaviour: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onBeforeShow: (acnhor, element) => {
          const autocompleteModule = this.quill.getModule(Modules.AUTOCOMPLETE) as AutocompleteModule | null
          autocompleteModule && autocompleteModule.emitData(null)
        },
        onShow: (acnhor, element) => {
          const container = element && element.querySelector('.reaction-picker')
          container && (container.scrollTop = 0)

          acnhor.classList.add('active')
          this.isOpen = true

          window.addEventListener('keydown', this.escapeClose)
        },
        onHide: acnhor => {
          acnhor.classList.remove('active')
          this.isOpen = false

          window.removeEventListener('keydown', this.escapeClose)
        },
        local: true,
      },
    })

    this.quill.on('text-change', (delta, oldDelta, source) => {
      source === 'user' && this.closeList()
    })

    this.quill.on('selection-change', (range, oldRange, source) => {
      range && source === 'user' && this.closeList()
    })
  }

  private closeList = () => {
    if (!this.isOpen || !this.anchor) return

    const popout = extractPopoutInstance(this.anchor)
    popout && popout.hide()
  }

  private escapeClose = (event: KeyboardEvent) => {
    isEscape(event) && this.closeList()
  }

  private onItemClick = (emoji: string, e: MouseEvent) => {
    const target = e.target as HTMLElement
    if (!target) return

    const range = this.quill.getSelection(true)
    if (!range) return

    this.setLastUsed(emoji)

    const { index } = range
    const ops: Array<DeltaOperation> = []
    index && ops.push({ retain: range.index })
    ops.push({ insert: { [Formats.EMOJI]: emoji } })
    CommonUtils.updateContents(this.quill, ops)

    this.quill.setSelection(range.index + 1, 0, 'api')
  }

  private setLastUsed = (emoji: string) => {
    if (!this.anchor) return

    const popout = extractPopoutInstance(this.anchor)
    if (!popout) return

    const { element } = popout
    if (!element) return

    const container = element.querySelector('.emoji-container[data-container-type="last-used"] > .list')
    if (!container) return

    let emojiElement = container.querySelector(`li[data-emoji="${emoji}"]`)
    if (emojiElement) return

    emojiElement = createEmoji(emoji)
    prepend(container, emojiElement)

    if (container.children.length > maxLastUsedEmojiCount) DOMUtils.removeElement(container.lastChild as Element)
  }

  private createLastUsedEmojiContainer = (): HTMLElement | null => {
    // no functional with lastUsedEmoji

    try {
      const lastUsed = [] as Array<any> // JSON.parse(getters.uiSettings.lastUsedEmoji || '[]') as Array<any>
      if (!Array.isArray(lastUsed) || lastUsed.length === 0) return null

      if (lastUsed.length > maxLastUsedEmojiCount) {
        lastUsed.slice(0, maxLastUsedEmojiCount)
      }

      const element = createEmojiContainer({ name: 'Последние использованные', list: lastUsed, onItemClick: this.onItemClick })
      element.setAttribute('data-container-type', 'last-used')
      return element
    } catch (e) {
      return null
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private content = (popout: Popout): HTMLElement | string => {
    const categories = {
      [i18n.t('chattape.emoji.smileysPeople').toString()]: [
        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '😊', '😇', '🙂', '😉', '😌', '😍', '🤔',
        '😘', '😗', '😙', '😚', '😋', '😜', '😝', '😛', '😎', '😏', '😒', '😞', '😔', '😟',
        '😕', '🙁', '😣', '😖', '😫', '😩', '😤', '😠', '😡', '😶', '😐', '😑', '😯',
        '😦', '😧', '😮', '😲', '😵', '😳', '😱', '😨', '😰', '😢', '😥', '😭', '😓', '😪',
        '😴', '😬', '😷', '😈', '👿', '👹', '👺', '💩', '👻', '💀', '👽', '👾', '🎃',
        '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾',
        '💁‍♀️', '💁‍♂️', '🙅‍♀️', '🙅‍♂️', '🙆‍♀️', '🙆‍♂️', '🙋‍♀️', '🙋‍♂️', '🤦‍♀️', '🤦‍♂️', '🤷‍♀️', '🤷‍♂️', '🙎‍♀️', '🙎‍♂️', '🙍‍♀️', '🙍‍♂️',
        '👐', '🙌', '👏', '🤝', '🙏', '👍', '👎', '👊', '✊', '🤛', '🤜', '🤞', '✌️', '🤟', '🤘', '👌',
        '👈', '👉', '👆', '👇', '☝️', '✋', '👋', '🤙', '💪',
        '💅', '💍', '💋', '💄', '👄', '👅', '👙', '💑', '💔', '💘', '❕', '❔',
      ],
      [i18n.t('chattape.emoji.animalsNature').toString()]: [
        '🐶', '🐱', '🐭', '🐹', '🐰', '🐻', '🐼', '🐨', '🐯', '🦁', '🐮', '🐷', '🐽',
        '🐸', '🐵', '🙈', '🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤', '🐣', '🐥',
        '🐺', '🐗', '🐴', '🦄', '🐝', '🐛', '🐌', '🐚', '🐞', '🐜', '🕷️',
        '🕸️', '🐢', '🐍', '🦂', '🦀', '🐙', '🐠', '🐟', '🐡', '🐬',
        '🐳', '🐋', '🐊', '🐆', '🐅', '🐃', '🐂', '🐄', '🐪', '🐫', '🐘',
        '🐎', '🐖', '🐐', '🐏', '🐑', '🐕', '🐩', '🐈', '🐓', '🦃', '🕊️', '🐇', '🐁', '🐀',
        '🐿️', '🐾', '🐉', '🐲', '🌵', '🎄', '🌲', '🌳', '🌴', '🌱', '🌿', '☘️', '🍀', '🎍',
        '🎋', '🍃', '🍂', '🍁', '🍄', '🌾', '💐', '🌷', '🌹', '🌻', '🌼', '🌸', '🌺',
        '🌎', '🌝', '🌞',
        '🌛', '🌙', '💫', '⭐', '🌟', '✨', '⚡', '🔥', '💥', '☄️', '☀️', '🌤️', '⛅',
        '🌥️', '🌦️', '🌈', '☁️', '🌧️', '☃️', '⛄', '❄️', '🌬️', '💨', '🌪️',
        '🌫️', '🌊', '💧', '💦', '☔',
      ],
      [i18n.t('chattape.emoji.objects').toString()]: [
        '⌚', '📱', '💻', '💾', '💽', '📷', '📹', '🎥',
        '📞', '📟', '📠', '📺', '📻', '⏰', '⌛', '⏳', '📡', '🔋',
        '🔌', '💡', '🔦', '💸', '💵', '💰', '💳', '💎', '🔧',
        '🔨', '🔩', '💣', '🔪', '🏺',
        '🔭', '🔬', '💊', '💉', '🌡', '🚽', '🚰', '🚿', '🛀', '🔑', '🚪', '🎁',
        '💌', '📦', '📮', '📈', '📉', '📋', '📁', '📚', '📎', '📏', '📌', '🔎', '🔒',
      ],
      [i18n.t('chattape.emoji.foodDrink').toString()]: [
        '🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓', '🍈', '🍒', '🍑', '🍍',
        '🍅', '🍆', '🌽', '🌶️', '🍠', '🌰', '🍯', '🍞', '🧀',
        '🍳', '🍤', '🍗', '🍖', '🍕', '🌭', '🍔', '🍟', '🌮', '🌯', '🍝',
        '🍜', '🍲', '🍥', '🍣', '🍱', '🍛', '🍙', '🍚', '🍘', '🍢', '🍡', '🍧', '🍨', '🍦', '🍰',
        '🎂', '🍮', '🍭', '🍬', '🍫', '🍿', '🍩', '🍪', '🍼', '☕', '🍵', '🍶', '🍺', '🍻',
        '🍷', '🍸', '🍹', '🍾', '🍴', '🍽️',
      ],
      [i18n.t('chattape.emoji.activitySport').toString()]: [
        '⚽', '🏀', '🏈', '⚾', '🏐', '🏉', '🎾', '🎱', '🏓', '🏸',
        '🥅', '🏒', '🏑', '🏏', '⛳', '🏹', '🎣', '🥊', '🥋', '🎽', '🥌', '🛷',
        '🎿', '🏂', '🤼‍♀️', '🤼‍♂️', '🤸‍♀️', '🤸‍♂️', '🤺', '🤾‍♀️', '🤾‍♂️', '🏇', '🧘‍♀️', '🧘‍♂️',
        '🏄‍♀️', '🏄', '🏊‍♀️', '🏊‍♂️', '🤽‍♀️', '🤽‍♂️', '🚣‍♀️', '🚣',
        '🧗‍♀️', '🧗‍♂️', '🚵‍♀️', '🚵‍♂️', '🚴‍♀️', '🚴‍♂️', '🏆', '🥇', '🥈', '🥉', '🏅',
        '🎫', '🎪', '🤹‍♀️', '🤹‍♂️', '🎭', '🎨', '🎬', '🎤', '🎧', '🎼', '🎹', '🥁',
        '🎷', '🎺', '🎸', '🎻', '🎲', '🎯', '🎳', '🎮', '🎰',
      ],
      [i18n.t('chattape.emoji.travelPlaces').toString()]: [
        '🚗', '🚕', '🚙', '🚌', '🚎', '🚑', '🚒', '🚛', '🚜', '🚲', '🚔', '🚍',
        '🚡', '🚋', '🚄', '🚈', '🚂', '🚊', '🚁', '🚀', '⛵', '🚦', '🗿',
        '🗽', '🗼', '🌋', '⛺', '🏭', '🏠', '🏢', '🏤', '🏪', '⛪',
        '🌅', '🌆', '🌃',
      ],
    } as any

    const pickerElement = DOMUtils.createElement('div', { class: 'reaction-picker' })

    const lastUsedEmojiContainer = this.createLastUsedEmojiContainer()
    lastUsedEmojiContainer && pickerElement.appendChild(lastUsedEmojiContainer)

    Object.keys(categories).forEach(name => {
      const list = categories[name]
      const containerElement = createEmojiContainer({ name, list, onItemClick: this.onItemClick })
      pickerElement.appendChild(containerElement)
    })

    if (!this.stickers) {
      const Component = Vue.extend(Stickers)
      this.stickers = new Component({ i18n })
      this.stickers.$mount()
    }

    pickerElement.appendChild(this.stickers.$el)

    const licenseElement = DOMUtils.createElement('a', {
      class: 'license',
      href: 'https://www.emojione.com/',
      target: '_blank',
    }, 'Emoji icons supplied by EmojiOne')
    pickerElement.appendChild(licenseElement)

    return pickerElement
  }
}
