import { updateTools } from '@/components/Chat/Instance/DOM/Components/Tools'
import { updateContent } from '@/components/Chat/Instance/DOM/Components/Content'
import { updateSenderData } from '@/components/Chat/Instance/DOM/Components/SenderData'
import { updateAttachments } from '@/components/Chat/Instance/DOM/Components/Attachments'
import { updateSelectionMark } from '@/components/Chat/Instance/DOM/Components/Selection'
import { updateMessageReactions } from '@/components/Chat/Instance/DOM/Components/Reactions'

import { updateState } from '@/components/Chat/Instance/DOM/Components/Processing/State'
import { updateEditedMark } from '@/components/Chat/Instance/DOM/Components/Processing/Edited'
import { updateDeletedState } from '@/components/Chat/Instance/DOM/Components/Processing/Deleted'
import { updateReceivedState } from '@/components/Chat/Instance/DOM/Components/Processing/Received'
import { updateImportantState } from '@/components/Chat/Instance/DOM/Components/Processing/Important'
import { updateThreadState } from '@/components/Chat/Instance/DOM/Components/Processing/Thread'

type HandlerFuction = (element: HTMLElement, model: TADA.Message | null) => void

export const enum ReproduceTarget {
  ALL = 'all',
  ALL_NO_DATA = 'no-data',

  DATA = 'data',
  STATE = 'state',
  CONTENT = 'content',
  TOOLS = 'tools',
  SELECTION = 'selection',
  RECEIVED = 'received',
  ATTACHMENTS = 'attachments',
  IMPORTANT = 'important',
  REACTIONS = 'reactions',
  EDITED = 'edited',
  DELETED = 'deleted',
  THREAD = 'thread'
}

const handlers: { [property: string]: HandlerFuction } = {
  [ReproduceTarget.DATA]: updateSenderData,
  [ReproduceTarget.STATE]: updateState,
  [ReproduceTarget.CONTENT]: updateContent,
  [ReproduceTarget.TOOLS]: updateTools,
  [ReproduceTarget.SELECTION]: updateSelectionMark,
  [ReproduceTarget.RECEIVED]: updateReceivedState,
  [ReproduceTarget.ATTACHMENTS]: updateAttachments,
  [ReproduceTarget.IMPORTANT]: updateImportantState,
  [ReproduceTarget.REACTIONS]: updateMessageReactions,
  [ReproduceTarget.EDITED]: updateEditedMark,
  [ReproduceTarget.DELETED]: updateDeletedState,
  [ReproduceTarget.THREAD]: updateThreadState,
}

const defaultPropertiesOrder = [
  ReproduceTarget.DATA,
  ReproduceTarget.STATE,
  ReproduceTarget.CONTENT,
  ReproduceTarget.TOOLS,
  ReproduceTarget.SELECTION,
  ReproduceTarget.ATTACHMENTS,
  ReproduceTarget.REACTIONS,
  ReproduceTarget.RECEIVED,
  ReproduceTarget.IMPORTANT,
  ReproduceTarget.EDITED,
  ReproduceTarget.THREAD,
]
const basePropertiesOrder = defaultPropertiesOrder.slice(1)

const getProperties = (target: ReproduceTarget | Array<ReproduceTarget>): Array<string> => {
  if (Array.isArray(target)) return target

  switch (target) {
    case ReproduceTarget.ALL: return defaultPropertiesOrder
    case ReproduceTarget.ALL_NO_DATA: return basePropertiesOrder
    default: return [target]
  }
}

export const reproduce = ({ basis, model, target }: { basis: HTMLElement; model: TADA.Message | null; target?: ReproduceTarget | Array<ReproduceTarget> }) => {
  const properties = getProperties(target || ReproduceTarget.ALL)

  properties.forEach(key => {
    const handler = handlers[key]
    if (!handler) throw new Error(`[Constructor.reproduce] No handler for "${key}" key`)

    handler(basis, model)
  })
}
