import Messages from '@/store/messages'

import { getActiveMessageSource } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { toggleMessageCollapsedState } from '@/components/Chat/Instance/DOM/ConstructorAliases'
import { ID_UNREAD_DIVIDER } from '@/components/Chat/Instance/Controllers/UnreadBarController'
// import { defaultLogger } from '@/loggers'

const DELETED_CLASS_NAME = 'deleted-message'

export const collapseDayContainerIfNeeded = (dayElement: HTMLElement | null, element: HTMLElement) => {
  let rosterElement: HTMLElement | null = null

  if (!dayElement) {
    rosterElement = element.parentElement
    if (!rosterElement) return

    dayElement = rosterElement.parentElement
    if (!dayElement) return
  } else {
    rosterElement = dayElement.lastChild as HTMLElement
  }

  for (let index = 0; index < rosterElement.children.length; index++) {
    const child = rosterElement.children[index]
    const owner = child.getAttribute(MessageDOMAttribute.OWNER)
    const messageId = child.getAttribute(MessageDOMAttribute.ID)

    const isNormalMessage = owner && messageId
    if (isNormalMessage) {
      dayElement.removeAttribute(MessageDOMAttribute.COLLAPSED)
      return
    }
  }

  dayElement.setAttribute(MessageDOMAttribute.COLLAPSED, 'true')
}

// export const expandDayContainer = (dayElement: HTMLElement | null, element: HTMLElement) => {
//   if (dayElement) {
//     dayElement.removeAttribute(MessageDOMAttribute.COLLAPSED)
//   } else {
//     const rosterElement = element.parentElement
//     if (!rosterElement) return

//     const dayElement = rosterElement.parentElement
//     if (!dayElement) return

//     dayElement.removeAttribute(MessageDOMAttribute.COLLAPSED)
//   }
// }

export const updateDeletedState = (element: HTMLElement, model: TADA.Message | null) => {
  if (!model) return

  // const { getters } = store
  const { content, messageId } = model

  if (content.type !== TADA.MessageType.DELETED && content.type !== TADA.MessageType.NEW_TASK) {
    // expandDayContainer(null, element)
    return
  }

  const isAlreadyDeleted = element.classList.contains(DELETED_CLASS_NAME)
  if (isAlreadyDeleted) return

  element.innerHTML = ''
  element.className = 'msg ' + DELETED_CLASS_NAME

  element.removeAttribute(MessageDOMAttribute.OWNER)

  const nextMessageElement = element.nextElementSibling
  nextMessageElement && toggleMessageCollapsedState(nextMessageElement as HTMLElement)

  const previousElement = element.previousElementSibling as HTMLElement | null
  if (previousElement) {
    const id = previousElement.getAttribute('id')
    id === ID_UNREAD_DIVIDER && (previousElement.style.display = 'none')
  }

  const chatId = getActiveMessageSource(element)
  chatId && Messages.removeMessage(chatId, messageId, true)

  collapseDayContainerIfNeeded(null, element)
}
