








































import { callsStore, uiStore, activeCallStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import IconPhoneVolume from '@/components/UI/icons/IconPhoneVolume.vue'
import { callsLogger } from '@/loggers'

@Component({
  name: 'NotifyCallBar',
  components: {
    IconPhoneVolume,
    IconUser: () => import('@/components/UI/icons/IconUser.vue'),
  },
})
export default class NotifyCallBar extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly chatId!: string

  @Prop({
    type: Boolean,
    default: false,
  }) readonly hasPinned!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) readonly hasMeetingSelectedMessages!: boolean

@Prop({
  type: Boolean,
  default: false,
}) readonly hasTaskSelectedMessages!: boolean

  maxUsersPerCall = 0

  get activeCall () {
    return activeCallStore.state.activeCall
  }

  get isConnected () {
    return this.activeCall?.jid === this.chatId
  }

  get actionCaption () {
    if (!this.canJoin) return this.$t('calls.membersLimit')
    if (!this.isConnected) return this.$t('calls.notifyCallBar.connect')

    return this.$t('calls.notifyCallBar.disconnect')
  }

  get actionIcon () {
    if (!this.canJoin) return

    // TODO надо порефачить, но я так не хочу(((((
    const icon = this.isConnected ? 'close' : 'expand'

    if (icon === 'expand') {
      return createRawSVGComponent({
        icon,
        size: 16,
        sizeStorage: 20,
        permanentlyActive: true,
        width: undefined,
        height: undefined,
      })
    }

    return createRawSVGComponent({
      icon,
      size: 16,
      sizeStorage: 16,
      permanentlyActive: true,
      width: undefined,
      height: undefined,
    })
  }

  get stateMessage () {
    if (this.activeCall?.jid !== this.chatId) {
      return this.$t('calls.notifyCallBar.activeCall')
    }

    return this.$t('calls.notifyCallBar.connected')
  }

  get canJoin () {
    return this.isConnected || !this.maxUsersInCall
  }

  get maxUsersInCall () {
    // >= тк бэк не уверен, что сможет обеспечить ровно-ровно
    return this.members >= this.maxUsersPerCall
  }

  /**
   * Not an active call (which use is connected to, but an ongoing call in this chat)
   */
  get ongoingCall () {
    return callsStore.getters.call(this.chatId)
  }

  get members () {
    return this.ongoingCall?.onlineCount ?? 0
  }

  created () {
    this.maxUsersPerCall = window.FEATURES.max_participants_per_call ?? 0
  }

  openUnblockMicModal () {
    uiStore.actions.showModal({ instance: 'UnblockMicModal' })
  }

  toggleCall () {
    if (!this.canJoin) return

    if (this.isConnected) {
      activeCallStore.actions.endCall()
    } else {
      const call = this.ongoingCall
      if (!call) {
        callsLogger.error('Tried connecting to unknown ongoing call.')
        return
      }
      activeCallStore.actions.answerCall({ jid: this.chatId })
    }
  }
}
