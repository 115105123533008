import { uiStore } from '@/store'
import DOMUtils from '@/utils/DOM'
import { createImageContent } from '@/components/Chat/Instance/DOM/Components/Content/Image'

export const createPlayIcon = (): HTMLElement => {
  return DOMUtils.createElement('div', {
    class: 'play-icon o-absolute-center',
  })
}

export const createVideoContent = (content: TADA.MessageContentVideo, maxPreviewHeight?: number, defaultEvent = true): HTMLElement => {
  const previewElement = createImageContent(content, maxPreviewHeight || 250, false)

  previewElement.classList.add('animated-preview')
  defaultEvent && (previewElement.onclick = event => {
    event.preventDefault()

    // TODO: remove any
    uiStore.actions.showModal({ instance: 'FileViewer', payload: content as any })
  })

  const playIcon = createPlayIcon()
  previewElement.appendChild(playIcon)

  return previewElement
}
