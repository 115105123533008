import { SearchType } from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Modules/AutocompleteModule/Utils'
import { defaultLogger } from '@/loggers'
import store from '@/store'
import * as actionTypes from '@/store/actionTypes'
import { Chat } from '@tada-team/tdproto-ts'
import Fuse from 'fuse.js'
import { throttle } from 'quasar'
import { multiSearch } from '@/utils/Common'
import { getDefaultFuseOptions } from '@/utils'

const enum AC_GET_TYPE {
  COMMANDS = 'commands',
  AT = 'at',
  SHARP = 'sharp'
}

/**
 * !TODO refactor
 * it is hard to refactor that shit cause of typing in code below
 * it use TADA.Group|Contact etc, need to refactor part of file :(
 */
export interface AutocompleteEntry {
  /**
   * @deprecated use title instead
   */
  displayName: string;
  /**
   * @deprecated use meta.jid instead
   */
  jid?: string;
  /**
   * @deprecated use title
   */
  description?: string;
  meta?: {
    jid?: string; // always contains, but types error now - need refactor
    section?: string;
  };
  key?: string;
  title?: string;
  help?: string;
}

export interface EntriesPack {
  chatId: string;
  searchType: SearchType;
  sequence: string;
  entries: AutocompleteEntry[];
}

export default class {
  private tempFormattedNames: { [key: string]: string } | null
  private fuseOptions: Fuse.IFuseOptions<AutocompleteEntry>
  private throttledUpdate: ReturnType<typeof throttle>

  constructor () {
    this.tempFormattedNames = null

    this.fuseOptions = getDefaultFuseOptions(['filterSequence'])

    this.throttledUpdate = throttle(this.updateList, 500)
  }

  private search = (data: AutocompleteEntry[], sequence: string): AutocompleteEntry[] => {
    const fuse = new Fuse(data, this.fuseOptions)
    return multiSearch((text: string) => fuse.search(text), sequence, 'filterSequence')
  }

  public getEntry = (chatId: string, entry: string | AutocompleteEntry, type: SearchType): AutocompleteEntry | null => {
    if (typeof entry !== 'string') return entry

    const { getters } = store
    switch (type) {
      case SearchType.COMMAND: {
        const commands = getters.chatBotCommands(chatId) as Array<TADA.BotCommand>
        if (!commands) return null

        for (let i = 0; i < commands.length; i++) {
          const command = commands[i]
          if (command.description === entry) return command as AutocompleteEntry
        }
        break
      }
      case SearchType.CONTACT: {
        const contacts = getters.chatBotCommands(chatId, 'at') as Array<TADA.Contact> // this._handleContactContext(chatId)
        if (!contacts) return null

        for (let i = 0; i < contacts.length; i++) {
          const contact = contacts[i]
          if ((contact as any).meta.jid === entry) return contact
          if ((contact as any).displayName === entry) return contact
        }
        break
      }
      case SearchType.GROUP: {
        const groups = getters.chatBotCommands(chatId, 'sharp') as Array<Chat> // this._handleGroupContext(chatId)
        if (!groups) return null

        for (let i = 0; i < groups.length; i++) {
          const group = groups[i]
          if (group.jid === entry) return group
        }
        break
      }
    }
    return null
  }

  public getEntries (
    chatId: string,
    onData: (pack: EntriesPack) => void,
    payload: { type: SearchType; sequence: string; needUpdate: boolean },
  ): AutocompleteEntry[] {
    let result: AutocompleteEntry[] = []
    const { type, sequence } = payload
    let { needUpdate } = payload

    const onDataCallback = (entries: AutocompleteEntry[]) => {
      if (!entries || entries.length === 0) return

      entries = entries.map(entry => {
        return Object.assign({}, entry, { filterSequence: this.getFilterSequence(entry) })
      })

      onData({
        searchType: type,
        entries: sequence ? this.search(entries, sequence) : entries,
        sequence: sequence,
        chatId: chatId,
      })
    }

    if (needUpdate && sequence) {
      needUpdate = false
    }

    switch (type) {
      case SearchType.COMMAND: {
        result = this._get(chatId, AC_GET_TYPE.COMMANDS, onDataCallback, needUpdate)
        break
      }
      case SearchType.CONTACT: {
        result = this._get(chatId, AC_GET_TYPE.AT, onDataCallback, needUpdate)
        break
      }
      case SearchType.GROUP: {
        result = this._get(chatId, AC_GET_TYPE.SHARP, onDataCallback, needUpdate)
        break
      }
    }

    result = result.map(entry => {
      return Object.assign({}, entry, { filterSequence: this.getFilterSequence(entry) })
    })

    return sequence ? this.search(result, sequence) : result
  }

  public flush () {
    this.tempFormattedNames = null
  }

  public getFilterSequence (entry: AutocompleteEntry, normalize?: boolean): string {
    const { displayName, jid } = entry as any
    const key: string = jid || displayName
    if (!key) return ''

    const cached = this.tempFormattedNames && this.tempFormattedNames[key]
    if (cached) return cached

    const value = Utils.prepareSequenceToFilter(displayName, normalize)

    if (!this.tempFormattedNames) this.tempFormattedNames = {}
    this.tempFormattedNames[key] = value

    return value
  }

  private _get (chatId: string, type: AC_GET_TYPE, onData: (entries: AutocompleteEntry[]) => void, needUpdate = false): AutocompleteEntry[] {
    const { getters } = store
    const entries = getters.chatBotCommands(chatId, type) || []
    if (entries.length === 0 || needUpdate) {
      this.throttledUpdate(chatId, type, onData)
    }

    return entries
  }

  private updateList = (chatId: string, type: AC_GET_TYPE, onData?: (entries: AutocompleteEntry[]) => void) => {
    const { dispatch, getters } = store
    const entries = getters.chatBotCommands(chatId, type) || []

    defaultLogger.info('[AutocompleteDataStore.updateList] Updating autocomplete entries (' + type + ')...')
    dispatch(actionTypes.LOAD_CHAT_COMMANDS, { chatId, type }).then(list => {
      if (typeof onData !== 'function') return
      if (!list || list.length === 0 || list.length === entries.length) return

      defaultLogger.debug('[AutocompleteDataStore.updateList] Entries:', list)

      onData(list)
    })
  }
}
