




































































































import api from '@/api/v3'
import {
  drawWaveform,
  bindPlayerListeners,
} from '@/components/Chat/Instance/DOM/Components/Content/AudioMsg'
import { defaultLogger } from '@/loggers'
import { uiStore } from '@/store'
import { SOCKET_SEND_MESSAGE_COMPOSING } from '@/store/actionTypes'
import { format } from 'quasar'
import { Component, Vue, Ref } from 'vue-property-decorator'

const { capitalize } = format

type RecordStatus = 'recording' | 'paused' | 'playing'

@Component
export default class AudioInput extends Vue {
  @Ref() readonly audio!: HTMLAudioElement
  @Ref() readonly button!: HTMLElement
  @Ref() readonly progress!: HTMLCanvasElement
  @Ref() readonly durationText!: HTMLElement
  @Ref() readonly player!: HTMLElement

  state: RecordStatus = 'recording'
  duration = 0
  inProgress = false
  micDenied = false
  showMicInfo = false
  error?: string = ''
  isElectron = false

  recorder?: MediaRecorder
  chunks: Array<BlobEvent['data'] | ArrayBufferLike> = []
  blob?: Blob
  stream?: MediaStream
  startTime = 0
  resolveRecorded?: ((value?: any) => void)
  wavedataInterval = 0
  wavedata: Array<number> = []
  composingIv!: number
  audioCtx!: AudioContext
  audioType!: string
  xhr?: XMLHttpRequest

  get btnClassByState (): string {
    const map: Record<RecordStatus, string> = {
      recording: 'stop',
      paused: 'play',
      playing: 'pause',
    }

    return map[this.state]
  }

  get formattedDuration (): string {
    return this.formatTime(this.duration)
  }

  get errorText (): string {
    return this.error || this.$t('chattape.seemsLikeMicBlocked').toString()
  }

  get closeText (): string {
    return capitalize(this.$t('glossary.close').toString())
  }

  created (): void {
    const isTypeSupported = (t: string) => {
      return typeof window?.MediaRecorder?.isTypeSupported === 'function' && MediaRecorder.isTypeSupported(t)
    }

    this.isElectron = Boolean(window.isElectron)

    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    this.audioType = ['audio/webm;codecs=opus', 'audio/ogg;codecs=opus', 'audio/wav']
      .filter(isTypeSupported)[0]

    defaultLogger.debug('Supported audio recording:', this.audioType)
  }

  mounted (): void {
    this.start()

    this.dispatchComposingState(true)
    this.composingIv = window.setInterval(() => this.dispatchComposingState(true), 30 * 1000)
  }

  beforeDestroy (): void {
    window.clearInterval(this.composingIv)

    this.dispatchComposingState(false)

    if (this.recorder && this.recorder.state !== 'inactive') this.stop()
  }

  formatTime (seconds: number): string {
    return new Date(seconds * 1000).toISOString().substr(14, 5)
  }

  dispatchComposingState (value: boolean): void {
    const { getters, dispatch } = this.$store
    const { currentChat: chatId } = getters
    dispatch(SOCKET_SEND_MESSAGE_COMPOSING, { chatId, composing: value, audio: true })
  }

  close () : void{
    try {
      this.stop()
    } catch (e) {
      console.warn(`[AudioMessages.Close err]: ${e}`)
    }
    this.$emit('cancel')
  }

  openUnblockMicModal (): void {
    uiStore.actions.showModal({ instance: 'UnblockMicModal' })
  }

  onControlBtnClick (): void {
    if (this.state === 'recording') this.stop()
  }

  start (): void {
    this.error = undefined
    const micInfoTimeout = window.setTimeout(() => { this.showMicInfo = true }, 500)

    if (this.isElectron && window._electronFeatures?.includes('micDenied')) {
      this.micDenied = true

      this.showMicInfo = false
      window.clearTimeout(micInfoTimeout)

      return
    }

    navigator.mediaDevices.getUserMedia({ audio: true }).then((s: MediaStream) => {
      this.showMicInfo = false
      window.clearTimeout(micInfoTimeout)

      this.micDenied = false
      this.stream = s
      this.chunks = []
      this.wavedata = []
      this.recorder = new MediaRecorder(this.stream)
      this.visualise(this.stream)
      this.startTime = Date.now()

      this.recorder.addEventListener('dataavailable', async (e: BlobEvent) => {
        this.duration = (Date.now() - this.startTime) / 1000
        this.chunks.push(e.data)

        if (this.recorder?.state === 'inactive') {
          window.clearInterval(this.wavedataInterval)
          await this.finalizeRecording()

          let data = null

          const reader = new FileReader()

          if (!this.blob) return

          reader.readAsDataURL(this.blob)
          reader.onloadend = () => {
            const { audio, button, progress, durationText, player } = this
            if (!audio) return

            data = String(reader.result)
            try {
              bindPlayerListeners({
                audio,
                button,
                progress,
                durationText,
                player,
                mediaURL: data,
                duration: this.duration,
                wavedata: this.wavedata,
              })
            } catch (e) {
              defaultLogger.error('[FileReader.onloadend]', e)
            }
          }
        }
      })
      this.recorder.start(500)
    }).catch(e => {
      this.showMicInfo = false
      window.clearTimeout(micInfoTimeout)

      this.micDenied = true
      defaultLogger.error('[navigator.mediaDevices.getUserMedia]', e)
    })
  }

  finalizeRecording (): Promise<void> {
    return new Promise(resolve => {
      if (this.audioType === 'audio/wav') {
        // polyfill fix
        const first = this.chunks[0]
        const headerBlob = first.slice(0, 44) // header WAV file
        this.chunks = this.chunks.map(c => c.slice(44)) // trimming headers for all chunks

        const fileReader = new FileReader()
        fileReader.onload = event => {
          const arrayBuffer = event.target?.result as ArrayBuffer

          const header = arrayBuffer && new Uint32Array(arrayBuffer)
          // fix the file size in the header
          const size = this.chunks.reduce((s, chunk) => s + (chunk as Blob).size, 0) + 44 - 8
          header[1] = size
          header[10] = size - 36

          // return the header to the beginning of the file
          this.chunks.unshift(header.buffer)

          this.state = 'paused'
          this.blob = new Blob(this.chunks, { type: this.audioType })
          this.resolveRecorded && this.resolveRecorded()
          resolve()
        }
        fileReader.readAsArrayBuffer(headerBlob as Blob)
      } else {
        this.state = 'paused'
        this.blob = new Blob(this.chunks, { type: this.audioType })
        this.resolveRecorded && this.resolveRecorded()
        resolve()
      }
    })
  }

  visualise (stream: MediaStream): void {
    const source = this.audioCtx.createMediaStreamSource(stream)

    const analyser = this.audioCtx.createAnalyser()
    analyser.fftSize = 2048
    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    source.connect(analyser)

    this.wavedataInterval = setInterval(() => {
      analyser.getByteTimeDomainData(dataArray)
      this.wavedata.push(Math.abs(Math.max(...dataArray) / 128 - 1) * 2)

      let data = this.wavedata.slice(-300)

      if (data.length < 300) {
        data = new Array(300 - data.length).fill(0).concat(data)
      }

      drawWaveform(this.progress, data, 1)
    }, 30)
  }

  stop (): void{
    this.recorder && this.recorder.stop()
    this.stream && this.stream.getTracks().map(t => t.stop())
  }

  async send (): Promise<void> {
    if (this.inProgress) {
      return
    }

    this.error = ''

    if (this.state === 'recording') {
      try {
        this.stop()
      } catch (e) {
        console.log('AUDIO RECORD STOP ERROR, e', e)
      }

      await new Promise(resolve => { this.resolveRecorded = resolve })
    }

    this.inProgress = true
    const data = new FormData()
    this.blob && data.append('file', this.blob)
    try {
      const { getters } = this.$store
      const { currentChat: chatId } = getters
      await api.messages.audiomsg(chatId, data)
    } catch (e) {
      this.error = this.$t('errors.sendingError').toString()
      console.error('AudioMessage error', e)
    }
    this.inProgress = false

    if (!this.error) {
      this.close()
    }
  }
}
