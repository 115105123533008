//
//
//
//
//
//
//
//
//

import Instance from './Instance'

export default {
  name: 'EditableArea',
  props: {
    chatId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  watch: {
    chatId (value) {
      this.instance.setActiveChat(value)
    },
    placeholder (value) {
      this.instance.setPlaceholder(value)
    },
  },
  mounted () {
    const { element } = this.$refs
    if (!element) return

    // prevent normal browsers from trying to translate Quill input
    // (for weirdos like Chrome - see class 'notraslate' in template above)
    // this is here instead of in a template because for some reason
    // (no, really, god knows why)
    // it gets compiled to translate="translate" when used as
    // translate="no" in template above
    element.setAttribute('translate', 'no')

    this.instance = new Instance({
      element,
      chatId: this.chatId,
      placeholder: this.placeholder,
    })
  },
  beforeDestroy () {
    this.instance.destroy()
  },
  methods: {

  },
}
