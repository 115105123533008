import { debounce } from 'quasar'
import { recalculateMediaSize } from '../../DOM/Components/Content/Utils'
import { setMaxMessageContentWidth } from '../Utils'

export default {
  created () {
    this.tapeHeight = 0
    this.tapeWidth = 0

    this.resizeMediaContent = debounce(this._resizeMediaContent, 250)
  },
  beforeDestroy () {
    this.resizeMediaContent && this.resizeMediaContent.cancel()
  },
  methods: {
    handleTapeResize (data) {
      if (!data) return

      const currentWidth = data.width || 0
      const currentHeight = data.height || 0

      if (this.tapeWidth !== currentWidth) {
        setMaxMessageContentWidth(currentWidth)
        this.resizeMediaContent()
      }

      if (currentHeight < this.tapeHeight) {
        const diff = this.tapeHeight - currentHeight
        this.scrollMove(diff)
      }

      this.tapeHeight = currentHeight
      this.tapeWidth = currentWidth
    },
    _resizeMediaContent () {
      const tape = this.tape || (this.instance && this.instance.tape)
      if (!tape) return

      const elements = tape.getMessagesElements()
      for (let i = 0; i < elements.length; i++) {
        const messageElement = elements[i]
        if (!messageElement) continue

        recalculateMediaSize(messageElement)
      }
    },
  },
}
