import DOMUtils from '@/utils/DOM'

window.addEventListener('resize', () => {
  document.querySelectorAll('.audiomsg-player').forEach((el: any) => el['@@onresize'] && el['@@onresize']())
})

const formatTime = (seconds: number) => new Date(seconds * 1000).toISOString().substr(14, 5)

const w = window as any

// here we store running audio
let activeAudio: HTMLMediaElement | null = null

const requestAnimationFrame = (w.requestAnimationFrame ||
  w.mozRequestAnimationFrame ||
  w.webkitRequestAnimationFrame ||
  w.msRequestAnimationFrame).bind(window)

const cancelAnimationFrame = (w.cancelAnimationFrame ||
  w.mozCancelAnimationFrame ||
  w.webkitCancelAnimationFrame ||
  w.webkitCancelRequestAnimationFrame).bind(window)

export const drawWaveform = (canvas: HTMLCanvasElement, wavedata: Array<number>, progress = 0) => {
  const ctx = canvas && canvas.getContext('2d')

  if (ctx === null) {
    return
  }

  canvas.style.width = ''
  canvas.style.height = ''

  canvas.width = 0
  canvas.height = 0

  const rect = canvas.getBoundingClientRect()

  canvas.width = rect.width * 2
  canvas.height = rect.height * 2

  canvas.style.width = rect.width + 'px'
  canvas.style.height = rect.height + 'px'

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  const maxHeight = canvas.height / 60 * 24
  const height = canvas.height / 2
  const width = canvas.width

  ctx.fillStyle = '#5F98E7'

  const k = wavedata.length / canvas.width

  // console.log('DRAW', maxHeight, width, rect.width)

  for (let x = 0; x < width - 4; x = x + 6) {
    if (x / width >= progress) {
      ctx.fillStyle = '#CCCFD5'
    }

    // let sample = wavedata[Math.floor(k * x)]
    let sample = 0
    // let count = 0
    for (let index = Math.floor(k * x); index < Math.floor(k * x + 6); index++) {
      sample = wavedata[index] > sample ? wavedata[index] : sample
      // sample += wavedata[index]
      // count++
    }

    // sample /= count

    const sh = Math.max(2, sample * maxHeight)

    // ctx.fillRect(x + 2, height - sh, 4, sh * 2)
    ctx.fillRect(x + 2, height - sh + 1, 4, sh * 2 - 2)
    ctx.fillRect(x + 3, height - sh, 2, sh * 2)
  }
}

export const addRewindListeners = (progress: HTMLElement, onRewindStart: (_: number) => boolean, onRewindEnd: (_: number) => void) => {
  const calcProgress = (event: MouseEvent): number => {
    const rect = progress.getBoundingClientRect()
    return Math.min(1, Math.max(0, (event.clientX - rect.left) / rect.width))
  }

  const progressMouseMove = (event: MouseEvent) => {
    onRewindStart(calcProgress(event))
  }

  const progressMouseUp = (event: MouseEvent) => {
    // console.log('UP')
    window.removeEventListener('mousemove', progressMouseMove, true)
    window.removeEventListener('mouseup', progressMouseUp, true)

    onRewindEnd(calcProgress(event))
  }

  progress.addEventListener('mousedown', event => {
    const stop = !onRewindStart(calcProgress(event))
    // console.log('DOWN')

    if (stop) {
      return
    }

    window.addEventListener('mousemove', progressMouseMove, true)
    window.addEventListener('mouseup', progressMouseUp, true)

    event.stopPropagation()
  })
}

export const bindPlayerListeners = ({
  audio,
  button,
  progress,
  durationText,
  player,
  mediaURL,
  duration,
  wavedata,
}: {
  audio: HTMLMediaElement;
  button: HTMLElement;
  progress: HTMLElement;
  durationText: HTMLElement;
  player: HTMLElement;
  mediaURL: string;
  duration: number;
  wavedata: Array<number>;
}) => {
  const state = {
    // playing: false
    requestId: 0,
    fakeProgress: -1, // используется, когда пользователь держит кнопку мыши в процессе перемотки
  }

  const setPlayingState = () => {
    button.classList.toggle('audiomsg-play', audio.paused)
    button.classList.toggle('audiomsg-pause', !audio.paused)

    const stopped = audio.paused && audio.currentTime === 0

    player.classList.toggle('stopped', stopped)
    durationText.innerHTML = stopped ? formatTime(duration || 0) : formatTime(audio.currentTime)

    state.requestId && cancelAnimationFrame(state.requestId)
    state.requestId = 0

    const animation = () => {
      drawWaveform(progress as HTMLCanvasElement, wavedata, state.fakeProgress === -1 ? audio.currentTime / duration : state.fakeProgress)
      state.requestId = requestAnimationFrame(animation)
    }

    // console.log('QWE', audio.paused, audio)
    if (!audio.paused) {
      state.requestId = requestAnimationFrame(animation)
    }

    drawWaveform(progress as HTMLCanvasElement, wavedata, state.fakeProgress === -1 ? audio.currentTime / duration : state.fakeProgress)
  }

  audio.addEventListener('ended', () => {
    audio.currentTime = 0
    setPlayingState()
  })

  audio.addEventListener('error', () => {
    setPlayingState()
  })

  audio.addEventListener('pause', () => {
    setPlayingState()
  })

  audio.addEventListener('play', () => {
    setPlayingState()
  })

  // audio.addEventListener('progress', event => {
  // })

  audio.addEventListener('timeupdate', () => {
    setPlayingState()
  })

  // audio.addEventListener('volumechange', event => {
  // })

  button.addEventListener('click', event => {
    if (audio.paused) {
      // if we already have running audio stopping it
      !activeAudio?.paused && activeAudio && activeAudio.pause()
      // and save current audio
      activeAudio = audio
      activeAudio.play()
    } else {
      activeAudio && activeAudio.pause()
      activeAudio = null
    }
    setPlayingState()
    event.stopPropagation()
  })

  player.addEventListener('click', event => {
    audio.paused && audio.currentTime === 0 && audio.play()
    setPlayingState()

    event.stopPropagation()
  })

  addRewindListeners(progress, p => {
    if (audio.paused && audio.currentTime === 0) {
      return false
    }

    state.fakeProgress = p
    drawWaveform(progress as HTMLCanvasElement, wavedata, state.fakeProgress === -1 ? audio.currentTime / duration : state.fakeProgress)
    return true
  }, p => {
    state.fakeProgress = -1
    audio.currentTime = duration * p
  })

  audio.src = mediaURL
  setPlayingState()

  return {
    redrawListener: () => {
      // console.log('DRAW')
      drawWaveform(progress as HTMLCanvasElement, wavedata, state.fakeProgress === -1 ? audio.currentTime / duration : state.fakeProgress)
    },
  }
}

export const createAudioMsgContent = (content: TADA.MessageContentAudioMsg): HTMLElement => {
  const { mediaURL, duration } = content
  console.warn(content)

  const wavedata = new Array(200).fill(0).map(() => Math.random() * Math.random())

  const audio = DOMUtils.createElement('audio', {
    class: 'audiomsg',
    preload: 'none',
    src: mediaURL,
  }) as HTMLMediaElement

  const button = DOMUtils.createElement('div', {
    class: 'audiomsg-button audiomsg-play',
  })

  const progress = DOMUtils.createElement('canvas', {
    class: 'audiomsg-progress',
  })

  const durationText = DOMUtils.createElement('div', {
    class: 'audiomsg-duration',
  }, '')

  const comment = (content as any).comment || ''
  const comm = DOMUtils.createElement('div', {
    class: 'audiomsg-comment',
    // style: 'text-align: center'
  }, comment)

  const player = DOMUtils.createElement('div', {
    class: 'audiomsg-player o-flex-row stopped',
  }, audio, button, progress, durationText)

  const wrapper = DOMUtils.createElement('div', {
    class: 'o-flex-column',
  }, player, comm)

  // listeners
  const { redrawListener } = bindPlayerListeners({ audio, button, progress, durationText, player, mediaURL, duration, wavedata })

  const insertListener = (e: Event) => {
    const event = e as AnimationEvent
    if (event.animationName === 'nodeInserted') {
      redrawListener()
    }
  }

  progress.addEventListener('animationstart', insertListener, false)
  progress.addEventListener('MSAnimationStart', insertListener, false)
  progress.addEventListener('webkitAnimationStart', insertListener, false)
  // window.addEventListener('resize', redrawListener, false)
  ;(player as any)['@@onresize'] = redrawListener

  return wrapper
}
