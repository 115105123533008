import { MarkupEntity } from '@tada-team/tdproto-ts'

let isDebug = false
const log = (...args: any[]) => {
  isDebug && console.log(...args)
}

export type NodeResolver = (ctx: { me: MarkupEntity, text?: string }) => HTMLElement
export type DefaultNodeResolver = (ctx: { me?: MarkupEntity, text?: string }) => HTMLElement
export type NodeFactory = {
  bold: NodeResolver
  code: NodeResolver
  codeblock: NodeResolver,
  italic: NodeResolver,
  link: NodeResolver,
  quote: NodeResolver,
  strike: NodeResolver,
  time: NodeResolver,
  underscore: NodeResolver,
  unsafe: NodeResolver,
  default: DefaultNodeResolver,
}

export const baseFactory: NodeFactory = {
  bold: ({ text }) => {
    const el = document.createElement('b')
    if (text !== undefined) el.textContent = text
    return el
  },
  code: ({ text }) => {
    const el = document.createElement('code')
    if (text !== undefined) el.textContent = text
    return el
  },
  codeblock: ({ text }) => {
    const el = document.createElement('pre')
    if (text !== undefined) el.textContent = text
    return el
  },
  italic: ({ text }) => {
    const el = document.createElement('i')
    if (text !== undefined) el.textContent = text
    return el
  },
  link: ({ text, me }) => {
    const el = document.createElement('a')
    if (text !== undefined) el.textContent = text
    if (me.url) el.href = me.url
    return el
  },
  quote: ({ text }) => {
    const el = document.createElement('blockquote')
    if (text !== undefined) el.textContent = text
    return el
  },
  strike: ({ text }) => {
    const el = document.createElement('s')
    if (text !== undefined) el.textContent = text
    return el
  },
  time: ({ text, me }) => {
    const el = document.createElement('time')
    if (text !== undefined) el.textContent = text
    if (me.time !== undefined) {
      el.setAttribute('datetime', me.time)
      const date = new Date(me.time)
      el.textContent = date.toLocaleString()
    }
    return el
  },
  underscore: ({ text }) => {
    const el = document.createElement('u')
    if (text !== undefined) el.textContent = text
    return el
  },
  unsafe: ({ text }) => {
    const el = document.createElement('span')
    if (text !== undefined) el.textContent = text
    return el
  },
  default: ({ text }) => {
    const el = document.createElement('span')
    if (text !== undefined) el.textContent = text
    return el
  },
}

const resolveNode = (factory: NodeFactory, me: MarkupEntity, text?: string) => {
  if (me.typ in factory) {
    log('resolve node of type', me.typ)
    return factory[me.typ]({ me, text })
  }

  log('resolve default node of type', me.typ)
  return factory.default({ me, text })
}

const append = (
  factory: NodeFactory,
  el: HTMLElement,
  source: string[],
  markup: MarkupEntity[],
): void => {
  let prev = 0

  if (markup.length === 0) {
    const text = source.join('')
    log('no markup, appending default', text)
    const child = factory.default({ text })
    el.appendChild(child)
    return
  }

  for (const me of markup) {
    log('looking at markup', me)

    const context = source.slice(me.op + (me.oplen ?? 0), me.cl)
    let child: HTMLElement

    if (me.childs && me.childs.length > 0) {
      log('found children, processing...')
      child = resolveNode(factory, me)
      append(factory, child, context, me.childs)
    } else {
      let text = context.join('')
      if (me.repl) text = me.repl
      log('no children, inserting', text)
      child = resolveNode(factory, me, text)
    }

    if (prev < me.op) {
      const text = source.slice(prev, me.op).join('')
      log('text before start of markup, appending:', text, prev, me.op)
      el.appendChild(factory.default({ text }))
    }

    el.appendChild(child)

    prev = me.cl + (me?.cllen ?? 0)
    log('set prev:', prev)
  }

  if (prev < source.length) {
    const text = source.slice(prev).join('')
    log('text after end of markup, appending:', text, prev)
    el.appendChild(factory.default({ text }))
  }
}

export const debug = (enable = true) => {
  isDebug = enable
}

export const toHTML = (source: string, markup: MarkupEntity[], factory = baseFactory) => {
  const el = document.createElement('div')
  append(factory, el, Array.from(source), markup)
  return el.innerHTML
}
