import { createPlayIcon } from './Video'
import DOMUtils from '@/utils/DOM'
import { getAspectRatioSize } from '@/utils/Common'

import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { getMessageElement } from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import { prepareUploads } from '@/components/Chat/Instance/DOM/Components/Content/Utils'

import store, { uiStore } from '@/store'
import { MessageDOMAttribute } from '@/components/Chat/Instance/DOM'
import { getMaxMessageContentWidth } from '@/components/Chat/Instance/Hooks/Utils'
import { CONTENT_CLASS_NAME, ATTRIBUTE_CONTENT_TYPE } from '@/components/Chat/Instance/DOM/Components/Content'
import Messages from '@/store/messages'
import * as Sentry from '@sentry/browser'
import { defaultLogger } from '@/loggers'

export const IMAGE_HEIGHT_LIMIT = 300
export const IMAGE_STICKER_HEIGHT_LIMIT = 150
export const IMAGE_CLASS_NAME = 'image'

export const HEIGHT_LIMIT_ATTRIBUTE = 'data-height-limit'
export const PREVIEW_HEIGHT_ATTRIBUTE = 'data-preview-height'
export const PREVIEW_WIDTH_ATTRIBUTE = 'data-preview-width'

const openFileViewer = (content: Partial<TADA.MessageContentImage>, element: HTMLElement | null) => {
  const messageElement = element
  const messageId = messageElement?.getAttribute(MessageDOMAttribute.ID)

  // if uploads enabled, then the message object will be different
  if (window.FEATURES.message_uploads && messageId) {
    const message = Messages.getMessage(store.getters.currentChat, messageId)
    // is there a picture in the message
    if (Messages.isImageInMessage(message)) {
      // get all uploads in the chat
      const groupedUploadsByMsg = Messages.getUploadsByChatIdAndContentType(store.getters.currentChat, 'image')
      // calculate the index of the image inside the array of all chat images
      let targetIndex = 0
      let isTargetIndexFound = false
      const flatUploads: TADA.Upload[] = []
      Object.entries(groupedUploadsByMsg).forEach(([msgId, uploadsInMsg]) => {
        // flatten the array of uploads for the image gallery
        flatUploads.push(...uploadsInMsg)

        if (isTargetIndexFound) return

        // computing image index in uploads array
        if (msgId !== messageId) {
          // if this is not the message the image from which we open
          // just count all its uploads
          targetIndex += uploadsInMsg.length
        } else {
          // if this is a message that contains our image
          // looking for its target index in uploads this message
          const indexInUploads = uploadsInMsg.findIndex(upload => upload.url === content.mediaURL)

          // if not found, then this is an error
          if (indexInUploads === -1) {
            Sentry.withScope(scope => {
              scope.setLevel(Sentry.Severity.Error)
              scope.setTag('image', 'uploads')
              scope.setContext('error', { mediaUrl: content.mediaURL })
              Sentry.captureException('Open an image that is not in the chat')
            })
            defaultLogger.error('Open an image that is not in the chat', content.mediaURL)
          }
          targetIndex += indexInUploads
          // stop looking target index
          isTargetIndexFound = true
        }
      })
      // we bring uploads to the required format
      const viewerContentList = prepareUploads(flatUploads)
      uiStore.actions.showModal({ instance: 'FileViewer', payload: { viewerContentList, targetIndex } })
      return
    }
  }

  const contentElement = messageElement && messageElement.querySelector(`.${CONTENT_CLASS_NAME}`)
  if (contentElement && contentElement.getAttribute(ATTRIBUTE_CONTENT_TYPE) !== TADA.MessageType.IMAGE) {
    uiStore.actions.showModal({ instance: 'FileViewer', payload: Object.assign({}, content, { type: TADA.MessageType.IMAGE }) })
    return
  }

  if (!messageElement || messageElement.getAttribute(MessageDOMAttribute.SOURCE)) {
    uiStore.actions.showModal({ instance: 'FileViewer', payload: content })
    return
  }

  ChatEventBus.$emit(Events.OPEN_IMAGE_VIEWER, { messageId, content })
}

const applyClickHandler = (content: Partial<TADA.MessageContentImage>, element: HTMLElement) => {
  element.addEventListener('click', event => {
    event.preventDefault()

    const messageElement = getMessageElement(event)
    openFileViewer(content, messageElement)
  })
}

export const setupDimensions = ({ element, previewWidth, previewHeight, maxImageHeight, maxImageWidth }: { element: HTMLElement; previewWidth: number; previewHeight: number; maxImageHeight: number; maxImageWidth?: number }) => {
  maxImageWidth = maxImageWidth === undefined ? getMaxMessageContentWidth() : maxImageWidth

  if (!maxImageWidth) {
    element.style.height = `${previewHeight}px`
    element.style.maxHeight = `${maxImageHeight}px`
    return
  }

  const { width, height } = getAspectRatioSize({
    maxWidth: maxImageWidth,
    maxHeight: maxImageHeight,
    width: previewWidth,
    height: previewHeight,
  })

  element.style.width = `${width}px`
  element.style.height = `${height}px`
  element.style.backgroundSize = 'cover'
}

const isFitInTape = (content: Partial<TADA.MessageContentImage>): boolean => {
  const { previewWidth, previewHeight } = content
  if (!previewWidth || !previewHeight) return false

  return previewWidth <= getMaxMessageContentWidth() && previewHeight <= IMAGE_HEIGHT_LIMIT
}

const getPreviewURL = (content: Partial<TADA.MessageContentImage>): string => {
  const { previewURL, preview2xURL } = content

  const preferHighResolution = (window.devicePixelRatio || 1) > 1
  return (preferHighResolution && preview2xURL) || previewURL || ''
}

const getMaxImageHeight = (content: Partial<TADA.MessageContentImage>, maxImageHeight?: number): number => {
  let maxHeight = IMAGE_HEIGHT_LIMIT
  if (content.subtype === 'sticker') maxHeight = IMAGE_STICKER_HEIGHT_LIMIT

  return Math.min(maxImageHeight || maxHeight, maxHeight)
}

const applyAnimatedBehaviour = (content: Partial<TADA.MessageContentImage>, element: HTMLElement) => {
  element.classList.add('animated-preview')

  const playIcon = createPlayIcon()
  element.appendChild(playIcon)

  const toggle = (on?: boolean) => {
    const playingAttr = 'data-playing'
    on = on === undefined ? (element.getAttribute(playingAttr) !== 'true') : on

    element.style.backgroundImage = `url("${on ? content.mediaURL : getPreviewURL(content)}")`
    playIcon.style.display = on ? 'none' : 'block'

    on ? element.setAttribute(playingAttr, 'true') : element.removeAttribute(playingAttr)
  }

  element.onclick = (event: MouseEvent) => {
    event.preventDefault()

    const isFit = isFitInTape(content)
    if (isFit) {
      toggle()
      return
    }

    toggle(false)
    const messageElement = getMessageElement(event)
    openFileViewer(content, messageElement)
  }
}

export const createImageContent = (content: Partial<TADA.MessageContentImage>, maxImageHeight?: number, clickable = true): HTMLElement => {
  const { mediaURL, previewHeight, previewWidth, name, animated } = content

  if (!previewWidth || !previewHeight) return document.createElement('div')

  maxImageHeight = getMaxImageHeight(content, maxImageHeight)

  const previewURL = getPreviewURL(content)

  const attributes = {
    class: IMAGE_CLASS_NAME,
    [HEIGHT_LIMIT_ATTRIBUTE]: maxImageHeight,
    [PREVIEW_WIDTH_ATTRIBUTE]: previewWidth,
    [PREVIEW_HEIGHT_ATTRIBUTE]: previewHeight,
  }
  clickable && Object.assign(attributes, { href: mediaURL, title: name })

  const element = DOMUtils.createElement(clickable ? 'a' : 'div', attributes)
  element.style.backgroundImage = `url("${previewURL}")`

  setupDimensions({ element, previewWidth, previewHeight, maxImageHeight })

  if (clickable) {
    animated ? applyAnimatedBehaviour(content, element) : applyClickHandler(content, element)
  }

  return element
}
