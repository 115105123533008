import { goalChatOneMessageActions } from '@/analytics'
import DataGenerator from '@/api/v3/DataGenerator'
import {
  getActiveMessageId,
  getActiveMessageSource,
} from '@/components/Chat/Instance/DOM/Components/Helpers/CommonUtils'
import {
  ATTRIBUTE_REACTION_NAME,
  REACTION_CLASS_NAME,
  REACTION_ME_CLASS_NAME,
} from '@/components/Chat/Instance/DOM/Components/Reactions'
import Popout from '@/components/UI/Popouts/Popout'
import { executeAtPopout } from '@/components/UI/Popouts/Utils'
import i18n from '@/i18n'
import store, { contactsStore } from '@/store'
import Reactions from '@/store/messages/reactions'
import { parseEmoji } from '@/utils'
import DOMUtils from '@/utils/DOM'
import { MessageReaction, MyReactionsJSON, Reaction } from '@tada-team/tdproto-ts'

const ATTRIBUTE_EMOJI_NAME = 'data-emoji'

type EmojiList = Array<Reaction | string>

export const createEmoji = (emoji: string): HTMLElement => {
  return DOMUtils.createElement('li', {
    class: parseEmoji(emoji, true),
    [ATTRIBUTE_EMOJI_NAME]: emoji,
  })
}

export const createEmojiContainer = ({ name, list, onItemClick }: {
  name: string
  list: EmojiList
  onItemClick: (emoji: string, event: MouseEvent) => void
}): HTMLElement => {
  const headingElement = DOMUtils.createElement('div', {
    class: 'heading o-text-cutter',
  }, name)

  const listElement = DOMUtils.createElement('ul', {
    class: 'list o-no-default-ul',
  })

  const fragment = document.createDocumentFragment()
  list.forEach((data: Reaction | string) => {
    const emoji = typeof data === 'string' ? data : data.name
    const itemElement = createEmoji(emoji)
    fragment.appendChild(itemElement)
  })
  listElement.appendChild(fragment)

  listElement.addEventListener('click', (e: MouseEvent) => {
    const target = e.target as HTMLElement
    if (!target.getAttribute) return

    const emoji = target.getAttribute(ATTRIBUTE_EMOJI_NAME)
    if (!emoji) return

    goalChatOneMessageActions.addReactionClick()

    onItemClick(emoji, e)
  })

  return DOMUtils.createElement('div', {
    class: 'emoji-container',
  }, headingElement, listElement)
}

const onItemClick = (emoji: string, e: MouseEvent) => {
  const target = e.target as HTMLElement
  if (!target) return

  const messageId = getActiveMessageId(target)
  if (!messageId) return

  const chatId = getActiveMessageSource(target)
  if (!chatId) return

  executeAtPopout(e, () => {
    Reactions.toggleMessageReaction({ messageId, chatId, reactionName: emoji })
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createReactionsPopoutContent = (popout: Popout): HTMLElement | string => {
  const groups = Reactions.getList()
  const keys: Array<keyof MyReactionsJSON> = ['top', 'all']

  const containerElements = keys.map(key =>
    createEmojiContainer({
      name: key === 'all'
        ? i18n.t('chattape.reactionAll').toString()
        : i18n.t('chattape.reactionRecentlyUsed').toString(),
      list: groups[key],
      onItemClick,
    }),
  )

  return DOMUtils.createElement('div', {
    class: 'reaction-picker',
  }, ...containerElements)
}

export const hasMyReaction = (reaction: MessageReaction): boolean => {
  const { getUserId } = store.getters
  const { details } = reaction

  return details.some(reaction => {
    const { sender } = reaction
    return sender === getUserId
  })
}

export const getReactionDescription = async (reaction: MessageReaction): Promise<string> => {
  const senders = reaction.details.map(s => s.sender)
  await contactsStore.actions.loadContacts(senders)
  const result = reaction.details.map(({ sender }) => {
    const contact =
      contactsStore.getters.contact(sender) ||
      DataGenerator.generateDummyContact()
    return contact.displayName
  })
  return result.join(', ')
}

export const onTapeClickHandler = (e: Event) => {
  const target = e.target as HTMLElement
  if (!target || !(target instanceof HTMLElement)) return

  const { classList } = target
  if (classList.contains(REACTION_CLASS_NAME)) {
    if (classList.contains(REACTION_ME_CLASS_NAME)) {
      goalChatOneMessageActions.removeReaction()
    } else {
      goalChatOneMessageActions.addSomeoneReactionClick()
    }
  }

  const name = target.getAttribute(ATTRIBUTE_REACTION_NAME)
  if (!name) return

  const messageId = getActiveMessageId(target)
  if (!messageId) return

  const chatId = getActiveMessageSource(target)
  if (!chatId) return

  Reactions.toggleMessageReaction({ messageId, chatId, reactionName: name })
}
