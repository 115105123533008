import { persistScrollPosition } from '@/utils/DOM'

export default {
  methods: {
    scrollMove (offset) {
      const { scroll } = this.$refs
      if (!scroll) return

      const currentPosition = scroll.getDistanceFromTop()
      scroll.scrollTo(currentPosition + offset)
    },
    scrollDown (threshold) {
      const { scroll } = this.$refs
      if (!scroll) return

      const straightScroll = !threshold || scroll.getDistanceFromBottom() <= threshold
      straightScroll && scroll.scrollDown()
    },
    scrollDownIfNeeded ({ action, threshold = 0 }) {
      const { scroll } = this.$refs
      if (!scroll) return

      const distanceBeforeAction = scroll.getDistanceFromBottom()
      action && action()

      if (distanceBeforeAction > threshold) return

      scroll.scrollDown()
    },
    scrollTo (offset) {
      const { scroll } = this.$refs
      scroll && scroll.scrollTo(offset)
    },
    setScrollEvent (func) {
      const { scroll } = this.$refs
      scroll && scroll.setUpdateCallback(func)
    },
    updateScrollBar () {
      const { scroll } = this.$refs
      scroll && scroll.update(false)
    },
    async persistScroll ({ callback }) {
      const { scroll } = this.$refs
      if (!scroll) return

      const { content } = scroll.$refs
      await persistScrollPosition({
        container: content,
        callback,
      })
    },
  },
}
