import Quill from 'quill'

import EmojiParser from '@/components/Chat/ReplyArea/Emoji/Parser'
import { parseEmoji } from '@/utils'

const Embed = Quill.import('blots/embed')

class EmojiBlot extends Embed {
  static create (value: string) {
    const node = super.create(value) as Element

    node.setAttribute('alt', value)

    const src = EmojiParser.toSource(value)
    node.setAttribute('src', src)

    return node
  }

  static value (node: Element) {
    return node.getAttribute('alt')
  }

  static textProcessor (text: string) {
    return parseEmoji(text)
  }

  static toRawText (value: string): string {
    return value
  }
}

EmojiBlot.blotName = 'emoji'
EmojiBlot.tagName = 'img'
EmojiBlot.className = 'emojione'

export default EmojiBlot
